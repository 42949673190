import { Appraisal, Entity, Financing, Vehicle } from '@/models'
import { Price } from '@/models/interfaces'
import { Bonus, Deal as IDeal, DealType } from './interfaces'
import { fixPrice } from '@/utils/general'

export class Deal extends Entity implements IDeal {
  type: DealType;
  vehicle: Vehicle;
  appraisal?: Appraisal;
  financing?: Array<Financing>;
  bonuses?: Array<Bonus>;

  _financial?: Financing;

  constructor (deal: IDeal) {
    super(deal)
    const { type, vehicle, appraisal, financing, bonuses } = deal

    this.type = type
    this.vehicle = new Vehicle(vehicle)
    if (appraisal) this.appraisal = new Appraisal(appraisal)
    this.financing = financing?.map(f => new Financing(f))
    this.bonuses = bonuses
  }

  get price (): Price | undefined {
    const { type, vehicle, appraisal } = this

    switch (type) {
      case DealType.sell:
        return vehicle.price
      case DealType.buy:
        return appraisal?.price
    }
  }

  get isSell () {
    return this.type === DealType.sell
  }

  get isBuy () {
    return this.type === DealType.buy
  }

  get financial (): Financing | undefined {
    const { _financial } = this
    if (_financial) return _financial

    const { isSell, financing } = this
    if (!isSell || !financing) return

    const financial = financing.sort(({ status: a }, { status: b }) => b - a)
    return this._financial = financial[0]
  }

  get approvedFinancing (): string | undefined {
    const { isSell, financing } = this
    if (!isSell || !financing) return

    const approved = financing.filter(({ isApproved }) => isApproved)
    const names = [...new Set(approved.map(({ company: { alias } }) => alias))].join(', ')
    return approved.length ? names : undefined
  }

  get bonus (): string | undefined {
    const { isSell, bonuses } = this
    if (!isSell || !bonuses?.length) return

    const { name, amount } = bonuses[0]
    return `${name}: ${fixPrice(amount)}`
  }
}
