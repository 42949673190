import gql from 'graphql-tag'
import { ActivityFragment, DealFragment, LeadFragment, NoteFragment } from '@/graphql/fragments/crm'
import { PersonFragment } from '@/graphql/fragments/persons'
import { AppraisalFragment } from '@/graphql/fragments/purchase'
import { BaseSaleOrderFragment, StockFragment } from '@/graphql/fragments/sales'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import { AutoMotiveRegistrationFragment } from '@/graphql/fragments/vehicle'
import Fragment from '@/graphql/forms/persons/person/fragment'
import { FinancingFragment } from '@/graphql/fragments/loans'

export const mutation = gql`
  ${LeadFragment}
  ${PersonFragment}
  ${DealFragment}
  ${AppraisalFragment}
  ${StockFragment}
  ${NoteFragment}
  ${Fragment}
  ${BaseSaleOrderFragment}
  ${DealAutoAttributeFragment}
  ${ActivityFragment}
  ${FinancingFragment}
  ${BaseAutoFragment}
  ${AutoMotiveRegistrationFragment}
  mutation updateLead($id: Int!, $fields: crm_lead_set_input!) {
    record: update_crm_lead_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...Lead
      client {
        ...PersonForm
      }
      deals {
        ...Deal
        auto {
          ...AutoBase
          ownerships{
            id
            owners{
              id
            }
          }
          version: version_year {
            ...VersionYear
          }
          generation {
            id
            registrations:automotive_registrations{
              ...AutoMotiveRegistration
            }
          }
          status: process_status {
            ...ProcessStatus
          }
          maintenances (order_by: {type: {mileage: desc}}){
            ...Maintenance
          }
          deals {
            id
            appraisalsAggregate: appraisals_aggregate{
              aggregate {
                count
              }
            }
          }
        }
        saleOrders: sale_orders {
          ...BaseSaleOrder
          saleOrderItems: sale_order_items(order_by: {item_type: {id: asc}}) {
            ...SaleOrderItem
          }
          financings {
            ...Financing
          }
        }
        status {
          ...ProcessStatus
        }
        closingReason: closing_reason {
          ...ClosingReason
        }
        intention {
          ...Intention
        }
        autoAttributes: auto_attributes{
          ...DealAutoAttribute
        }
        appraisals {
          ...Appraisal
        }
        stock {
          ...Stock
        }
      }
      activities(order_by:{id: desc}){
        ...Activity
      }
    }
  }
`

export default mutation
