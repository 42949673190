import { Type } from 'class-transformer'
import { Entity } from '..'

import { Auto, Region } from '.'
import { Person } from '../persons'
import { Attribute, AutomotiveRegistration } from '../vehicle'

export class Country extends Entity {
  @Type(() => Attribute)
  attributes: Attribute[];

  @Type(() => Auto)
  autos: Auto[];

  @Type(() => AutomotiveRegistration)
  automotiveRegistrations: AutomotiveRegistration[];

  @Type(() => Person)
  people: Person[];

  @Type(() => Region)
  regions: Region[];

  name: string;
  abbreviation: string | null;
  phoneCode: number | null;
  nationality: string | null;
  nationalFrequency: number | null;
}
