import { Type } from 'class-transformer'
import { Entity } from '..'

import { AutomotiveRegistration } from '.'

export class RegistrationFile extends Entity {
  @Type(() => AutomotiveRegistration)
  registrations: AutomotiveRegistration[];

  name: string;
  uri: string;
  checksum: string;
  type: string;
}
