import gql from 'graphql-tag'
import { ActivityTypeFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${ActivityTypeFragment}
  query findActivityType($filter: crm_activity_type_bool_exp, $limit: Int,$order: [crm_activity_type_order_by!]) {
    records: crm_activity_type (where: $filter, limit: $limit, order_by: $order){
      ...ActivityType
    }
  }
`

export default query
