import gql from 'graphql-tag'

import { FileFragment } from '@/graphql/fragments/files'

export const query = gql`
  ${FileFragment}
  query fetchFile($id: Int!) {
    records: files_file_by_pk(id: $id) {
      ...File
    }
  }
`

export default query
