import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
    component: () => import('./Auth.vue'),
  },
]

export default routes
