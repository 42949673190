
  import { Component, Vue, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'
  import { NotificationContent } from '@/store/notification/state'

@Component({
  components: {},
  computed: {
    ...mapGetters('notification', ['notifications']),
    ...mapGetters('app', ['isMobile']),
  },
  methods: {
    ...mapActions('notification', ['cleanNotification']),
  },
})
  export default class GlobalNotification extends Vue {
  open = false;
  notifications!: NotificationContent;
  isMobile!: boolean;
  cleanNotification!: () => void;

  get snackbar (): NotificationContent | null {
    if (
      !this.notifications ||
      !this.notifications?.color ||
      !this.notifications?.message
    ) {
      return null
    }
    return this.notifications
  }

  @Watch('snackbar') onChange (val: NotificationContent): void {
    if (val) {
      this.open = true
    }
  }

  closeSnackbar (): void {
    this.open = false
    this.cleanNotification()
  }
  }
