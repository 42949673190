import gql from 'graphql-tag'
import { GenderFragment, MaritalStatusFragment, PersonFragment } from '@/graphql/fragments/persons'
import { CountryFragment } from '@/graphql/fragments/public'

export const Fragment = gql`
  ${PersonFragment}
  ${GenderFragment}
  ${MaritalStatusFragment}
  ${CountryFragment}
  fragment PersonForm on persons_person {
    ...Person
    gender: gender {
      ...Gender
    }
    maritalStatus: marital_status {
      ...MaritalStatus
    }
    country {
      ...Country
    }
    employee {
      id
    }
    companyType:company_type {
      id
      name
    }
  }
`
export default Fragment
