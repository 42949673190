import { Type } from 'class-transformer'
import { Entity } from '..'

import { FileType } from '.'

export class Serialization extends Entity {
  @Type(() => FileType)
  fileType: FileType;

  service: string;
  config: Record<string, any>;
}
