import gql from 'graphql-tag'
import { ClosingReasonFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${ClosingReasonFragment}
  query findClosingReason($filter: settings_closing_reason_bool_exp!,$order: [settings_closing_reason_order_by!], $limit: Int){
    records:settings_closing_reason(where: $filter,order_by: $order, limit: $limit) {
      ...ClosingReason
    }
  }
`

export default query
