import { Actions } from '../../interfaces'

import find from './find'
import { TransferType } from '@/entities/settings'

export const actions: Actions = {
  find,
}

export default {
  Model: { TransferType },
  actions: { [TransferType.name]: actions },
}
