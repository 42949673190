import gql from 'graphql-tag'

import { InspectionQualificationFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${InspectionQualificationFragment}
  mutation insertInspectionQualification($fields: purchase_inspection_qualification_insert_input!) {
    record: insert_purchase_inspection_qualification_one(object: $fields) {
      ...InspectionQualification
    }
  }
`

export default mutation
