import gql from 'graphql-tag'

import { NegotiationFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${NegotiationFragment}
  mutation insertNegotiation($fields: purchase_negotiation_insert_input!) {
    record: insert_purchase_negotiation_one(object: $fields) {
      id
    }
  }
`

export default mutation
