import { Type } from 'class-transformer'
import { Entity } from '..'

import { ClosingReasonType, Icon } from '.'
import { Deal, Lead } from '../crm'
import { Appraisal, Inspection } from '../purchase'
import { Evaluation } from '../loans'
import { Reserve, SaleOrder } from '../sales'

export class ClosingReason extends Entity {
  @Type(() => Appraisal)
  appraisals: Appraisal[];

  @Type(() => ClosingReasonType)
  type: ClosingReasonType;

  @Type(() => Deal)
  deals: Deal[];

  @Type(() => Evaluation)
  evaluations: Evaluation[];

  @Type(() => Reserve)
  reserve: Reserve[];

  @Type(() => SaleOrder)
  saleOrders: SaleOrder[];

  @Type(() => Inspection)
  inspections: Inspection[];

  @Type(() => Lead)
  leads: Lead[];

  @Type(() => Icon)
  _icon: Icon;

  _description: string
  order: number | null
  _color: string

  get title (): string {
    const { type: { title }, _description } = this
    return _description || title
  }

  get icon () {
    const { type: { icon }, _icon } = this
    return _icon || icon
  }

  get color () {
    const { type: { color }, _color } = this
    return _color || color
  }

  get description (): string {
    const { title } = this
    return title
  }

  get name (): string {
    const { type: { name } } = this

    return name
  }

  get isExpired () {
    const { type: { name } } = this
    return name === ClosingReasonType.expired
  }

  get notQualify () {
    const { type: { name } } = this
    return name === ClosingReasonType.notQualify
  }

  get isScheduledInspection () {
    const { type: { name } } = this

    return name === ClosingReasonType.scheduledInspection
  }

  get isFinancialMisbehavior () {
    const { type: { name } } = this
    return name === ClosingReasonType.financialMisbehavior
  }

  get notMatch () {
    const { type: { name } } = this
    return name === ClosingReasonType.notMatch
  }

  get successful () {
    const { type: { name } } = this
    return name === ClosingReasonType.successful
  }

  get canceled () {
    const { type: { name } } = this
    return name === ClosingReasonType.canceled
  }

  get withoutDeal () {
    const { type: { name } } = this
    return name === ClosingReasonType.withoutDeal
  }

  get activityResult () {
    const { successful, withoutDeal } = this

    return successful ? true : withoutDeal ? false : null
  }

  get isSigned () {
    const { type: { name } } = this
    return name === ClosingReasonType.signed
  }
}
