import inspections from './inspections'
import appraisals from './appraisals'
import purchases from './purchases'
import evaluations from './evaluations'
import stock from './stock'
import reserve from './reserve'
import sales from './salesOrder'

export const queries = {
  inspections,
  appraisals,
  purchases,
  evaluations,
  stock,
  reserve,
  sales,
}
export default queries
