import { Transform, Type } from 'class-transformer'
import { Entity } from '..'

import { Financing } from './Financing'
import { EvaluationCondition } from './EvaluationCondition'
import { EvaluationRequirement } from './EvaluationRequirement'
import { Offer } from './Offer'
import { Agreement, Person, ResponsibleAgreement } from '../persons'
import { BusinessApproach, ClosingReason, ProcessStatus } from '../settings'
import { Intervener } from '@/entities/documents'
import dayjs, { Dayjs } from 'dayjs'

export class Evaluation extends Entity {
  @Type(() => Agreement)
  agreement: Agreement

  @Type(() => Person)
  backer: Person

  @Type(() => Intervener)
  intervener: Intervener[]

  @Type(() => ClosingReason)
  closingReason: ClosingReason

  @Type(() => Financing)
  financing: Financing;

  @Type(() => ResponsibleAgreement)
  responsibleAgreement: ResponsibleAgreement

  @Type(() => ProcessStatus)
  status: ProcessStatus

  @Type(() => EvaluationCondition)
  conditions: EvaluationCondition[];

  @Type(() => Offer)
  offers: Offer[];

  @Type(() => EvaluationRequirement)
  requirements: EvaluationRequirement[];

  @Type(() => BusinessApproach)
  businessApproach: BusinessApproach;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  signedDate: Dayjs;

  comment: string

  get signedDateLocalTime () {
    const { signedDate } = this
    if (!signedDate) return null

    const local = dayjs(signedDate)
    const offset = dayjs().utcOffset()
    const localSchedulingDate = local.add(offset, 'minute')

    return dayjs(localSchedulingDate)
  }

  get buttonClose () {
    const { isDisabled, id } = this

    return {
      disabled: isDisabled,
      id,
      constructor: { name: 'EvaluationClosing' },
    }
  }

  get isDisabled () {
    const { status, closingReason, financing: { saleOrder } } = this

    if (saleOrder?.deal?.status?.isLost) {
      return true
    }

    if (saleOrder?.deal?.auto?.status?.isSelling && saleOrder?.status?.isPending) {
      return true
    }

    if (status.isClosed && closingReason.isSigned) {
      return false
    }

    return !status?.isApproved
  }

  get isApproved () {
    const { status, closingReason } = this
    return (status.isClosed && closingReason.isSigned)
  }

  get pie () {
    const { financing } = this

    if (!financing?.pie) return ''

    return financing.pie
  }
}
