import { State as RootState } from '@/store/state'
import { ActionTree } from 'vuex'
import { Reference } from '@/store/persons/reference/state'
import { relationsShips } from '@/store/persons/reference/queries/info'
import {
  createPersonReference,
  deletePersonReference,
  personReference,
} from '@/store/persons/reference/mutation/reference'
import { isEmpty } from '@/utils/general'

export const actions: ActionTree<Reference, RootState> = {
  deleteReference: async (
    { rootState: { apolloClient } },
    reference
  ): Promise<void> => {
    if (!apolloClient) return

    await apolloClient.mutate({
      mutation: deletePersonReference,
      variables: { id: reference.idPersonalReference },
    })

    window.dispatchEvent(
      new CustomEvent('notification-message', {
        // @ts-ignore
        detail: {
          message: '¡Se ha desvinculado exitosamente!',
          type: 'success',
        },
      })
    )
  },
  loadRelationsShips: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) {
      return
    }
    const {
      data: { relationships },
    } = await apolloClient.query({
      query: relationsShips,
    })

    commit('setRelationsShips', relationships)
  },
  createPersonReference: async (
    { dispatch, rootState: { apolloClient } },
    { reference, personId }
  ): Promise<void> => {
    if (!apolloClient) {
      return
    }

    const data = {
      phone: reference.phone,
      name: reference.name,
      surname: reference.surname,
    }

    if (!isEmpty(reference.lastName)) {
      // @ts-ignore
      data.secondName = reference.secondName
    }
    if (!isEmpty(reference.lastSurname)) {
      // @ts-ignore
      data.secondSurname = reference.secondName
    }

    const {
      data: { person },
    } = await apolloClient.mutate({
      mutation: personReference,
      variables: data,
    })

    await dispatch('createReference', {
      id: person.id,
      personId,
      relation: reference.relationship,
    })
  },
  createReference: async (
    { commit, rootState: { apolloClient } },
    reference
  ): Promise<void> => {
    if (!apolloClient) {
      return
    }

    const referenceData = {
      relation: reference.relation,
      referenceId: reference.id,
      idPerson: reference.personId,
    }

    const {
      data: { personReferenceData },
    } = await apolloClient.mutate({
      mutation: createPersonReference,
      variables: referenceData,
    })

    window.dispatchEvent(
      new CustomEvent('notification-message', {
        // @ts-ignore
        detail: {
          message: 'Creada correctamente',
          type: 'success',
        },
      })
    )

    commit('setPersonReference', personReferenceData)
  },
}
