import gql from 'graphql-tag'
import { FileProcessFragment } from '@/graphql/fragments/files'

export const mutation = gql`
  ${FileProcessFragment}
  mutation insertFileProcess($fields: files_file_process_insert_input!) {
    record: insert_files_file_process_one(object: $fields) {
      ...FileProcess
    }
  }
`

export default mutation
