import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: ':role/staff_sales_purchases',
    name: 'staff_dashboard_purchases',
    meta: { inherit: true },
    component: () => import('./SalesPurchases.vue'),
  },
  {
    path: ':role/staff_leads',
    name: 'staff_dashboard_leads',
    meta: { inherit: true },
    component: () => import('./Leads.vue'),
  },
  {
    path: ':role/staff_inspections',
    name: 'staff_dashboard_inspections',
    meta: { inherit: true },
    component: () => import('./Inspections.vue'),
  },
  {
    path: ':role/staff_team',
    name: 'staff_dashboard_team',
    meta: { inherit: true },
    component: () => import('./Team.vue'),
  },
  {
    path: ':role/staff_all',
    name: 'staff_dashboard_all',
    meta: { inherit: true },
    component: () => import('./All.vue'),
  },
  {
    path: ':role/staff_stock',
    name: 'staff_dashboard_stock',
    meta: { inherit: true },
    component: () => import('./Stock.vue'),
  },
]

export default routes
