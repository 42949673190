import gql from 'graphql-tag'
import { ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { PersonFragment } from '@/graphql/fragments/persons'

export const findDashboardFinancing = gql`
  ${ClosingReasonFragment}
  ${PersonFragment}
  ${ProcessStatusFragment}
  query findDashboardFinancing($filter: loans_financing_bool_exp) {
    records: loans_financing(where: $filter) {
      id
      evaluations{
        id
        agreement {
          id
          person{
            ...Person
          }
        }
        closingReason:closing_reason {
          ...ClosingReason
        }
        status{
          id
          order
          ...ProcessStatus
          closingReasons:closing_reasons {
            ...ClosingReason
          }
        }
      }
    }
  }
`
