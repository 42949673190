import { Type } from 'class-transformer'
import { Entity } from '..'
import { ComponentType } from '@/entities/settings/ComponentType'
import { Field } from '.'

export class FieldType extends Entity {
    @Type(() => ComponentType)
    componentType: ComponentType;

    @Type(() => Field)
    fields: Field[];
}
