import gql from 'graphql-tag'

import { EvaluationRequirementFragment } from '@/graphql/fragments/loans'

export const mutation = gql`
  ${EvaluationRequirementFragment}
  mutation insertEvaluationRequirement($fields: loans_evaluation_requirement_insert_input!) {
    record: insert_loans_evaluation_requirement_one(object: $fields) {
      ...EvaluationRequirement
    }
  }
`

export default mutation
