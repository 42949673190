import { Type } from 'class-transformer'
import { Entity } from '..'

import { Evaluation } from './Evaluation'
import { RequirementType } from './RequirementType'

export class EvaluationRequirement extends Entity {
  @Type(() => Evaluation)
  evaluation: Evaluation;

  @Type(() => RequirementType)
  requirementType: RequirementType;

  get description () {
    const { requirementType: { description } } = this
    return description
  }
}
