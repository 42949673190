import { Entity } from '@/entities'
import { Type } from 'class-transformer'
import { BankData } from '@/entities/persons'
import dayjs from 'dayjs'
import 'dayjs/locale/es' // Importa el idioma español

dayjs.locale('es') // Establece el idioma a español

export class DailyAccountBalance extends Entity {
    @Type(() => BankData)
    bankAccount: BankData

    date: string
    accountBalance: number;
    availableBalance: number;
    usedCreditLine: number | null;
    creditAmount: number;
    dailyVariation: number;
    creditVariation: number;

    get dateFixed () {
      const { date } = this
      return dayjs(date).format('D [de] MMMM [del] YYYY')
    }

    get balance () {
      const { accountBalance, dailyVariation } = this

      return { value: accountBalance }
    }

    get available () {
      const { availableBalance } = this

      return { value: availableBalance }
    }

    get creditLine () {
      const { usedCreditLine } = this

      return { value: usedCreditLine }
    }

    get dailyAccountIsCurrentDay () {
      const { date } = this
      return dayjs(date).isSame(dayjs(), 'day')
    }

    get creditAvailable () {
      const { usedCreditLine, creditAmount } = this

      const value = (creditAmount || 0) - (usedCreditLine || 0)

      return { value, difference: 0 }
    }

    get variant () {
      const { dailyVariation } = this

      return { value: dailyVariation, difference: 0 }
    }

    get creditVariantValue () {
      const { creditVariation } = this

      return { value: creditVariation, difference: 0 }
    }
}
