import gql from 'graphql-tag'
import { QueryBuilder } from '../utils'
import { Evaluation } from '@/entities/loans'
import { EvaluationFragment, FinancingFragment } from '@/graphql/fragments/loans'
import { VersionYearFragment } from '@/graphql/fragments/vehicle'
import { ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import { PersonFragment } from '@/graphql/fragments/persons'
import { EmployeeFragment } from '@/graphql/fragments/hr'
import { SaleOrderItemFragment } from '@/graphql/fragments/sales'

const query = gql`
  ${EvaluationFragment}
  ${SaleOrderItemFragment}
  ${PersonFragment}
  ${BaseAutoFragment}
  ${VersionYearFragment}
  ${ProcessStatusFragment}
  ${EmployeeFragment}
  ${FinancingFragment}
  ${ClosingReasonFragment}
  query evaluations($filter: loans_evaluation_bool_exp, $order: [loans_evaluation_order_by!], $limit: Int, $offset: Int) {
    records: loans_evaluation(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      ...Evaluation
      financing {
        ...Financing
        saleOrder:sale_order {
          id
          status{
            ...ProcessStatus
          }
          saleOrderItems:sale_order_items{
            ...SaleOrderItem
          }
          deal {
            id
            closingReason:closing_reason {
              ...ClosingReason
            }
            status{
              ...ProcessStatus
            }
            lead {
              id
              executive {
                ...Employee
                person {
                  ...Person
                }
              }
              client {
                ...Person
              }
            }
            auto{
              ...AutoBase
              version: version_year {
                ...VersionYear
              }
              status: process_status {
                ...ProcessStatus
              }
            }
            stock{
              id
              status{
                ...ProcessStatus
              }
            }
          }
        }
      }
    }
    total: loans_evaluation_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const evaluations = QueryBuilder(query, Evaluation)
export default evaluations
