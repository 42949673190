import { Actions } from '../../interfaces'

import { EvaluationCondition } from '@/entities/loans'
import find from './find'
import fetch from './fetch'
import create from './create'
import update from './update'
// import remove from './remove'

export const actions: Actions = {
  find,
  fetch,
  create,
  update,
  // remove,
}

export default {
  Model: { EvaluationCondition },
  actions: { [EvaluationCondition.name]: actions },
}
