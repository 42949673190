import { Entity, Employee, Platform } from '@/models'
import { AppraisalStatusIcons, Price } from '@/models/interfaces'
import {
  Appraisal as IAppraisal,
  AppraisalStatus,
  PlatformOffer as IPlatformOffer,
  AppraisalRecord,
} from './interfaces'
import { fixPrice } from '@/utils/general'

export class PlatformOffer extends Entity implements IPlatformOffer {
  amount: number;
  platform: Platform;

  constructor (platformOffer: IPlatformOffer) {
    super(platformOffer)
    const { amount, platform } = platformOffer

    this.amount = amount
    this.platform = new Platform(platform)
  }
}

export class Appraisal extends Entity implements IAppraisal {
  responsible: Employee;
  price?: Price;
  status: AppraisalStatus;
  message?: string;
  offers: Array<PlatformOffer>;
  history?: Array<AppraisalRecord>;

  constructor (appraisal: IAppraisal) {
    super(appraisal)
    const { responsible, price, status, message, offers, history } = appraisal

    this.responsible = new Employee(responsible)
    this.price = price
    this.status = status
    this.message = message
    this.offers = offers.map(offer => new PlatformOffer(offer))
    this.history = history
  }

  get bestOffer (): string | undefined {
    const offers = this.offers.concat().sort(({ amount: a }, { amount: b }) => b - a)
    return fixPrice(offers[0]?.amount)
  }

  get last (): string | undefined {
    // TODO: Define were this value most be extracted from.
    const { price } = this
    return fixPrice(price?.min)
  }

  toString () {
    return AppraisalStatusIcons[this.status].name
  }
}
