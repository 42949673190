export abstract class Metadata {
  protected required: Array<string> = [];

  protected init (metadata) {
    if (!metadata) throw new Error('Metadata is required')

    const missing = this.required.filter(field => !metadata[field])
    if (missing.length) throw new Error(`Missing required metadata properties: ${missing}`)
  }

  abstract get model(): string
}
