import { Actions } from '../../interfaces'
import { Address } from '@/entities/persons'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { Address },
  actions: { [Address.name]: actions },
}
