import { Type } from 'class-transformer'
import { Entity } from '..'

import { Offer } from './Offer'
import { Currency, QuotaCharacteristic } from '../settings'

export class Quota extends Entity {
  @Type(() => Offer)
  offer: Offer;

  @Type(() => QuotaCharacteristic)
  characteristic: QuotaCharacteristic;

  @Type(() => Currency)
  currency: Currency;

  amount: number;
  idOffer: number;
  idQuotaCharacteristic: number;

  detail: object | null;
}
