import { Actions } from '../../interfaces'

import { PaymentOrderItem } from '@/entities/finance'
import create from './create'
import fetch from './fetch'
import find from './find'
import update from './update'

export const actions: Actions = {
  create,
  fetch,
  find,
  update,
}

export default {
  Model: { PaymentOrderItem },
  actions: { [PaymentOrderItem.name]: actions },
}
