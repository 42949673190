<template>
  <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_221_1225)">
      <path
        d="M508.069 129.292L481.984 31.659C480.113 24.659 473.772 19.789 466.526 19.789H45.474C38.228 19.789 31.887 24.659 30.016 31.659L3.931 129.292C1.212 139.467 0 148.701 0 159.233V194.105C0 223.26 19.603 247.906 46.315 255.6V431.21C46.315 464.846 73.68 492.21 107.315 492.21H404.684C438.319 492.21 465.684 464.846 465.684 431.21V255.6C492.397 247.906 512 223.26 512 194.105V159.233C512 148.701 510.788 139.467 508.069 129.292ZM57.76 51.789H454.24L475.164 130.105H36.836L57.76 51.789ZM384 194.105C384 211.75 369.645 226.105 352 226.105C334.355 226.105 320 211.75 320 194.105V162.105H384V194.105ZM128 194.105V162.105H192V194.095V194.105C192 211.75 177.645 226.105 160 226.105C142.355 226.105 128 211.75 128 194.105ZM224 194.105V162.105H288V194.105C288 211.75 273.645 226.105 256 226.105C238.355 226.105 224 211.75 224 194.105ZM32 194.105V162.105H96V194.095V194.105C96 211.75 81.645 226.105 64 226.105C46.355 226.105 32 211.75 32 194.105ZM291.369 460.211H220.632V349.895C220.632 340.144 228.565 332.21 238.317 332.21H273.685C283.436 332.21 291.37 340.144 291.37 349.895V460.211H291.369ZM404.685 460.211H323.369V349.895C323.369 322.499 301.08 300.21 273.684 300.21H238.316C210.92 300.21 188.631 322.498 188.631 349.895V460.211H107.315C91.324 460.211 78.315 447.201 78.315 431.211V256.481C91.579 253.438 103.288 246.271 112 236.395C123.736 249.7 140.905 258.106 160 258.106C179.095 258.106 196.264 249.7 208 236.394C219.736 249.7 236.906 258.106 256 258.106C275.094 258.106 292.264 249.7 304 236.394C315.736 249.7 332.906 258.106 352 258.106C371.094 258.106 388.264 249.7 400 236.395C408.712 246.271 420.42 253.438 433.684 256.481V431.211C433.684 447.201 420.675 460.211 404.685 460.211ZM448 226.105C430.355 226.105 416 211.75 416 194.105V162.105H480V194.105C480 211.75 465.645 226.105 448 226.105Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_221_1225">
        <rect width="512" height="512" fill="white" />
      </clipPath>
    </defs>
  </svg>

</template>

<script>
  export default {}
</script>
