import { Transform, Type } from 'class-transformer'
import dayjs, { Dayjs } from 'dayjs'
import duration, { Duration } from 'dayjs/plugin/duration'
import parse from 'postgres-interval'

import { Entity } from '..'

import { LeadActivity, DealType, Icon } from '.'

dayjs.extend(duration)

export class ActivityType extends Entity {
  @Type(() => DealType)
  dealType?: DealType;

  @Type(() => LeadActivity)
  leadActivities: LeadActivity[];

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs.duration(parse(value).toISOStringShort()), { toClassOnly: true })
  notifySince: Duration;

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs.duration(parse(value).toISOStringShort()), { toClassOnly: true })
  defaultDuration: Duration;

  @Type(() => Icon)
  icon: Icon;

  name: string;
  description: string;
  active: boolean;
  color: string;

  get defaultTime () {
    const { defaultDuration } = this

    return defaultDuration.asMinutes()
  }
}
