import { Type } from 'class-transformer'
import { Entity } from '..'

import { Appraisal } from './Appraisal'

export class AppraisalDetail extends Entity {
  @Type(() => Appraisal)
  appraisal: Appraisal

  rotationIndex: number
  averageMileage: number
  link: string
  vehicleNumber: number
  minimumPrice: number
  medianPrice: number
  maximumPrice: number
  zeroMileagePrice: number
  previousYearPrice: number
  currentYearPrice: number
  nextYearPrice: number
  estimatedPublicationPrice: number
  residualPercentage: number
  averageMileageConfig: number
  initialAppraisal: number
  bruteInitialAppraisal: number

  metadata: Record<string, any>
}
