import { RouteConfig } from 'vue-router'
import supervisor from './supervisor/router'
import staff from './staff/router'

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/layouts/dashboard/Index.vue'),
    meta: { requiresLogin: true, auth: { param: 'role' } },
    children: [
      ...supervisor,
      ...staff,
    ],
  },
]

export default routes
