import gql from 'graphql-tag'
import { AliasFragment } from '@/graphql/fragments/public'

export const mutation = gql`
  ${AliasFragment}
  mutation updateAlias($id: Int!, $fields: alias_set_input!) {
    record: update_alias_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...Alias
    }
  }
`

export default mutation
