import gql from 'graphql-tag'

import { ValidationTypeFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${ValidationTypeFragment}
  query findValidationType($filter: settings_validation_type_bool_exp, $order: [settings_validation_type_order_by!], $limit: Int){
    records: settings_validation_type(where: $filter, order_by: $order, limit: $limit) {
      ...ValidationType
    }
  }
`

export default query
