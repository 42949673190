import gql from 'graphql-tag'
import { PaymentOrderItemFragment } from '@/graphql/fragments/finance'

export const mutation = gql`
  ${PaymentOrderItemFragment}
  mutation updatePaymentOrderItem($id: Int!, $fields: finance_payment_order_item_set_input!) {
    record: update_finance_payment_order_item_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...PaymentOrderItem
    }
  }
`

export default mutation
