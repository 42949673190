import { Type } from 'class-transformer'
import { Entity } from '..'

import { FileParameter, Serialization } from '.'

export class FileType extends Entity {
  public static readonly photo = 'photo'
  public static readonly cav = 'cav'
  public static readonly legalReport = 'legal_report'
  public static readonly penaltyCertificate = 'penalty_certificate'
  @Type(() => FileParameter)
  parameters: FileParameter[];

  @Type(() => Serialization)
  serialization: Serialization;

  name: string;
  mimes: string;
  icon?: string;
  pattern?: string;

  get isPhoto () {
    const { name } = this
    return FileType.photo === name
  }

  get isCav () {
    const { name } = this

    return FileType.cav === name
  }

  get isLegalReport () {
    const { name } = this

    return FileType.legalReport === name
  }

  get isPenalty () {
    const { name } = this

    return FileType.penaltyCertificate === name
  }

  get isPdf () {
    const { isLegalReport, isCav, mimes } = this

    return isLegalReport || isCav || mimes.includes('pdf')
  }
}
