import { ActionTree } from 'vuex'
import { State as RootState } from '@/store/state'

import { Teams } from '@/store/settings/team/state'
import { TeamClass } from '@/store/settings/team/team'
import { UserManagement } from '@/store/user/user'
// @ts-ignore
import { insertRole, deleteRole } from './mutation'
import { getTeams } from '@/store/settings/team/queries/team'

const handleRolesChange = async (original: TeamClass, modified: TeamClass) => {
  const addRole: Array<number> = []
  const removeRole: Array<number> = []

  if (original.roles.length) {
    for (const roles of original.team.roles) {
      if (
        modified.team.roles.findIndex(
          role => role.rol.id === roles.rol.id
        ) === -1 && roles.id
      ) {
        removeRole.push(roles.id)
      }
    }
  }
  if (modified.roles.length) {
    for await (const rol of modified.roles) {
      if (original.roles.findIndex(copy => copy === rol) === -1) {
        addRole.push(rol)
      }
    }
  }

  return {
    add: addRole,
    remove: removeRole,
  }
}

export const actions: ActionTree<Teams, RootState> = {
  getTeams: async ({ commit, rootState: { apolloClient } }): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { teams },
    } = await apolloClient.query({ query: getTeams })

    const teamInitialized = teams.map(
      (team: UserManagement) => new TeamClass(team)
    )
    commit('setTeams', teamInitialized)
  },
  updateTeamsRoles: async (
    { rootState: { apolloClient } },
    { original, modified }
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { add, remove } = await handleRolesChange(original, modified)
      if (add.length) {
        add.forEach(value => {
          apolloClient.mutate({
            mutation: insertRole,
            variables: {
              userId: original.id,
              roleId: value,
            },
          })
        })
      }
      if (remove.length) {
        remove.forEach(value => {
          apolloClient.mutate({
            mutation: deleteRole,
            variables: {
              roleId: value,
            },
          })
        })
      }

      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: { message: 'Actualizado correctamente' },
        })
      )
    } catch (e: any) {
      window.dispatchEvent(
        new CustomEvent('notification-message', {
          detail: { message: e.message, type: 'error' },
        })
      )
    }
  },
}
