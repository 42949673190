import { Type } from 'class-transformer'
import { Entity } from '..'

import { Concessionaire, Model } from '.'

export class Brand extends Entity {
  @Type(() => Concessionaire)
  concessionaires: Concessionaire[];

  @Type(() => Model)
  models: Model[];

  name: string;
  officialWeb: string;
}
