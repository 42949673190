import { Type } from 'class-transformer'
import { Entity } from '..'

import { VersionYear } from '.'
import { Person } from '@/entities/persons'

export class MileagePrice extends Entity {
  @Type(() => VersionYear)
  versionYear: VersionYear;

  @Type(() => Person)
  person: Person;

  mileageFrom: number;
  mileageTo: number;
  dateFrom: string;
  dateTo: string | null;
  link: string;
  amount: number;
  amountFinanced: number;
  idGeneration: number;
  idVersion: number;
}
