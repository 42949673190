<template>
  <svg
    width="27"
    height="24"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.6194 10.5998L20.6694 15.4198L21.8394 22.2398C21.8856 22.5148 21.8543 22.7971 21.7489 23.0552C21.6436 23.3133 21.4684 23.5369 21.2431 23.701C21.0177 23.865 20.7511 23.9631 20.4732 23.9841C20.1952 24.0051 19.9169 23.9482 19.6694 23.8198L13.5394 20.6098L7.40943 23.8198C7.16199 23.9482 6.88367 24.0051 6.60571 23.9841C6.32775 23.9631 6.06113 23.865 5.83578 23.701C5.61043 23.5369 5.43526 23.3133 5.32993 23.0552C5.22459 22.7971 5.19325 22.5148 5.23943 22.2398L6.40943 15.4198L1.40943 10.5998C1.1953 10.404 1.04259 10.1502 0.96981 9.86924C0.897031 9.58831 0.907309 9.29229 0.999398 9.01709C1.09149 8.74188 1.26143 8.49929 1.48863 8.31873C1.71584 8.13818 1.99054 8.02741 2.27943 7.99985L9.12943 6.99985L12.1894 0.839847C12.3133 0.588914 12.5049 0.377643 12.7426 0.229919C12.9803 0.0821938 13.2546 0.00390625 13.5344 0.00390625C13.8143 0.00390625 14.0886 0.0821938 14.3262 0.229919C14.5639 0.377643 14.7555 0.588914 14.8794 0.839847L17.9994 6.99985L24.8394 7.99985C25.1209 8.04192 25.3847 8.16323 25.5998 8.3496C25.815 8.53597 25.9726 8.77971 26.0544 9.05235C26.1362 9.32498 26.1388 9.61526 26.0617 9.88929C25.9847 10.1633 25.8313 10.4098 25.6194 10.5998Z"
      fill="#BABABA"
    />
    <g clip-path="url(#clip0_1716_347969)">
      <path
        d="M25.1706 10.2318L25.163 10.2386L25.1557 10.2458L20.2363 15.0689L20.0495 15.252L20.0935 15.5099L21.2559 22.3326C21.256 22.3329 21.256 22.3332 21.2561 22.3334C21.2866 22.5173 21.2657 22.706 21.1958 22.8784C21.1259 23.051 21.0097 23.2001 20.8608 23.3092C20.712 23.4183 20.5363 23.4833 20.3534 23.4972C20.1707 23.5111 19.9877 23.4735 19.8247 23.3886C19.8245 23.3884 19.8243 23.3883 19.824 23.3882L13.7337 20.177L13.5005 20.0541L13.2673 20.177L7.17689 23.3882C7.17666 23.3883 7.17643 23.3884 7.1762 23.3886C7.0132 23.4735 6.83018 23.5111 6.64754 23.4972C6.46465 23.4833 6.28891 23.4183 6.14008 23.3092C5.99122 23.2001 5.87507 23.051 5.8051 22.8784C5.73522 22.706 5.71431 22.5172 5.74486 22.3333C5.7449 22.3331 5.74494 22.3329 5.74498 22.3326L6.90745 15.5099L6.95166 15.2505L6.7628 15.0672L1.79372 10.244L1.79384 10.2439L1.78422 10.235C1.64259 10.1046 1.54119 9.9352 1.49281 9.74715C1.44442 9.55908 1.45126 9.36088 1.51247 9.17671C1.57366 8.99257 1.68642 8.83077 1.83658 8.71062C1.98669 8.59051 2.16781 8.5171 2.3579 8.49884L2.3704 8.49764L2.38281 8.49581L9.19045 7.49516L9.45004 7.45701L9.56613 7.2217L12.6072 1.05771L12.6077 1.05662C12.69 0.888904 12.8169 0.748187 12.9738 0.650007C13.1307 0.551848 13.3114 0.5 13.4955 0.5C13.6796 0.5 13.8603 0.551848 14.0172 0.650007C14.1741 0.748187 14.301 0.888904 14.3832 1.05662L14.3832 1.05663L14.3855 1.06117L17.4862 7.22517L17.603 7.4573L17.8601 7.49515L24.6562 8.49556C24.6564 8.49559 24.6566 8.49563 24.6568 8.49566C24.8418 8.52363 25.0156 8.60402 25.1576 8.72792C25.2999 8.85199 25.4045 9.01463 25.4589 9.19708C25.5133 9.37956 25.5149 9.57393 25.4637 9.75734C25.4125 9.94074 25.3107 10.1052 25.1706 10.2318Z"
        fill="#FFE925"
        stroke="#FFC93D"
      />
      <path
        opacity="0.55"
        d="M22.4793 11.5103L18.976 14.9267L19.8041 19.7607C19.8368 19.9555 19.8146 20.1556 19.74 20.3386C19.6655 20.5215 19.5415 20.68 19.382 20.7963C19.2225 20.9126 19.0338 20.9821 18.8371 20.997C18.6404 21.0119 18.4434 20.9716 18.2683 20.8806L13.9299 18.6053L9.59147 20.8806C9.41635 20.9716 9.21937 21.0119 9.02265 20.997C8.82592 20.9821 8.63723 20.9126 8.47774 20.7963C8.31826 20.68 8.19428 20.5215 8.11973 20.3386C8.04518 20.1556 8.023 19.9555 8.05568 19.7607L8.88373 14.9267L5.34506 11.5103C5.19351 11.3715 5.08543 11.1916 5.03393 10.9925C4.98242 10.7934 4.98969 10.5835 5.05487 10.3885C5.12004 10.1934 5.24032 10.0215 5.40112 9.89349C5.56191 9.76551 5.75633 9.687 5.96079 9.66747L10.8088 8.95867L12.9744 4.59251C13.0621 4.41465 13.1977 4.2649 13.366 4.1602C13.5342 4.05549 13.7283 4 13.9263 4C14.1244 4 14.3185 4.05549 14.4867 4.1602C14.655 4.2649 14.7906 4.41465 14.8782 4.59251L17.0864 8.95867L21.9273 9.66747C22.1265 9.69729 22.3132 9.78327 22.4654 9.91537C22.6177 10.0475 22.7293 10.2202 22.7872 10.4135C22.8451 10.6067 22.8469 10.8125 22.7923 11.0067C22.7378 11.2009 22.6293 11.3756 22.4793 11.5103Z"
        fill="url(#paint0_linear_1716_347969)"
      />
      <path
        opacity="0.55"
        d="M20.1095 11.6327L17.482 14.195L18.1031 17.8205C18.1276 17.9666 18.1109 18.1167 18.055 18.2539C17.9991 18.3911 17.9061 18.51 17.7865 18.5972C17.6669 18.6845 17.5254 18.7366 17.3778 18.7477C17.2303 18.7589 17.0826 18.7287 16.9512 18.6604L13.6974 16.954L10.4436 18.6604C10.3123 18.7287 10.1645 18.7589 10.017 18.7477C9.86944 18.7366 9.72792 18.6845 9.60831 18.5972C9.48869 18.51 9.39571 18.3911 9.3398 18.2539C9.28388 18.1167 9.26725 17.9666 9.29176 17.8205L9.9128 14.195L7.2588 11.6327C7.14513 11.5286 7.06408 11.3937 7.02544 11.2444C6.98681 11.095 6.99227 10.9377 7.04115 10.7914C7.09003 10.6451 7.18024 10.5161 7.30084 10.4201C7.42144 10.3241 7.56725 10.2652 7.72059 10.2506L11.3566 9.719L12.9808 6.44438C13.0466 6.31099 13.1483 6.19868 13.2745 6.12015C13.4006 6.04162 13.5462 6 13.6948 6C13.8433 6 13.9889 6.04162 14.115 6.12015C14.2412 6.19868 14.3429 6.31099 14.4087 6.44438L16.0648 9.719L19.6955 10.2506C19.8449 10.273 19.9849 10.3375 20.0991 10.4365C20.2133 10.5356 20.297 10.6652 20.3404 10.8101C20.3838 10.955 20.3851 11.1093 20.3443 11.255C20.3034 11.4007 20.2219 11.5317 20.1095 11.6327Z"
        fill="url(#paint1_linear_1716_347969)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1716_347969"
        x1="9.58037"
        y1="4.96667"
        x2="20.9314"
        y2="17.6914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFFFFD" />
        <stop
          offset="1"
          stop-color="#FFF492"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1716_347969"
        x1="10.4353"
        y1="6.725"
        x2="15.6411"
        y2="17.9426"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.322917"
          stop-color="white"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0"
        />
      </linearGradient>
      <clipPath id="clip0_1716_347969">
        <rect
          width="12.5408"
          height="24"
          fill="white"
          transform="translate(1.03906)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {}
</script>
