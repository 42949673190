import gql from 'graphql-tag'

import { FinancingFragment } from '@/graphql/fragments/loans'

export const mutation = gql`
  ${FinancingFragment}
  mutation insertFinancing($fields: loans_financing_insert_input!) {
    record: insert_loans_financing_one(object: $fields) {
      ...Financing
    }
  }
`

export default mutation
