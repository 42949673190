import { Entity } from '..'
import { Type } from 'class-transformer'

import { Country, City } from '.'

export class Region extends Entity {
  @Type(() => City)
  cities: City[];

  @Type(() => Country)
  country: Country;

  name: string;
  abbreviation: string | null;
}
