import { Type } from 'class-transformer'
import { Entity } from '..'

import { ProcessStatus, ProcessExpense } from '.'
import { FileParameter } from '../files'
import { InspectionInspectedComponent } from '../purchase'
import { DealAutoAttribute } from '../public'

export class Process extends Entity {
  @Type(() => DealAutoAttribute)
  dealAutoAttributes: DealAutoAttribute[];

  @Type(() => FileParameter)
  fileParameters: FileParameter[];

  @Type(() => InspectionInspectedComponent)
  inspectedComponents: InspectionInspectedComponent[];

  @Type(() => Process)
  parentProcess: Process;

  @Type(() => Process)
  childrenProcesses: Process[];

  @Type(() => ProcessExpense)
  processExpenses: ProcessExpense[];

  @Type(() => ProcessStatus)
  statuses: ProcessStatus[];

  schema: string;
  tableName: string;
  description: string;
  pointer: string;
  config: Record<string, any> | null;
}
