import gql from 'graphql-tag'

export const appraiseWithScrapping = gql`
  query appraisedWithScrapping($mileage: Float!, $idGeneration: Int, $idVersionYear: Int){
    response:appraise_with_scrapping(mileage: $mileage, idGeneration: $idGeneration, idVersionYear: $idVersionYear)
  }
`

export const automaticAppraisal = gql`
  query automaticAppraisal($id: Int!) {
    appraise_with_id(idAppraising: $id)
  }
`

export const getAppraisalWithAutoId = gql`
  query getAppraisalWithAutoId($id: Int!) {
    get_appraisals_with_auto_id(auto_id: $id)
  }
`
