import { Transform, Type } from 'class-transformer'
import { Entity } from '..'

import { Currency } from '.'
import dayjs, { Dayjs } from 'dayjs'

export class ExchangeRate extends Entity {
  @Type(() => Currency)
  currencyTo: Currency;

  @Type(() => Currency)
  currencyFrom: Currency;

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs(value), { toClassOnly: true })
  dateFrom: Dayjs;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  dateTo?: Dayjs;

  rate: number;
}
