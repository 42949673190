import gql from 'graphql-tag'
import { EntityFields } from '..'

export const MileagePriceFragment = gql`
  fragment MileagePrice on vehicle_mileage_price {
    ${EntityFields}
    mileageFrom:mileage_from
    mileageTo:mileage_to
    dateFrom:date_from
    dateTo:date_to
    link
    amount
    amountFinanced:amount_financed
    person{
      id
    }
    idGeneration:id_generation
    idVersion:id_version_year
  }
`
