import { GetterTree, MutationTree } from 'vuex'
import { State as RootState } from '@/store/state'

export interface Platforms {
  platforms: Array<Platform>
}

export interface Platform {
  id: number
  name: string
  status?: string
}

export function initState (): Platforms {
  return {
    platforms: [],
  }
}

export const mutations: MutationTree<Platforms> = {
  setPlatforms: (state, platforms) => {
    state.platforms = platforms
  },
}

export const getters: GetterTree<Platforms, RootState> = {
  getPlatforms: ({ platforms }): Array<Platform> => {
    return platforms
  },
}
