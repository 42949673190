import { Type } from 'class-transformer'
import { Entity } from '..'

import { Variant } from './Variant'
import { QuotaType } from './QuotaType'
import { Quota } from '../loans'

export class QuotaCharacteristic extends Entity {
  @Type(() => Quota)
  quotas: Quota[];

  @Type(() => Variant)
  variant: Variant;

  @Type(() => QuotaType)
  quotaType: QuotaType;

  frequency: number;
  type: string
  unit: string;

  idVariant: number | null;
}
