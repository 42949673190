import { Entity } from '@/models'
import {
  Person as IPerson, Employee as IEmployee,
  Platform as IPlatform, Company as ICompany,
} from './interfaces'

export class Person extends Entity implements IPerson {
  firstName: string;
  surname: string;
  email?: string;
  phone: string;

  constructor (person: IPerson) {
    super(person)
    const { firstName, surname, email, phone } = person

    this.firstName = firstName
    this.surname = surname
    this.email = email
    this.phone = phone
  }

  get name () {
    const { firstName, surname } = this
    return `${firstName} ${surname}`
  }

  get whatsappMe () {
    const { phone } = this
    if (!phone) return

    return `https://wa.me/${phone}`
  }

  toString () {
    return this.name
  }
}

export class Employee extends Person implements IEmployee {
  role?: string

  constructor (employee: IEmployee) {
    super(employee)
    const { role } = employee

    this.role = role
  }
}

export class Company extends Person implements ICompany {
  alias: string;
  contact?: Person;

  constructor (company: ICompany) {
    super(company as IPerson)
    const { alias, contact } = company

    this.alias = alias
    if (contact) this.contact = new Person(contact)
  }
}

export class Platform extends Company implements IPlatform {
  url?: string

  constructor (platform: IPlatform) {
    super(platform)
    const { url } = platform

    this.url = url
  }
}

export default class User {
}
