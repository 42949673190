import { Module } from 'vuex'

import { State as RootState } from '@/store/state'
import { Persons, initState, getters, mutations } from '@/store/persons/state'
import { actions } from '@/store/persons/actions'
import Address from '@/store/persons/address/index'
import Labor from '@/store/persons/labor'
import Bank from '@/store/persons/bank'
import Reference from '@/store/persons/reference'

const module: Module<Persons, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
  modules: {
    address: Address,
    labor: Labor,
    bank: Bank,
    reference: Reference,
  },
}

export default module
