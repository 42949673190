import { Transform, Type } from 'class-transformer'
import parse from 'postgres-interval'
import dayjs, { Dayjs } from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'
import { Entity } from '..'

import { ExtraGuarantee } from './ExtraGuarantee'
import { Reserve } from './Reserve'
import { SaleOrderItem } from './SaleOrderItem'
import { Financing } from '../loans'
import { ClosingReason, ProcessStatus } from '../settings'
import { Deal } from '../crm'
import { Employee } from '@/entities/hr'
import { Document as Transfer } from '@/entities/documents'
import { fixPrice, isValidNumber } from '@/utils/general'

export class SaleOrder extends Entity {
  @Type(() => ExtraGuarantee)
  extraGuarantees: ExtraGuarantee[];

  @Type(() => SaleOrderItem)
  saleOrderItems: SaleOrderItem[];

  @Type(() => Financing)
  financings: Financing[]

  @Type(() => ClosingReason)
  closingReason: ClosingReason

  @Type(() => Deal)
  deal: Deal

  @Type(() => Reserve)
  reserve: Reserve;

  @Type(() => Transfer)
  document: Transfer;

  @Type(() => ProcessStatus)
  status: ProcessStatus

  @Type(() => Employee)
  validator: Employee;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  deliveryDate: Dayjs;

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs.duration(parse(value).toISOStringShort()), { toClassOnly: true })
  duration?: Duration;

  cavValidation: Record<string, any> | null
  _discount: number
  declare _product?: SaleOrderItem

  get financing (): Financing | undefined {
    const { financings } = this
    return financings && financings[0]
  }

  get product () {
    const { _product } = this
    if (_product) return _product

    const { saleOrderItems } = this
    if (!saleOrderItems?.length) return undefined

    this._product = saleOrderItems.find(item => item.isProduct)
    return this._product
  }

  get productWithDiscount () {
    const { product, discount } = this
    if (!product || !discount?.value) return undefined

    const amount = product.amount - (discount?.value || 0)
    return {
      value: fixPrice(amount),
    }
  }

  get total () {
    const { saleOrderItems } = this
    return !saleOrderItems ? 0 : saleOrderItems.reduce((total, { subTotal }) => total + subTotal, 0)
  }

  get isFinanced () {
    const { financing } = this

    return Boolean(financing?.initial)
  }

  get discount () {
    const { saleOrderItems, _discount } = this

    if (isValidNumber(_discount)) return { value: _discount }
    if (!saleOrderItems?.length) return undefined

    const discount = saleOrderItems.find(item => item.isDiscount)

    if (!discount?.amount) return undefined

    return { value: Math.abs(discount?.amount || 0) }
  }

  get marginPositive () {
    const { discountCalculated, deal: { stock, lead } } = this
    if (discountCalculated?.value < stock?.price?.margin) {
      return fixPrice((stock?.price?.margin || 0) - (discountCalculated?.value || 0))
    } else if (lead.pipeline.name === 'renewal') {
      return '$0'
    }
    return fixPrice((stock?.price?.margin || 0) - (discountCalculated?.value || 0))
  }

  get priceWithOutDiscount () {
    const { saleOrderItems } = this

    const price = saleOrderItems.find(item => item.isProduct)

    if (!price?.amount) return undefined

    return { value: fixPrice(price?.amount) }
  }

  get transferAmount () {
    const { saleOrderItems } = this

    const transfer = saleOrderItems.find(item => item.isTransfer)

    if (!transfer?.amount) return undefined

    return { value: fixPrice(transfer?.amount) }
  }

  get priceSell () {
    const { saleOrderItems, discountCalculated } = this

    const product = saleOrderItems.find(item => item.isProduct)

    if (!product?.amount) return undefined

    const val = (product?.amount || 0) - (discountCalculated.value || 0)
    return {
      value: fixPrice(val),
    }
  }

  get discountApply () {
    const { saleOrderItems, _discount } = this
    if (isValidNumber(_discount)) return { value: _discount }
    if (!saleOrderItems?.length) return undefined

    const discount = saleOrderItems.find(item => item.isDiscount)
    const discountPostFinancing = saleOrderItems.find(item => item.isPostFinancing)
    if (!discount?.amount && !discountPostFinancing?.amount) return undefined
    const value = Math.abs(discount?.amount || 0) + Math.abs(discountPostFinancing?.amount || 0)
    return { value: fixPrice(value) }
  }

  get discountPostFinancing () {
    const { saleOrderItems } = this

    if (!saleOrderItems?.length) return undefined

    const discount = saleOrderItems.find(item => item.isPostFinancing)
    if (!discount?.amount) return undefined

    return { value: Math.abs(discount.amount || 0) }
  }

  get discountCalculated () {
    const { discount, discountPostFinancing } = this

    return { value: (discount?.value || 0) + (discountPostFinancing?.value || 0) }
  }

  get documentId () {
    const { deal, document } = this

    if (document?.id) return document.id

    if (!deal) return null

    const { stock } = deal

    if (!stock) return null

    const { transfer } = stock

    if (!transfer) return null

    return transfer.documentId
  }

  get stockDocumentType () {
    const { deal, document } = this

    if (document?.support) return document.support.name

    if (!deal) return null

    const { stock } = deal

    if (!stock) return null

    const { transfer } = stock

    if (!transfer) return null

    return transfer.document?.support?.name
  }

  get interveners () {
    const { deal, document } = this

    if (document?.interveners) {
      return document.interveners.filter(intervener => intervener.field.name === 'buyer').map(intervener => intervener.person)
    }

    if (!deal) return null

    const { stock } = deal

    if (!stock) return null

    const { transfer } = stock

    if (!transfer) return null

    return transfer.document?.interveners.filter(intervener => intervener.field.name === 'buyer').map(intervener => intervener.person)
  }

  get payments () {
    const { deal } = this

    if (!deal?.payments?.length) return ''

    const amount = deal.payments.map(payment => payment.amount).reduce((acc, curr) => acc + curr)
    return fixPrice(amount)
  }
}
