<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 24 24"
  >
    <path d="M9 7.50001C8.68882 7.49881 8.38497 7.59442 8.13055 7.77359C7.87613 7.95276 7.68372 8.20663 7.58 8.50001L5.5 14.5V22.5C5.5 22.7652 5.60536 23.0196 5.79289 23.2071C5.98043 23.3947 6.23478 23.5 6.5 23.5H7.5C7.76522 23.5 8.01957 23.3947 8.20711 23.2071C8.39464 23.0196 8.5 22.7652 8.5 22.5V21.5H13.8C13.6006 20.8521 13.4995 20.1779 13.5 19.5C13.5016 18.2905 13.8166 17.1021 14.4142 16.0506C15.0118 14.999 15.8717 14.1203 16.91 13.5H7.5L9 9.00001H20L21.18 12.5C21.793 12.5639 22.3949 12.7085 22.97 12.93L21.42 8.50001C21.3163 8.20663 21.1239 7.95276 20.8694 7.77359C20.615 7.59442 20.3112 7.49881 20 7.50001H9ZM9 15.5C9.39782 15.5 9.77936 15.658 10.0607 15.9394C10.342 16.2207 10.5 16.6022 10.5 17C10.5 17.3978 10.342 17.7794 10.0607 18.0607C9.77936 18.342 9.39782 18.5 9 18.5C8.60218 18.5 8.22064 18.342 7.93934 18.0607C7.65804 17.7794 7.5 17.3978 7.5 17C7.5 16.6022 7.65804 16.2207 7.93934 15.9394C8.22064 15.658 8.60218 15.5 9 15.5Z" />
    <path d="M16.4199 20.27H17.7399C17.7399 20.91 18.6499 21.46 19.7399 21.46C20.8299 21.46 21.7399 20.91 21.7399 20.27C21.7399 19.63 21.0499 19.39 19.5999 19.07C18.1499 18.75 16.4499 18.37 16.4499 16.73C16.4499 15.67 17.4499 14.73 18.7699 14.47V13.5H20.7699V14.5C21.3528 14.5625 21.8977 14.8195 22.3168 15.2295C22.7358 15.6395 23.0046 16.1786 23.0799 16.76H21.7099C21.7099 16.12 20.8099 15.57 19.7099 15.57C18.6099 15.57 17.7099 16.12 17.7099 16.76C17.7099 17.4 18.3999 17.64 19.8599 17.96C21.3199 18.28 22.9999 18.66 22.9999 20.3C22.9138 20.866 22.6439 21.388 22.2319 21.7855C21.8199 22.1831 21.2886 22.4341 20.7199 22.5V23.5H18.7199V22.5C17.3899 22.23 16.4199 21.33 16.4199 20.27Z" />
  </svg>
</template>

<script>
  export default {
  }
</script>
