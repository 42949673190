import { Entity, Icon } from '@/models/interfaces'
import { Company, Employee, Person } from '@/models/user/interfaces'

export enum FinancingStatus {
  pending,
  rejected,
  conditioned,
  approved,
  desisted,
  signed,
}

export interface Financing extends Entity {
  status: FinancingStatus
  debtor: Person
  responsible: Employee
  company: Company
  priority?: number
}

export const financingStatusIcons: Record<FinancingStatus, Icon> = {
  [FinancingStatus.pending]: {
    name: 'Pendiente',
    icon: 'mdi-circle',
    color: 'yellow',
  },
  [FinancingStatus.rejected]: {
    name: 'Rechazada',
    icon: 'mdi-circle',
    color: 'red',
  },
  [FinancingStatus.conditioned]: {
    name: 'Condicionada',
    icon: 'mdi-circle',
    color: 'orange',
  },
  [FinancingStatus.approved]: {
    name: 'Aprobada',
    icon: 'mdi-circle',
    color: 'green',
  },
  [FinancingStatus.desisted]: {
    name: 'Desistido',
    icon: 'mdi-circle',
    color: 'red',
  },
  [FinancingStatus.signed]: {
    name: 'Firmado',
    icon: 'mdi-circle',
    color: 'primary',
  },
}
