import gql from 'graphql-tag'

export const mutation = gql`
  mutation updateEmployee($id: Int!,$fields: hr_employee_set_input!) {
    record: update_hr_employee_by_pk(pk_columns: {id: $id}, _set: $fields) {
      id
    }
  }
`

export default mutation
