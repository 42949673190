import gql from 'graphql-tag'

import { ProjectionPaymentFragment } from '@/graphql/fragments/sales'

export const mutation = gql`
  ${ProjectionPaymentFragment}
  mutation updateProjectionPayment($id: Int!,$fields: sales_projection_payment_set_input!) {
    record: update_sales_projection_payment_by_pk(pk_columns: {id: $id}, _set: $fields){
      ...ProjectionPayment
    }
  }
`

export default mutation
