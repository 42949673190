import gql from 'graphql-tag'

import { PaymentOrderFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${PaymentOrderFragment}
  query fetchPaymentOrder($id: Int!) {
    records: finance_payment_order_by_pk(id: $id) {
      ...PaymentOrder
    }
  }
`

export default query
