import gql from 'graphql-tag'
import { MileagePriceFragment } from '@/graphql/fragments/vehicle/mileagePrice'

export const mutation = gql`
  ${MileagePriceFragment}
  mutation updateMileagePrice($id: Int!, $fields: vehicle_mileage_price_set_input!) {
    record: update_vehicle_mileage_price_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...MileagePrice
    }
  }
`

export default mutation
