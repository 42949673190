import gql from 'graphql-tag'
import { EntityFields } from '..'

export const AliasFragment = gql`
  fragment Alias on alias{
    ${EntityFields}
    name
    idProcessRecord: id_process_record
  }
`
