import { Actions } from '../../interfaces'

import { ProjectionPayment } from '@/entities/sales'
import create from './create'
import fetch from './fetch'
import update from './update'
import find from './find'
import remove from './remove'

export const actions: Actions = {
  create,
  fetch,
  update,
  find,
  remove,
}

export default {
  Model: { ProjectionPayment },
  actions: { [ProjectionPayment.name]: actions },
}
