<template>
  <svg
    width="27"
    height="24"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.1706 10.2318L25.163 10.2386L25.1557 10.2458L20.2363 15.0689L20.0495 15.252L20.0935 15.5099L21.2559 22.3326C21.256 22.3329 21.256 22.3332 21.2561 22.3334C21.2866 22.5173 21.2657 22.706 21.1958 22.8784C21.1259 23.051 21.0097 23.2001 20.8608 23.3092C20.712 23.4183 20.5363 23.4833 20.3534 23.4972C20.1707 23.5111 19.9877 23.4735 19.8247 23.3886C19.8245 23.3884 19.8243 23.3883 19.824 23.3882L13.7337 20.177L13.5005 20.0541L13.2673 20.177L7.17689 23.3882C7.17666 23.3883 7.17643 23.3884 7.1762 23.3886C7.0132 23.4735 6.83018 23.5111 6.64754 23.4972C6.46465 23.4833 6.28891 23.4183 6.14008 23.3092C5.99122 23.2001 5.87507 23.051 5.8051 22.8784C5.73522 22.706 5.71431 22.5172 5.74486 22.3333C5.7449 22.3331 5.74494 22.3329 5.74498 22.3326L6.90745 15.5099L6.95166 15.2505L6.7628 15.0672L1.79372 10.244L1.79384 10.2439L1.78422 10.235C1.64259 10.1046 1.54119 9.9352 1.49281 9.74715C1.44442 9.55908 1.45126 9.36088 1.51247 9.17671C1.57366 8.99257 1.68642 8.83077 1.83658 8.71062C1.98669 8.59051 2.16781 8.5171 2.3579 8.49884L2.3704 8.49764L2.38281 8.49581L9.19045 7.49516L9.45004 7.45701L9.56613 7.2217L12.6072 1.05771L12.6077 1.05662C12.69 0.888904 12.8169 0.748187 12.9738 0.650007C13.1307 0.551848 13.3114 0.5 13.4955 0.5C13.6796 0.5 13.8603 0.551848 14.0172 0.650007C14.1741 0.748187 14.301 0.888904 14.3832 1.05662L14.3832 1.05663L14.3855 1.06117L17.4862 7.22517L17.603 7.4573L17.8601 7.49515L24.6562 8.49556C24.6564 8.49559 24.6566 8.49563 24.6568 8.49566C24.8418 8.52363 25.0156 8.60402 25.1576 8.72792C25.2999 8.85199 25.4045 9.01463 25.4589 9.19708C25.5133 9.37956 25.5149 9.57393 25.4637 9.75734C25.4125 9.94074 25.3107 10.1052 25.1706 10.2318Z"
      fill="#FFE925"
      stroke="#FFC93D"
    />
    <path
      opacity="0.55"
      d="M22.3895 11.5103L18.8862 14.9267L19.7142 19.7607C19.7469 19.9555 19.7247 20.1556 19.6502 20.3386C19.5756 20.5215 19.4517 20.68 19.2922 20.7963C19.1327 20.9126 18.944 20.9821 18.7473 20.997C18.5506 21.0119 18.3536 20.9716 18.1785 20.8806L13.84 18.6053L9.50163 20.8806C9.3265 20.9716 9.12953 21.0119 8.9328 20.997C8.73608 20.9821 8.54739 20.9126 8.3879 20.7963C8.22841 20.68 8.10444 20.5215 8.02989 20.3386C7.95534 20.1556 7.93316 19.9555 7.96584 19.7607L8.79389 14.9267L5.25522 11.5103C5.10367 11.3715 4.99559 11.1916 4.94408 10.9925C4.89257 10.7934 4.89985 10.5835 4.96502 10.3885C5.0302 10.1934 5.15047 10.0215 5.31127 9.89349C5.47207 9.76551 5.66648 9.687 5.87095 9.66747L10.7189 8.95867L12.8846 4.59251C12.9723 4.41465 13.1079 4.2649 13.2761 4.1602C13.4443 4.05549 13.6384 4 13.8365 4C14.0346 4 14.2287 4.05549 14.3969 4.1602C14.5651 4.2649 14.7007 4.41465 14.7884 4.59251L16.9965 8.95867L21.8374 9.66747C22.0367 9.69729 22.2233 9.78327 22.3756 9.91537C22.5279 10.0475 22.6395 10.2202 22.6973 10.4135C22.7552 10.6067 22.757 10.8125 22.7025 11.0067C22.648 11.2009 22.5394 11.3756 22.3895 11.5103Z"
      fill="url(#paint0_linear_5999_145514)"
    />
    <path
      opacity="0.55"
      d="M19.9943 11.6327L17.3668 14.195L17.9878 17.8205C18.0123 17.9666 17.9957 18.1167 17.9398 18.2539C17.8839 18.3911 17.7909 18.51 17.6713 18.5972C17.5517 18.6845 17.4101 18.7366 17.2626 18.7477C17.1151 18.7589 16.9673 18.7287 16.836 18.6604L13.5822 16.954L10.3284 18.6604C10.197 18.7287 10.0493 18.7589 9.90175 18.7477C9.75421 18.7366 9.61269 18.6845 9.49307 18.5972C9.37346 18.51 9.28048 18.3911 9.22456 18.2539C9.16865 18.1167 9.15202 17.9666 9.17653 17.8205L9.79757 14.195L7.14356 11.6327C7.0299 11.5286 6.94884 11.3937 6.91021 11.2444C6.87158 11.095 6.87703 10.9377 6.92592 10.7914C6.9748 10.6451 7.065 10.5161 7.1856 10.4201C7.3062 10.3241 7.45201 10.2652 7.60536 10.2506L11.2413 9.719L12.8656 6.44438C12.9314 6.31099 13.0331 6.19868 13.1592 6.12015C13.2854 6.04162 13.431 6 13.5795 6C13.7281 6 13.8737 6.04162 13.9998 6.12015C14.126 6.19868 14.2277 6.31099 14.2935 6.44438L15.9496 9.719L19.5802 10.2506C19.7297 10.273 19.8696 10.3375 19.9838 10.4365C20.098 10.5356 20.1817 10.6652 20.2252 10.8101C20.2686 10.955 20.2699 11.1093 20.229 11.255C20.1881 11.4007 20.1067 11.5317 19.9943 11.6327Z"
      fill="url(#paint1_linear_5999_145514)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5999_145514"
        x1="9.49052"
        y1="4.96667"
        x2="20.8416"
        y2="17.6914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFFFFD" />
        <stop
          offset="1"
          stop-color="#FFF492"
          stop-opacity="0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5999_145514"
        x1="10.32"
        y1="6.725"
        x2="15.5258"
        y2="17.9426"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.322917"
          stop-color="white"
        />
        <stop
          offset="1"
          stop-color="white"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
  export default {}
</script>
