import gql from 'graphql-tag'

import { PaymentFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${PaymentFragment}
  query fetchPayment($id: Int!) {
    records: finance_payment_by_pk(id: $id) {
      ...Payment
      deal{
        id
        idLead: id_lead
        stock{
          id
        }
      }
    }
  }
`

export default query
