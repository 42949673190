import gql from 'graphql-tag'
import { EntityFields } from '..'

export const UserFragment = gql`
  fragment User on user {
    ${EntityFields}
    email
    lastSeen:last_seen
  }
`
