import gql from 'graphql-tag'
import { EntityFields } from '..'
import { QuotaTypeFragment } from './quotaType'

export const BaseQuotaCharacteristicFragment = gql`
  fragment BaseQuotaCharacteristic on settings_quota_characteristic {
    ${EntityFields}
    frequency
    unit
    type
  }
`

export const QuotaCharacteristicFragment = gql`
  ${BaseQuotaCharacteristicFragment}
  ${QuotaTypeFragment}
  fragment QuotaCharacteristic on settings_quota_characteristic {
    ...BaseQuotaCharacteristic
    quotaType: quota_type {
      ...QuotaType
    }
  }
`
