import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/schedule',
    name: 'schedule',
    redirect: { name: 'schedule-index' },
    component: () => import('@/layouts/app/Index.vue'),
    children: [
      {
        path: 'index',
        name: 'schedule-index',
        meta: { inherit: true },
        redirect: { name: 'inspection-schedule' },
        component: () => import('./ScheduleIndex.vue'),
        children: [
          {
            path: 'inspection',
            name: 'inspection-schedule',
            meta: { inherit: true },
            component: () => import('./GroupScheduleView.vue'),
          },
          {
            path: 'delivery',
            name: 'delivery-schedule',
            meta: { inherit: true },
            component: () => import('./DeliveryScheduleView.vue'),
          },
          {
            path: 'staff',
            name: 'staff-schedule',
            meta: { inherit: true },
            component: () => import('./ScheduleView.vue'),
          },
        ],
      },
    ],
  },
]

export default routes
