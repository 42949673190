import { Type } from 'class-transformer'
import { Entity } from '..'

import { Document } from '.'

export class TransferView extends Entity {
  @Type(() => Document)
  document: Document;

  documentId: number;

  get interveners () {
    const { document } = this

    if (!document?.interveners) return null

    return document.interveners.filter(intervener => intervener.field.name === 'buyer').map(intervener => intervener.person)
  }
}
