import gql from 'graphql-tag'
import { EntityFields } from '..'
import { DealTypeFragment } from './dealType'

export const BaseActivityTypeFragment = gql`
  fragment ActivityTypeBase on crm_activity_type {
    ${EntityFields}
    name
    description
    active
    notifySince: notify_since
    color
    icon
    defaultDuration: default_duration
  }
`

export const ActivityTypeFragment = gql`
  ${BaseActivityTypeFragment}
  ${DealTypeFragment}
  fragment ActivityType on crm_activity_type {
    ...ActivityTypeBase
    dealType: deal_type {
      ...DealType
    }
  }
`
