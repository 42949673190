import gql from 'graphql-tag'
import { QueryBuilder } from '../utils'

import { Inspection } from '@/entities/purchase'
import {
  AppraisalFragment,
  EnablementFragment,
  InspectedComponentFragment,
  InspectionFragment,
  NegotiationFragment,
} from '@/graphql/fragments/purchase'
import { BaseLeadFragment, PipelineFragment } from '@/graphql/fragments/crm'
import { PersonFragment } from '@/graphql/fragments/persons'
import { EmployeeFragment } from '@/graphql/fragments/hr'
import { AutoFragment } from '@/graphql/fragments/public'

const query = gql`
  ${InspectionFragment}
  ${InspectedComponentFragment}
  ${NegotiationFragment}
  ${AppraisalFragment}
  ${AutoFragment}
  ${BaseLeadFragment}
  ${PersonFragment}
  ${EnablementFragment}
  ${EmployeeFragment}
  ${PipelineFragment}
  query inspections($filter: purchase_inspection_bool_exp, $order: [purchase_inspection_order_by!], $limit: Int, $offset: Int) {
    records: purchase_inspection(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      ...InspectionBase
      status {
        ...ProcessStatus
      }
      inspector {
        ...Employee
        person{
          ...Person
        }
      }
      supervisor {
        ...Employee
        person{
          ...Person
        }
      }
      closingReason: closing_reason {
        ...ClosingReason
      }
      address {
        ...PersonAddress
      }
      appraisal {
        id
        deal {
          id
          auto {
            ...Auto
          }
          lead{
            id
            pipeline {
              ...Pipeline
            }
            executive {
              ...Employee
              person{
                ...Person
              }
            }
            client {
              ...Person
            }
          }
        }
      }
    }
    total: purchase_inspection_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const inspections = QueryBuilder(query, Inspection)
export default inspections
