import { Module } from 'vuex'

import { State as RootState } from '@/store/state'
import { Vehicles, initState, mutations, getters } from './state'
import Information from '@/store/vehicles/information'
import actions from './actions'
import Platforms from '@/store/vehicles/platform'
import Catalogue from '@/store/vehicles/catalogue'

const module: Module<Vehicles, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  getters,
  actions,
  modules: {
    information: Information,
    platform: Platforms,
    catalogue: Catalogue,
  },
}

export default module
