import gql from 'graphql-tag'

export const findDocument = gql`
  query findDocument($id: Int!){
    evaluation:loans_evaluation(where: {id: {_eq:$id}}){
      id
      financing{
        id
        sale_order{
          id
          deal{
            id
            stock{
              id
              transfer{
                document_id
              }
            }
          }
        }
      }
    }
  }
`
