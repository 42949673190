import { ActionTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Information } from '@/store/vehicles/information/state'
import {
  getBrands,
  getComponents,
  getModels,
  getVersions,
  getYears,
} from '@/store/vehicles/information/queries/allInformation'

const actions: ActionTree<Information, RootState> = {
  loadInformation: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) return

    const observer = apolloClient.subscribe({
      query: getBrands,
    })

    observer.subscribe({
      next ({ data: { brands } }) {
        commit('setBrands', brands)
      },
    })

    const {
      data: { years },
    } = await apolloClient.query({ query: getYears })

    commit('setYears', years)

    const {
      data: { components },
    } = await apolloClient.query({ query: getComponents })

    commit('setComponents', components)
  },
  loadModels: async (
    { commit, rootState: { apolloClient } },
    { id, filter = false }
  ): Promise<void> => {
    if (!apolloClient || !id) return

    const observer = apolloClient.subscribe({
      query: getModels,
      variables: { id },
    })

    observer.subscribe({
      next ({ data: { models } }) {
        if (filter) {
          commit('setModelsFilters', models)
          return
        }
        commit('setModels', models)
      },
    })
  },
  loadVersions: async (
    { commit, rootState: { apolloClient } },
    { modelId, yearId, filter = false }
  ): Promise<void> => {
    if (!apolloClient) return

    const observer = apolloClient.subscribe({
      query: getVersions,
      variables: { modelId, yearId },
    })

    observer.subscribe({
      next ({ data: { versions } }) {
        if (filter) {
          commit('setVersionsFilters', versions)
          return
        }

        commit('setVersions', versions)
      },
    })
  },
}

export default actions
