import gql from 'graphql-tag'
import { VersionYearFragment } from '@/graphql/fragments/vehicle'
import { MileagePriceFragment } from '@/graphql/fragments/vehicle/mileagePrice'

export const query = gql`
  ${VersionYearFragment}
  ${MileagePriceFragment}
  query versionYear($filter: vehicle_version_year_bool_exp, $order: [vehicle_version_year_order_by!], $limit: Int, $offset: Int) {
    records: vehicle_version_year(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      ...VersionYear
      mileagePrices:mileage_prices {
        ...MileagePrice
      }
    }
  }

`
export default query
