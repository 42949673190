import { Roles } from '@/store/settings/roles/state'

export class Rol {
  public rol: Roles;

  constructor (rol: Roles) {
    this.rol = rol
  }

  get id (): number {
    return <number> this.rol.id
  }

  get name (): string {
    return this.rol.name
  }

  get description (): string {
    return this.rol.description
  }

  get slug (): string {
    return this.rol.slug
  }

  get resources (): (number | undefined)[] {
    return this.rol.permissions.map(resource => resource.resourceId)
  }

  set resources (resources: (number | undefined)[]) {
    this.rol.permissions = resources.map(resource => {
      return { resourceId: resource }
    })
  }
}
