import gql from 'graphql-tag'
import { PaymentRecipientFragment } from '@/graphql/fragments/finance'

export const mutation = gql`
  ${PaymentRecipientFragment}
  mutation deletePaymentRecipient($id: Int!) {
    record: delete_finance_payment_recipient_by_pk(id: $id) {
      ...PaymentRecipient
    }
  }
`

export default mutation
