import { Type } from 'class-transformer'
import { Entity } from '..'

import { Deal } from '.'

export class Intention extends Entity {
  @Type(() => Deal)
  deals: Deal[];

  name: string;
  description: string;
}
