import { UserManagement } from '@/store/user/user'
import initials from 'initials'

export class TeamClass {
  public team: UserManagement;

  constructor (team: UserManagement) {
    this.team = team
  }

  get id (): number {
    return this.team.id
  }

  get name (): string {
    if (!this.team.name) return this.team.email
    return this.team.name
  }

  get email (): string {
    return this.team.email
  }

  get photoUrl (): string | undefined {
    if (!this.team.photoUrl) return undefined
    return this.team.photoUrl
  }

  get roles (): Array<number> {
    return this.team.roles.map(role => role.rol.id)
  }

  set roles (roles: Array<number>) {
    this.team.roles = roles.map(role => {
      return { rol: { id: role } }
    })
  }

  get initials (): string | string[] {
    return this.findInitials()
  }

  findInitials (): string | string[] {
    const { name, email } = this.team
    return initials((name as string) || (email as string))
  }
}
