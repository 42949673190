import gql from 'graphql-tag'

import { PaymentFragment } from '@/graphql/fragments/finance'
import {
  BasePersonAccountFragment,
  GenderFragment,
  MaritalStatusFragment,
  PersonFragment,
} from '@/graphql/fragments/persons'
import { BaseAutoFragment, CountryFragment } from '@/graphql/fragments/public'
import { VersionYearFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${PaymentFragment}
  ${GenderFragment}
  ${MaritalStatusFragment}
  ${CountryFragment}
  ${BasePersonAccountFragment}
  ${PersonFragment}
  ${BaseAutoFragment}
  ${VersionYearFragment}
  query fetchPayment($id: Int!) {
    records: finance_payment_by_pk(id: $id) {
      ...Payment
      paymentRecipient: payment_recipient {
        id
        paymentOrder: payment_order {
          id
          idProcessRecord:id_process_record
          idProcess:id_process
        }
        paymentOrderItem:payment_order_item{
          id
          amount
          processExpense:process_expense {
            id
            expense{
              id
              name
              description
            }
          }
        }
        person{
          ...Person
          gender: gender {
            ...Gender
          }
          maritalStatus: marital_status {
            ...MaritalStatus
          }
          country {
            ...Country
          }
        }
      }
      deal{
        id
        auto{
          ...AutoBase
          version: version_year {
            ...VersionYear
          }
        }
        lead{
          id
          executive{
            id
            person{
              ...Person
            }
          }
        }
      }
    }
  }
`

export default query
