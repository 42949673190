<template>
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9019 4.5C19.0566 2.5 21.9434 2.5 23.0981 4.5L35.6554 26.25C36.8101 28.25 35.3668 30.75 33.0574 30.75H7.94263C5.63323 30.75 4.18985 28.25 5.34456 26.25L17.9019 4.5Z"
      fill="#FAD901"
    />
    <path
      d="M21.8352 10.5851L21.5468 21.3423H19.4398L19.1514 10.5851H21.8352ZM20.5709 26.1553C20.1125 26.1553 19.7281 26 19.4176 25.6895C19.1071 25.379 18.9518 24.9945 18.9518 24.5361C18.9518 24.0778 19.1071 23.6933 19.4176 23.3828C19.7281 23.0723 20.1125 22.917 20.5709 22.917C21.0145 22.917 21.3916 23.0723 21.7021 23.3828C22.0126 23.6933 22.1679 24.0778 22.1679 24.5361C22.1679 24.9945 22.0126 25.379 21.7021 25.6895C21.3916 26 21.0145 26.1553 20.5709 26.1553Z"
      fill="#101038"
    />
  </svg>
</template>

<script>
  export default {}
</script>
