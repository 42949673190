import gql from 'graphql-tag'
import { CurrencyFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${CurrencyFragment}
  query findCurrency($filter: settings_currency_bool_exp!,$order: [settings_currency_order_by!], $limit: Int){
    records:settings_currency(where: $filter,order_by: $order, limit: $limit) {
      ...Currency
    }
  }
`

export default query
