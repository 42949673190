import { Type } from 'class-transformer'
import { Entity } from '..'

import { ClosingReason, Icon } from '.'

export class ClosingReasonType extends Entity {
  public static readonly expired = 'expired'
  public static readonly notQualify = 'not_qualify'
  public static readonly notMatch = 'not_match'
  public static readonly successful = 'successful'
  public static readonly canceled = 'canceled'
  public static readonly withoutDeal = 'without_deal'
  public static readonly signed = 'signed'
  public static readonly scheduledInspection = 'scheduled_inspection'
  public static readonly financialMisbehavior = 'financial_misbehavior'

  @Type(() => ClosingReason)
  closingReasons: ClosingReason[];

  @Type(() => Icon)
  icon?: Icon

  name: string;
  description: string;
  color?: string;

  get title (): string {
    return this.description
  }
}
