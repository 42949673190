import { Actions } from '../../interfaces'

import { TransferView } from '@/entities/documents'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { TransferView },
  actions: { [TransferView.name]: actions },
}
