import gql from 'graphql-tag'
import { CarFragment } from '@/graphql/fragments/public'

export const query = gql`
  ${CarFragment}
  query findCar($filter: car_bool_exp!, $limit: Int = 6,$order: [car_order_by!]) {
    records: car(where: $filter, limit: $limit, order_by: $order){
      ...Car
    }
  }
`
export default query
