import { Type } from 'class-transformer'
import { Entity } from '..'

import { AutomotiveRegistration, Component, ComponentValue } from '.'

export class RegistrationAttribute extends Entity {
  @Type(() => AutomotiveRegistration)
  automotiveRegistration: AutomotiveRegistration;

  @Type(() => Component)
  component: Component;

  @Type(() => ComponentValue)
  componentValue: ComponentValue;

  value?: string;
}
