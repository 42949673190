<template>
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 0C4.3 0 3 1.3 3 3C3 4.7 4.3 6 6 6C7.3 6 8.4 5.2 8.8 4H11V6H13V4H15V2H8.8C8.4 0.8 7.3 0 6 0ZM6 2C6.6 2 7 2.4 7 3C7 3.6 6.6 4 6 4C5.4 4 5 3.6 5 3C5 2.4 5.4 2 6 2ZM3.5 8C2.8 8 2.3 8.4 2.1 9L0 15V23C0 23.6 0.4 24 1 24H2C2.6 24 3 23.6 3 23V22H15V23C15 23.6 15.4 24 16 24H17C17.6 24 18 23.6 18 23V15L15.9 9C15.7 8.4 15.1 8 14.5 8H3.5ZM3.5 9.5H14.5L16 14H2L3.5 9.5ZM3.5 16C4.3 16 5 16.7 5 17.5C5 18.3 4.3 19 3.5 19C2.7 19 2 18.3 2 17.5C2 16.7 2.7 16 3.5 16ZM14.5 16C15.3 16 16 16.7 16 17.5C16 18.3 15.3 19 14.5 19C13.7 19 13 18.3 13 17.5C13 16.7 13.7 16 14.5 16Z"
      fill="white"
    />
  </svg>
</template>

<script>
  export default {}
</script>
