<template>
  <svg
    width="221"
    height="221"
    viewBox="0 0 221 221"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_630_11827)">
      <path
        d="M14 106.5C14 53.2045 57.2045 10 110.5 10V10C163.795 10 207 53.2045 207 106.5V106.5C207 159.795 163.795 203 110.5 203V203C57.2045 203 14 159.795 14 106.5V106.5Z"
      />
      <path
        d="M132.766 93.5234L131.594 85.125H137.062C139.602 85.125 141.75 83.1719 141.75 80.4375V61.6875C141.75 59.1484 139.602 57 137.062 57H83.9375C81.2031 57 79.25 59.1484 79.25 61.6875V80.4375C79.25 83.1719 81.2031 85.125 83.9375 85.125H89.2109L88.0391 93.5234C78.6641 99.9688 73 109.93 73 121.062C73 123.797 74.9531 125.75 77.6875 125.75H105.812V146.062C105.812 146.258 105.812 146.453 105.812 146.648L108.938 156.023C109.328 157.391 111.477 157.391 111.867 156.023L114.992 146.648C114.992 146.453 115.188 146.258 115.188 146.062V125.75H143.312C145.852 125.75 148 123.797 148 121.062C148 109.93 142.141 99.9688 132.766 93.5234ZM82.7656 116.375C84.3281 108.953 89.7969 102.703 96.8281 99.3828L99.9531 75.75H88.625V66.375H132.375V75.75H121.047L124.172 99.3828C131.008 102.703 136.477 108.953 138.039 116.375H82.7656Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_630_11827"
        x="0"
        y="0"
        width="221"
        height="221"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.380392 0 0 0 0 0.521569 0 0 0 0 0.858824 0 0 0 0.3 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_630_11827"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_630_11827"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
  export default {}
</script>
