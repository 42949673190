import gql from 'graphql-tag'
import { EntityFields } from '..'

export const BaseSaleProductFragment = gql`
  fragment BaseSaleProduct on sales_sale_product {
    ${EntityFields}
    cost
    comment
    name
  }
`

export const SaleProductFragment = gql`
  ${BaseSaleProductFragment}
  fragment SaleProduct on sales_sale_product {
    ...BaseSaleProduct
    company{
      id
    }
    broker{
      id
    }
    deductible:deductible {
      id
    }
    offer {
      id
    }
  }
`
