import gql from 'graphql-tag'
import { PaymentOrderFragment } from '@/graphql/fragments/finance'

export const mutation = gql`
  ${PaymentOrderFragment}
  mutation insertPaymentOrder($fields: finance_payment_order_insert_input!) {
    record: insert_finance_payment_order_one(object: $fields) {
      ...PaymentOrder
    }
  }
`

export default mutation
