import gql from 'graphql-tag'

import { BaseSaleOrderFragment, ReserveFragment, SaleOrderFragment, StockFragment } from '@/graphql/fragments/sales'
import { FinancingFragment } from '@/graphql/fragments/loans'
import { PersonFragment } from '@/graphql/fragments/persons'
import { EmployeeFragment } from '@/graphql/fragments/hr'
import { ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${SaleOrderFragment}
  ${FinancingFragment}
  ${StockFragment}
  ${PersonFragment}
  ${BaseSaleOrderFragment}
  ${ClosingReasonFragment}
  ${PersonFragment}
  ${ReserveFragment}
  ${ProcessStatusFragment}
  ${EmployeeFragment}
  query fetchLiteSaleOrder($id: Int!) {
    records: sales_sale_order_by_pk(id: $id) {
      ...BaseSaleOrder
      closingReason: closing_reason {
        ...ClosingReason
      }
      reserve {
        ...Reserve
      }
      validator {
        ...Employee
        person{
          ...Person
        }
      }
      status {
        ...ProcessStatus
      }
    }
  }
`

export default query
