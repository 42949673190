import gql from 'graphql-tag'
import { AggregatePath } from '@/entities/interfaces'
import { ProcessStatusFragment } from '@/graphql/fragments/settings'

const structure: Array<AggregatePath> = [
  { name: 'stocks', title: 'Stock', aggregations: ['count'] },
]
const query = gql`
  ${ProcessStatusFragment}
  query aggregates($model: settings_process_status_bool_exp, $aggregate: sales_stock_bool_exp, $order: [settings_process_status_order_by!]) {
    aggregates: settings_process_status(where: $model, order_by: $order) {
      ...ProcessStatus
      stocks: stocks_aggregate(where: $aggregate) {
        aggregate {
          count(distinct: true)
        }
      }
    }
  }
`
export const aggregate = { structure, query }
export default aggregate
