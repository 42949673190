import { Actions } from '../../interfaces'

import { Field } from '@/entities/documents'
import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { Field },
  actions: { [Field.name]: actions },
}
