import { Actions } from '../interfaces'

import file from './file'
import fileType from './fileType'
import fileProcess from './fileProcess'
import fileParameter from './fileParameter'

export const Models = {
  ...file.Model,
  ...fileType.Model,
  ...fileProcess.Model,
  ...fileParameter.Model,
}

export const actions: Record<string, Actions> = {
  ...file.actions,
  ...fileType.actions,
  ...fileProcess.actions,
  ...fileParameter.actions,
}

export const schema = { Models, actions }
export default schema
