import { Type } from 'class-transformer'
import { Entity } from '..'

import { Person } from '.'

export class CompanyType extends Entity {
    @Type(() => Person)
    persons: Person[];

    name: string;
}
