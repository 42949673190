<template>
  <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_508_4349)">
      <path
        d="M18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H10.16C9.09878 15.1386 8.27595 14.0197 7.77 12.75C7.68991 12.5511 7.68991 12.3289 7.77 12.13C8.33837 10.6561 9.34033 9.38918 10.6436 8.4965C11.9469 7.60383 13.4903 7.12737 15.07 7.13C16.8694 7.12742 18.6132 7.75348 20 8.9V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0ZM18 7H2V4H18V7Z"
      />
      <path
        d="M20.5705 10.68C20.4138 10.4754 20.2434 10.2816 20.0605 10.1L20.0005 10C19.7768 9.77517 19.5361 9.56789 19.2805 9.38003C18.4207 8.73127 17.4226 8.28971 16.3642 8.08972C15.3057 7.88973 14.2154 7.93671 13.1781 8.22701C12.1408 8.5173 11.1844 9.04308 10.3835 9.76341C9.58264 10.4837 8.95879 11.3792 8.56055 12.38C9.0431 13.5745 9.8459 14.6126 10.8805 15.38C11.0705 15.52 11.2705 15.65 11.4705 15.77C11.5333 15.8191 11.6002 15.8626 11.6705 15.9C12.0974 16.1408 12.5503 16.332 13.0205 16.47C14.3559 16.8836 15.7852 16.8836 17.1205 16.47C17.6717 16.3049 18.1993 16.0696 18.6905 15.77C19.0021 15.583 19.2966 15.3688 19.5705 15.13C19.9421 14.8373 20.2778 14.5016 20.5705 14.13C20.9892 13.5925 21.3262 12.996 21.5705 12.36C21.3195 11.755 20.9827 11.1892 20.5705 10.68ZM15.0705 15.4C14.6048 15.3962 14.1464 15.2839 13.7315 15.0721C13.3167 14.8604 12.9569 14.555 12.6805 14.18C12.3089 13.6761 12.1091 13.0661 12.1105 12.44C12.1344 11.8247 12.3471 11.2316 12.7197 10.7413C13.0923 10.251 13.6067 9.88726 14.1932 9.69942C14.7796 9.51158 15.4097 9.50876 15.9978 9.69135C16.5859 9.87394 17.1036 10.2331 17.4805 10.72C17.8304 11.2245 18.0123 11.8263 18.0005 12.44C17.9904 13.2168 17.6794 13.9593 17.1329 14.5113C16.5864 15.0634 15.8471 15.382 15.0705 15.4Z"
      />
      <path
        d="M17.4205 12.4401C17.4179 13.0616 17.1691 13.6568 16.7287 14.0953C16.2883 14.5339 15.6921 14.7801 15.0705 14.7801C14.6994 14.779 14.3342 14.6873 14.0066 14.5131C13.679 14.3388 13.3989 14.0872 13.1905 13.7801C12.8887 13.3875 12.7268 12.9053 12.7305 12.4101C12.7291 11.9099 12.8879 11.4224 13.1838 11.0191C13.4797 10.6157 13.8971 10.3179 14.3746 10.1691C14.8522 10.0203 15.3649 10.0285 15.8375 10.1925C16.3101 10.3565 16.7177 10.6675 17.0005 11.0801C17.2808 11.4776 17.4279 11.9538 17.4205 12.4401Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_508_4349">
        <rect width="21.58" height="16.88" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
  export default {}
</script>
