import { Module } from 'vuex'

import { State as RootState } from '@/store/state'

import { initState, getters, mutations, Resources } from './state'
import { actions } from './actions'

import datatables from './datatables'
import form from './form'

const module: Module<Resources, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
  modules: {
    datatables,
    form,
  },
}

export default module
