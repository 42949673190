import gql from 'graphql-tag'

import { DeductibleTypeFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${DeductibleTypeFragment}
  query findDeductibleType($filter: settings_deductible_type_bool_exp, $order: [settings_deductible_type_order_by!], $limit: Int){
    records: settings_deductible_type(where: $filter, order_by: $order, limit: $limit) {
      ...DeductibleType
    }
  }
`

export default query
