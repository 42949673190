import translations from '@/locales'

export function getErrorMessage (errorKey, msg, locale = 'es') {
  const errorMessageTemplate = findValue(translations, errorKey, locale)

  if (!errorMessageTemplate) {
    return null
  }

  const replaceNumber = extractNumbers(msg)
  if (!replaceNumber) return msg

  return errorMessageTemplate.replace(`{n}`, `${replaceNumber}`)
}

function findValue (obj, value, locale = 'es') {
  return deepSearch(obj, value, locale)
}

function deepSearch (obj, value, locale) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const currentValue = obj[key]
      if (currentValue === value) {
        return currentValue
      }

      if (typeof currentValue === 'object' && currentValue !== null) {
        const result = deepSearch(currentValue, value, locale)
        if (result !== null) {
          return result
        }
      }
    }
    if (key === value) {
      return obj[key]
    }
  }

  return null
}

function extractNumbers (text) {
  const regex = /\d+/g
  const numbersFound = text.match(regex)

  if (numbersFound) {
    return numbersFound.map(number => parseInt(number))[0]
  } else {
    return null
  }
}
