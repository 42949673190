import gql from 'graphql-tag'
import { EntityFields } from '..'

export const BaseCarFragment = gql`
  fragment BaseCar on car {
    ${EntityFields}
  }
`

export const CarFragment = gql`
  ${BaseCarFragment}
  fragment Car on car {
    ...BaseCar
  }
`
