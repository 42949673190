import gql from 'graphql-tag'
import { AppraisalResponseTypeFragment } from '@/graphql/fragments/purchase'

export const Fragment = gql`
  ${AppraisalResponseTypeFragment}
  fragment AppraisalResponseTypeForm on purchase_appraisal_response_type {
    ...AppraisalResponseType
  }
`
export default Fragment
