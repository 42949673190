import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const ClosingReasonTypeFragment = gql`
  fragment ClosingReasonType on settings_closing_reason_type{
    ${EntityFields}
    ${EnumFields}
    _description: description
    icon
    color
  }
`
