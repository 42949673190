import { GetterTree } from 'vuex'
import { Location } from 'vue-router/types/router'

import { State as RootState } from '@/store/state'
import { Process } from '@/entities/settings'
import { Schedule } from '@/entities/persons'

export interface Link {
  id: number
  name: string
  slug?: string
  route: Location
  icon: string
  order?: number
}

const STATIC_MENU: Array<Link> = [
  { id: 1, name: 'Inicio', icon: 'mdi-home-outline', route: { name: 'home' } },
]

export interface App {
  drawer?: boolean
  mini?: boolean
  staticMenu: Array<Link>
  isMobile: boolean
  isResize: number
  role: string | null
  process: Process
  system: string
  schedule: Array<Schedule>
}

export function initState (): App {
  return {
    drawer: false,
    mini: true,
    staticMenu: STATIC_MENU,
    isMobile: false,
    isResize: 0,
    role: null,
    process: null,
    system: null,
    schedule: [],
  }
}

export const getters: GetterTree<App, RootState> = {
  drawerOpened: state => {
    return state.drawer
  },
  isMini: state => {
    return state.mini
  },
  getStaticMenu ({ staticMenu }): Array<Link> {
    return staticMenu
  },
  isMobile: state => {
    return state.isMobile
  },
  isResize: state => {
    return state.isResize
  },
  role: state => {
    return state.role
  },
  process: state => {
    return state.process
  },
  system: state => {
    return state.system
  },
  systemSchedule: state => {
    return state.schedule
  },
}
