import gql from 'graphql-tag'

export const mutation = gql`
  mutation insertDailyAccountBalance($fields: finance_daily_account_balance_insert_input!) {
    record: insert_finance_daily_account_balance_one(object: $fields) {
      id
    }
  }
`

export default mutation
