import { Actions } from '../../interfaces'
import { InspectionInspectedComponent } from '@/entities/purchase'

import create from './create'
import find from './find'
import update from './update'
import remove from './remove'

export const actions: Actions = {
  create,
  find,
  update,
  remove,
}

export default {
  Model: { InspectionInspectedComponent },
  actions: { [InspectionInspectedComponent.name]: actions },
}
