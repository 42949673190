import gql from 'graphql-tag'
import { DealTypeFragment } from '@/graphql/fragments/crm'
import { AutoFragment } from '@/graphql/fragments/public'
import { ProcessStatusFragment } from '@/graphql/fragments/settings'

export const getLeadCustom = gql`
  ${DealTypeFragment}
  ${AutoFragment}
  ${ProcessStatusFragment}
  query getLeadCustom($idLead: Int!) {
    lead:get_lead(idLead:$idLead)
  }
`

export const getOpenLead = gql`
  query getOpenLeads($autoId: Float!, $clientId: Float!) {
    openLead:get_open_leads(autoId:$autoId, clientId:$clientId)
  }
`
