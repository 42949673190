import { Actions } from '../../interfaces'
import { ActivityType } from '@/entities/crm'

import find from './find'
import create from './create'
import fetch from './fetch'

export const actions: Actions = {
  find,
  create,
  fetch,
}

export default {
  Model: { ActivityType },
  actions: { [ActivityType.name]: actions },
}
