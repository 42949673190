export interface enterpriseInformation {
  id: number
  personType: string
  companyType: CompanyType
  alias: string
  companyName: string
  rut: string
  birthDate?: null
  contactPeopleByIdPerson?: (null)[] | null
  secondaryPhone?: null
  email?: null
  laborDataByIdPerson?: (null)[] | null
  personAccounts?: (null)[] | null
  personAddresses?: (null)[] | null
}

export interface CompanyType {
  name: string
}

export class EnterpriseFormatted {
  public details: enterpriseInformation;

  constructor (details: enterpriseInformation) {
    this.details = details
  }

  get id (): number | null {
    if (!this?.details?.id) return null
    return this.details.id
  }

  get alias (): string {
    if (!this?.details?.alias) {
      return ''
    }
    return this.details.alias
  }

  get companyName (): string {
    if (!this?.details?.companyName) {
      return ''
    }
    return this.details.companyName
  }

  get rut (): string {
    if (!this.details?.rut) {
      return ''
    }
    return this.details.rut
  }

  get personType (): string {
    if (!this?.details?.personType) {
      return ''
    }
    return this.details.personType
  }
}
