import gql from 'graphql-tag'
import { EntityFields } from '..'
import { BrandFragment } from './brand'

export const BaseModelFragment = gql`
  fragment ModelBase on vehicle_model {
    ${EntityFields}
    name
    segment
    officialWeb:official_web
    priceLink: price_link
  }
`

export const ModelFragment = gql`
  ${BaseModelFragment}
  ${BrandFragment}
  fragment Model on vehicle_model {
    ...ModelBase
    brand {
      ...Brand
    }
  }
`
