import gql from 'graphql-tag'
import { UserFragment } from '@/graphql/fragments/public/user'
import { PersonFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${UserFragment}
  ${PersonFragment}
  query findUser($filter: user_bool_exp!) {
    records: user(where: $filter){
      ...User
      person{
        ...Person
        employee {
          id
        }
      }
    }
  }
`
export default query
