import gql from 'graphql-tag'

export const getPurchasesByDayAggregate = gql`
  query aggregates( $aggregate: purchase_purchase_order_bool_exp) {
    purchase_purchase_order_aggregate(where: $aggregate) {
      aggregate {
        count
      }
      nodes{
        id
        approval_date
      }
    }
  }
`

export const getSalesByDayAggregate = gql`
  query sales( $aggregate: sales_stock_bool_exp) {
    sales_stock_aggregate(where: $aggregate) {
      aggregate {
        count
      }
      nodes{
        sold_date
        deals(where: {closing_reason: {type: {name: {_eq: "successful_sale"}}}}) {
          channel {
            description
            name
          }
          lead {
            executive {
              id
              person{
                id
                first_name
                surname
              }
            }
          }
        }
        stock_detail {
          purchaseExecutive: executive {
            id
            person {
              id
              first_name
              surname
            }
          }
          auto {
            registration_plate
            version_year {
              version {
                model {
                  name
                  brand {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const getLeadsAggregate = gql`
  query leads($model: crm_lead_bool_exp, $order: [crm_lead_order_by!], $limit: Int, $offset: Int) {
    records: crm_lead(where: $model, order_by: $order, limit: $limit, offset: $offset) {
      id
      idForwarder: id_forwarder
      createdAt: created_at
      createdBy: created_by
      channel {
        id
        name
        description
        color
      }
      executive {
        id
        person {
          id
          firstName: first_name
          surname
          user {
            id
            email
          }
        }
      }
      pipeline {
        id
        description
        name
        color
      }
      deals {
        id
        closingReason: closing_reason {
          id
        }
        appraisals {
          id
          inspection {
            id
          }
        }
        channel {
          id
          description
          name
        }
      }
    }
  }
`

export const getLeadByExecutiveAggregate = gql`
  query getLeadByExecutiveAggregate($model: hr_employee_bool_exp, $aggregate: crm_lead_bool_exp = {}) {
    hrEmployee:hr_employee(where: $model) {
      person {
        first_name
        surname
      }
      purchase: executive_leads_aggregate(where: {_and: [$aggregate, {pipeline: {name: {_eq: "purchase"}}}]}) {
        aggregate {
          count
        }
      }
      sale: executive_leads_aggregate(where: {_and: [$aggregate, {pipeline: {name: {_eq: "sale"}}}]}) {
        aggregate {
          count
        }
      }
      financed_sale: executive_leads_aggregate(where: {_and: [$aggregate, {pipeline: {name: {_eq: "financed_sale"}}}]}) {
        aggregate {
          count
        }
      }
      renewal: executive_leads_aggregate(where: {_and: [$aggregate, {pipeline: {name: {_eq: "renewal"}}}]}) {
        aggregate {
          count
        }
      }
      financed_renovation: executive_leads_aggregate(where: {_and: [$aggregate, {pipeline: {name: {_eq: "financed_renovation"}}}]}) {
        aggregate {
          count
        }
      }
    }
  }
`
