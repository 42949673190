import gql from 'graphql-tag'
import { FileTypeFragment } from '@/graphql/fragments/files'

export const query = gql`
  ${FileTypeFragment}
  query findFileType($filter: files_file_type_bool_exp) {
    records: files_file_type(where: $filter) {
      ...FileType
    }
  }
`
export default query
