import gql from 'graphql-tag'
import { EntityFields } from '..'
import { AgreementFragment, PersonFragment } from '.'

export const BaseResponsibleAgreementFragment = gql`
  fragment BaseResponsibleAgreement on persons_responsible_agreement{
    ${EntityFields}
  }
`

export const ResponsibleAgreementFragment = gql`
  ${BaseResponsibleAgreementFragment}
  ${AgreementFragment}
  ${PersonFragment}
  fragment ResponsibleAgreement on persons_responsible_agreement{
    ...BaseResponsibleAgreement
    agreement {
      ...Agreement
    }
    responsible {
      ...Person
    }
  }
`
