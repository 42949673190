import gql from 'graphql-tag'
import { EntityFields } from '..'

export const RolFragment = gql`
  fragment Rol on rol {
    ${EntityFields}
    name
    description
    slug
    icon
  }
`
