import gql from 'graphql-tag'

import { FileTypeFragment } from '@/graphql/fragments/files'

export const query = gql`
  ${FileTypeFragment}
  query fetchFileType($id: Int!) {
    records: files_file_type_by_pk(id: $id) {
      ...FileType
    }
  }
`

export default query
