// @ts-ignoreminimum_case
const translations = {
  $vuetify: {
    dataTable: {
      itemsPerPageText: 'Filas por página:',
      ariaLabel: {
        sortDescending: 'Orden descendente.',
        sortAscending: 'Orden ascendente.',
        sortNone: 'Sin ordenar.',
        activateNone: 'Pulse para quitar orden.',
        activateDescending: 'Pulse para ordenar descendente.',
        activateAscending: 'Pulse para ordenar ascendente.',
      },
      sortBy: 'Ordenado por',
    },
    dataFooter: {
      itemsPerPageText: 'Filas por página:',
      itemsPerPageAll: 'Todos',
      nextPage: 'Página siguiente',
      prevPage: 'Página anterior',
      firstPage: 'Primer página',
      lastPage: 'Última página',
      pageText: '{0}-{1} de {2}',
    },
    pagination: {
      ariaLabel: {
        previous: 'Anterior',
        next: 'Siguiente',
        wrapper: 'Envoltorio',
        currentPage: 'Pagina Actual',
        page: 'Pagina',
      },
    },
  },
  // General
  search_resource: 'Buscar Recurso',
  search_ppu: 'Buscar Patente',
  setting: 'Configuración',
  settings: 'Configuraciones',
  user_not_found: 'Usuario no se consigue debe registrarse',
  user_many_request:
    'Demasiados intentos de inicio de sesión fallidos. Inténtalo de nuevo más tarde.',
  welcome_message: 'Bienvenido a GENIO',
  email: 'Correo Electrónico',
  sign_google: 'Ingresar con google',
  sign_up: 'Registrarse',
  sign_in: 'Ingresar',
  sign_up_button: 'Regístrate aquí',
  sign_in_button: 'Ingresa aquí',
  sign_in_text: '¿Ya tienes una cuenta?',
  sign_up_text: '¿No tienes una cuenta?',
  forgot_user: '¿Olvidaste tu contraseña?',
  confirm_password: 'Confirmar Contraseña',
  start_session: 'Iniciar Sesión',
  cancel: 'Cancelar',
  send: 'Enviar',
  recover_password: 'Recuperar Contraseña',
  remember_session: '¿Recordar la sesión?',
  write_mail_forgot_password:
    'Escriba el correo electrónico con el que se registró en GENIO',
  password: 'Contraseña',
  lower_case: 'Caracter en Minuscula',
  upper_case: 'Caracter en Mayuscula',
  number_case: 'Caracter Numerico',
  special_case: 'Caracter Especial',
  minimum_case: 'Minimo 8 Caracteres',
  white_space_case: 'Sin Espacios en Blanco',
  email_required: 'E-mail es requerido',
  email_valid: 'Debe ser un E-mail valido',
  dashboard: 'Dashboard',
  vehicles: 'Vehiculos',
  is_required: 'Campo Requerido',
  edit: 'Editar',
  characteristics: 'Características',
  icon: 'Icono',
  icon_name: 'Nombre del Icono',
  group: 'Grupo',
  select_group: 'Selecciona un grupo',
  group_description: 'Descripción del Grupo',
  metadata: 'Metadata',
  position: 'Posición',
  new_version_available: '¡Nueva versión disponible!',
  refresh_to_update: 'Haga Click en Actualizar por obtener la ultima version',
  refresh: 'Actualizar',
  search: 'Buscar...',
  brand: 'Marca',
  model: 'Modelo',
  version: 'Version',
  year: 'Año',
  // Menu
  stock: 'Stock',

  // User
  name: 'Nombre',
  user_profile: 'Perfil De Usuario',
  user_roles: 'Roles de Usuario',
  user_name: 'Nombre de Usuario',
  user_first_name: 'Primer Nombre',
  user_last_name: 'Segundo Nombre',
  user_address: 'Dirección',
  user_postal_code: 'Codigo Postal',
  user_about_me: 'Sobre Mi',
  user_country: 'Pais',
  user_city: 'Ciudad',
  user_phone_number: 'Telefono',
  role: 'Rol',
  area: 'Area',
  availability: 'Disponibilidad',
  status: 'Estado',
  updating_person: 'Actualizando Perfil de ',
  select_role: 'Selecciona rol',
  select_area: 'Selecciona area',
  select_availability: 'Selecciona disponibilidad',
  select_status: 'Selecciona estatus',
  others: 'Otros',
  more: 'Más',
  // Roles
  admin: 'Administrador',
  roles: 'Roles',
  description: 'Descripción',
  slug: 'Identificador',
  group_name: 'Nombre del Grupo',
  // Resources
  resources: 'Recursos',
  password_invalid:
    'La contraseña es invalida o el usuario no tiene contraseña',
  network_error: 'Error de conexion, verifica tu conexion a internet',
  // Vehicle
  inside: 'Interior',
  general: 'General',
  equipment: 'Equipamiento',
  exterior: 'Exterior',
  watch_publication: 'Ver Publicación',
  price: 'Precio',
  publication_status: 'Estatus de la publicación',
  transmissions: 'Transmision',
  tractions: 'Traccion',
  fuels: 'Combustible',
  color: 'Color',
  'airbag type': 'Tipo de Bolsa de Aire',
  seats: 'Asientos',
  'comfort equipment': 'Equipo de Comfort',
  bodyWorks: 'Carroceria',
  ppu: 'Patente',
  mileage: 'Kilometraje',
  places: 'Nº de Plazas',
  doors: 'Nº de Puertas',
  keyType: 'Tipo de Llave',
  airbags: 'Tipo de Airbag',
  nVelocity: 'Nº de Velocidades',
  rim: 'Nº Rin',
  cylinder: 'Cilindrada',
  securityEquipment: 'Equipo de Seguridad',
  comfortEquipment: 'Equipo de Comfort',
  publish: 'Publicar',
  unpublish: 'Despublicar',
  DRAFT: 'Borrador',
  PUBLIC: 'Publicado',

  // config resources
  select_resources: 'Selecciona Recursos',
  select_resources_group: 'Selecciona Grupo',
  select_position: 'Selecciona una posición',
  menu_position: 'Posición del menu',
}

export default translations
