import gql from 'graphql-tag'
import { MileagePriceFragment } from '@/graphql/fragments/vehicle/mileagePrice'

export const query = gql`
  ${MileagePriceFragment}
  query findMileagePrice($filter: vehicle_mileage_price_bool_exp, $order: [vehicle_mileage_price_order_by!]) {
    records: vehicle_mileage_price(where: $filter, order_by: $order) {
      ...MileagePrice
    }
  }

`
export default query
