import gql from 'graphql-tag'

export const findPpu = gql`
  query findPpu($ppu: String!) {
    auto:find_version_year_by_ppu(ppu: $ppu) {
      year
      versions {
        id
        name
      }
      models{
        id
        name
      }
      brand {
        id
        name
      }
    }
  }
`
