import { Type } from 'class-transformer'
import { Entity } from '..'

import { Service } from './Service'
import { AgreementProduct } from '../settings'

export class Product extends Entity {
  @Type(() => AgreementProduct)
  agreements: AgreementProduct[];

  @Type(() => Service)
  serviceType: Service;

  name: string;

  description: string;
}
