import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ClosingReasonFragment, ProcessStatusFragment } from '../settings'

export const BaseReserveFragment = gql`
  fragment BaseReserve on sales_reserve{
    ${EntityFields}
    expiration
    comment
  }
`

export const ReserveFragment = gql`
  ${BaseReserveFragment}
  ${ClosingReasonFragment}
  ${ProcessStatusFragment}
  fragment Reserve on sales_reserve{
    ...BaseReserve
    closingReason:closing_reason {
      ...ClosingReason
    }
    status{
      ...ProcessStatus
    }
  }
`
