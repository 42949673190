import gql from 'graphql-tag'
import { FinancingFragment } from '@/graphql/fragments/loans'

export const mutation = gql`
  ${FinancingFragment}
  mutation updateFinancing($id: Int!, $fields: loans_financing_set_input!) {
    record: update_loans_financing_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...Financing
    }
  }
`

export default mutation
