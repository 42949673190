import gql from 'graphql-tag'
import { FileFragment } from '@/graphql/fragments/files'

export const mutation = gql`
  ${FileFragment}
  mutation updateFile($id: Int!, $fields: files_file_set_input!) {
    record: update_files_file_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...File
    }
  }
`

export default mutation
