import gql from 'graphql-tag'
import { EntityFields } from '..'

export const BrandFragment = gql`
  fragment Brand on vehicle_brand {
    ${EntityFields}
    name
    officialWeb:official_web
    gama
  }
`
