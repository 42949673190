import { MutationTree } from 'vuex'
import { App } from '@/store/app/state'

export const mutations: MutationTree<App> = {
  setDrawer: (state, payload) => {
    state.drawer = payload
  },
  setMenu: (state, payload) => {
    state.staticMenu.push(payload)
  },
  setMini: (state, payload) => {
    state.mini = payload
  },
  setIsMobile: (state, payload) => {
    state.isMobile = payload
  },
  setIsResize: (state, payload) => {
    state.isResize = payload
  },
  setRole: (state, payload) => {
    state.role = payload
  },
  setProcess: (state, payload) => {
    state.process = payload
  },
  setSystem: (state, payload) => {
    state.system = payload
  },
  setSchedule: (state, payload) => {
    state.schedule = payload
  },
}
