import gql from 'graphql-tag'
import { ResponsibleAgreementFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${ResponsibleAgreementFragment}
  query findAgreement($filter: persons_responsible_agreement_bool_exp, $limit: Int) {
    records: persons_responsible_agreement(where: $filter,  limit: $limit) {
      ...ResponsibleAgreement
    }
  }
`
export default query
