import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ItemTypeFragment } from '.'

export const BaseSaleOrderItemFragment = gql`
  fragment BaseSaleOrderItem on sales_sale_order_item {
    ${EntityFields}
    quantity
    amount
    tax
  }
`

export const SaleOrderItemFragment = gql`
  ${BaseSaleOrderItemFragment}
  ${ItemTypeFragment}
  fragment SaleOrderItem on sales_sale_order_item {
    ...BaseSaleOrderItem
    type: item_type {
      ...ItemType
    }
  }
`
