import { Transform, Type } from 'class-transformer'
import dayjs, { Dayjs } from 'dayjs'
import { Entity } from '..'

import { Bonus } from './Bonus'
import { Stock } from './Stock'

export class Price extends Entity {
  @Type(() => Bonus)
  bonuses: Bonus[];

  @Type(() => Stock)
  stock: Stock;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  expiration?: Dayjs

  amount: number;
  margin?: number;
  comment?: string;
  postComment?: string;

  get bonus (): Bonus {
    const { bonuses } = this
    return bonuses && bonuses[0]
  }

  get isLastPrice () {
    const { expiration } = this

    return !expiration
  }
}
