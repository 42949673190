import gql from 'graphql-tag'
import { EntityFields } from '..'
import { RegionFragment } from '@/graphql/fragments/public/region'

export const BaseCityFragment = gql`
  fragment BaseCity on city {
    ${EntityFields}
    name
  }
`

export const CityFragment = gql`
  ${BaseCityFragment}
  ${RegionFragment}
  fragment City on city {
    ...BaseCity
    region {
      ...Region
    }
  }
`
