import { Module } from 'vuex'
import { getters, initState, Dashboard } from '@/store/dashboards/state'
import { State as RootState } from '@/store/state'
import { mutations } from '@/store/dashboards/mutations'
import { actions } from '@/store/dashboards/actions'

const module: Module<Dashboard, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
}

export default module
