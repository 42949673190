import gql from 'graphql-tag'

export const createLaborData = gql`
  mutation createLaborData($data: CreateLaborDataInput!) {
    createLaborData(createLaborDataInput: $data) {
      laborData {
        id
        rent
        jobTitle
        admissionDate
        jobKey
        employer {
          id
          uid
          companyName
          alias
          firstName
          secondName
          surname
          secondSurname
          personType {
            name
          }
        }
        person {
          id
          firstName
          surname
        }
        activityType {
          name
          description
        }
        contractType {
          name
          description
        }
        rentType {
          name
          description
        }
        active
      }
      exist
    }
  }`

export const personLaborUpdateStatus = gql`
  mutation personLaborUpdateStatus($id: Int!) {
    update_persons_labor_data(where: {id: {_eq: $id}}, _set: {active: false}) {
      affected_rows
    }
  }
`
export const updateLaborData = gql`
  mutation updateLaborData($data: UpdateLaborDataInput!) {
    updateLaborData(updateLaborDataInput: $data) {
      id
    }
  }
`
