import gql from 'graphql-tag'
import { EntityFields } from '..'
import { AutoFragment } from '../public/auto'
import { ProcessStatusFragment } from '../settings/processStatus'
import { DealTypeFragment } from './dealType'
import { ChannelFragment } from '../crm/channel'
import { ClosingReasonFragment } from '../settings/closingReason'
import { IntentionFragment } from './intention'
import { PersonFragment } from '@/graphql/fragments/persons'
import { OwnershipFragment } from '@/graphql/fragments/public/ownership'

export const BaseDealFragment = gql`
  fragment DealBase on crm_deal {
    ${EntityFields}
    negotiationAmount: negotiation_amount
  }
`

export const DealFragment = gql`
  ${BaseDealFragment}
  ${DealTypeFragment}
  ${ChannelFragment}
  ${AutoFragment}
  ${ClosingReasonFragment}
  ${ProcessStatusFragment}
  ${IntentionFragment}
  ${PersonFragment}
  ${OwnershipFragment}
  fragment Deal on crm_deal {
    ...DealBase
    type: deal_type {
      ...DealType
    }
    channel {
      ...Channel
    }
    referrer {
      ...Person
    }
    auto {
      ...Auto
      deals {
        id
        appraisalsAggregate: appraisals_aggregate{
          aggregate {
            count
          }
        }
      }
    }
    status {
      ...ProcessStatus
    }
    closingReason: closing_reason {
      ...ClosingReason
    }
    intention {
      ...Intention
    }
    ownership {
      ...Ownership
    }
  }
`
