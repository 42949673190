import gql from 'graphql-tag'
import { Fragment } from './fragment'

export const query = gql`
  ${Fragment}
  query findFinancing($filter: loans_financing_bool_exp!,$order: [loans_financing_order_by!], $limit: Int){
    records: loans_financing(where: $filter, limit: $limit, order_by: $order) {
      ...FinancingFragment
    }
  }
`

export default query
