import { Type } from 'class-transformer'
import { EnumEntity } from '..'

import { BankData } from '.'

export class AccountType extends EnumEntity {
  @Type(() => BankData)
  bankData: BankData[];

  get shortName () {
    const { name } = this

    if (name === 'saving_account') {
      return 'Cta. ahorro'
    }

    if (name === 'view_account') {
      return 'Cta. vista'
    }

    return 'Cta. cte'
  }
}
