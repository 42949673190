import { RouteConfig } from 'vue-router'

const extractProps = ({ params: { uid }, query: { returnTo } }) => ({
  uid,
  returnTo,
})

const routes: Array<RouteConfig> = [
  {
    path: '/supervisor_stock',
    name: 'supervisor-stock',
    redirect: { name: 'stock_details_supervisor' },
    component: () => import('@/layouts/vehicles/Index.vue'),
    meta: { requiresLogin: true, public: true },
    children: [
      {
        path: ':uid',
        name: 'stock_details_supervisor',
        meta: { inherit: true },
        props: extractProps,
        component: () => import('./StockDetails.vue'),
      },
    ],
  },
  {
    path: '/stock',
    name: 'stock',
    redirect: { name: 'stock_details' },
    component: () => import('@/layouts/vehicles/Index.vue'),
    meta: { requiresLogin: true, public: true },
    children: [
      {
        path: ':uid',
        name: 'stock_details',
        meta: { inherit: true },
        props: extractProps,
        component: () => import('./StockDetails.vue'),
      },
    ],
  },
]

export default routes
