import gql from 'graphql-tag'

import { StockFragment, StockViewDetailFragment } from '@/graphql/fragments/sales'
import {
  BaseAppraisalFragment,
  BaseEnablementFragment,
  BaseInspectionFragment,
  ResponseFragment,
} from '@/graphql/fragments/purchase'
import { BaseDealFragment } from '@/graphql/fragments/crm'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import { AttributeFragment, BaseAutoMotiveRegistrationFragment, VersionYearFragment } from '@/graphql/fragments/vehicle'
import { MileagePriceFragment } from '@/graphql/fragments/vehicle/mileagePrice'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'

export const query = gql`
  ${StockFragment}
  ${BaseEnablementFragment}
  ${BaseInspectionFragment}
  ${BaseAppraisalFragment}
  ${BaseDealFragment}
  ${BaseAutoFragment}
  ${VersionYearFragment}
  ${BaseAutoMotiveRegistrationFragment}
  ${MileagePriceFragment}
  ${ResponseFragment}
  ${AttributeFragment}
  ${DealAutoAttributeFragment}
  ${StockViewDetailFragment}
  query fetchStock($id: Int!) {
    records: sales_stock_by_pk(id: $id) {
      ...Stock
      viewDetails: stock_detail {
        ...StockViewDetail
      }
    }
  }
`

export default query
