import gql from 'graphql-tag'
import { InspectionComponentFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${InspectionComponentFragment}
  query findInspectionComponent($filter: purchase_inspection_component_bool_exp){
    records: purchase_inspection_component(where: $filter) {
      id
      config
      component{
        id
        name
        slug
      }
      inspectionParameters:inspection_parameters{
        id
        name
        order
      }
    }
  }
`

export default query
