import { VueApolloClient } from '@/plugins/apollo'
import { DocumentNode } from 'graphql'
import { plainToInstance } from 'class-transformer'

import { Aggregate, AggregateType, Filter, Model } from '@/entities/public/Resource/interfaces'
import { AggregatePath } from '@/entities/interfaces'
import { Period } from '@/store/resources/period'
import { Aggregates } from '@/entities/aggregate'

import { Channel, Pipeline } from '@/entities/crm'
import pipeline from './pipeline'

import { Employee } from '@/entities/hr'
import employee from './employee'

import { ProcessStatus, ClosingReason } from '@/entities/settings'
import closingReason from './closingReason'
import processStatus from './processStatus'
import channel from './channel'
import { Stock } from '@/entities/sales'
import stock from './stock'
import { transformInterval } from '@/graphql/queries/resources/datatables/utils'

const Models: Record<string, Model> = {
  Pipeline,
  Employee,
  ProcessStatus,
  ClosingReason,
  Channel,
}
const Defined: Record<string, Record<string, {
  structure: Array<AggregatePath>
  query: DocumentNode
  global?: boolean
}>> = {
  [Pipeline.name]: pipeline,
  [Employee.name]: employee,
  [ProcessStatus.name]: processStatus,
  [ClosingReason.name]: closingReason,
  [Stock.name]: stock,
  [Channel.name]: channel,
}

export async function fetchAggregates (
  client: VueApolloClient,
  aggregate: Aggregate,
  period?: Period,
  filter?: Filter
): Promise<Array<AggregateType>> {
  const { model, name, where: find, order, params, filters = {} } = aggregate
  const where = transformInterval(find)
  const queries = Defined[model]
  if (!queries) throw new Error(`No aggregates defined for model: ${model}`)

  const target = queries[name]
  if (!target) throw new Error(`The query: ${name} is not part of the ${model} aggregates`)

  const { query, structure, global } = target
  const active = filters[filter?.name]
  const variables = {
    ...where,
    ...active?.where,
    order,
    ...params,
  }
  variables.aggregate = { _and: [period?.where, variables.aggregate].filter(_ => _) }

  const {
    data,
  } = await client.query({ query, fetchPolicy: 'cache-first', variables })
  const { aggregates } = data

  const Model = Models[model]
  return aggregates.map(record => {
    const entity = plainToInstance(Model, record)

    const struct = global ? structure.filter(({ name }) => name === record.name) : structure
    if (!struct?.length) throw new Error(`Incorrect structure definition for "${name}" query in "${model}" Model`)

    entity.setAggregates(new Aggregates(struct, global ? data : record))
    return entity
  })
}
