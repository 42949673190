import { State as RootState } from '@/store/state'
import { ActionTree } from 'vuex'
import { App } from '@/store/app/state'

export const actions: ActionTree<App, RootState> = {
  setMini: ({ commit }, payload): void => {
    commit('setMini', payload)
  },
  setDrawerImage: ({ commit }, payload): void => {
    commit('setDrawerImage', payload)
  },
  toggleDrawer: ({ commit, state: { drawer } }, state): void => {
    if (state !== undefined && drawer === state) return

    commit('setDrawer', state !== undefined ? state : !drawer)
  },
  setImage: ({ commit }, payload): void => {
    commit('setImage', payload)
  },
  setGradient: ({ commit }, payload): void => {
    commit('setGradient', payload)
  },
  setMobile: ({ commit }, payload): void => {
    commit('setIsMobile', payload)
  },
  setIsResize: ({ commit }, payload): void => {
    commit('setIsResize', payload)
  },
  setRole: ({ commit }, payload): void => {
    commit('setRole', payload)
  },
}
