<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0208 18.75C12.8146 15.1042 15.75 12.6125 18.75 12.6125C19.8854 12.6125 20.9583 13.0105 21.875 13.5417V12.5L19.7083 6.25004C19.5 5.64587 18.9167 5.20837 18.2292 5.20837H6.77083C6.08333 5.20837 5.5 5.64587 5.29167 6.25004L3.125 12.5V20.8334C3.125 21.4063 3.59375 21.875 4.16667 21.875H5.20833C5.78125 21.875 6.25 21.4063 6.25 20.8334V19.7917L13.1146 19.7917C13.0625 19.448 13.0208 19.1042 13.0208 18.75ZM6.77083 6.77087H18.2292L19.7917 11.4584H5.20833L6.77083 6.77087ZM6.77083 16.6667C5.90625 16.6667 5.20833 15.9688 5.20833 15.1042C5.20833 14.2396 5.90625 13.5417 6.77083 13.5417C7.63542 13.5417 8.33333 14.2396 8.33333 15.1042C8.33333 15.9688 7.63542 16.6667 6.77083 16.6667Z"
    />
    <path
      d="M18.45 13.8C16.2225 13.8 14.4 15.6225 14.4 17.85C14.4 20.0775 16.2225 21.9 18.45 21.9C20.6775 21.9 22.5 20.0775 22.5 17.85C22.5 15.6225 20.6775 13.8 18.45 13.8ZM20.1915 19.146L18.045 17.9715V15.825H18.6525V17.607L20.475 18.6195L20.1915 19.146Z"
    />
  </svg>
</template>

<script>
  export default {}
</script>
