import country from './countries'
import city from './city'
import auto from './auto'
import user from './user'
import dealAutoAttribute from './dealAutoAttribute'
import maintenanceType from './maintenanceType'
import maintenance from './maintenance'
import maintenanceBackupType from './maintenanceBackupType'
import alias from './alias'
import platform from './platform'
import trafficTicket from './trafficTicket'
import attribute from './attributeView'
import rol from './rol'
import roles from './roles'
import car from './car'

export const Models = {
  ...auto.Model,
  ...country.Model,
  ...user.Model,
  ...dealAutoAttribute.Model,
  ...maintenanceType.Model,
  ...maintenance.Model,
  ...maintenanceBackupType.Model,
  ...city.Model,
  ...alias.Model,
  ...platform.Model,
  ...trafficTicket.Model,
  ...attribute.Model,
  ...rol.Model,
  ...roles.Model,
  ...car.Model,
}

export const actions = {
  ...auto.actions,
  ...country.actions,
  ...user.actions,
  ...dealAutoAttribute.actions,
  ...maintenanceType.actions,
  ...maintenance.actions,
  ...maintenanceBackupType.actions,
  ...city.actions,
  ...alias.actions,
  ...platform.actions,
  ...trafficTicket.actions,
  ...attribute.actions,
  ...rol.actions,
  ...roles.actions,
  ...car.actions,
}

export const schema = { Models, actions }
export default schema
