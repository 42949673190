import { Actions } from '../interfaces'
import employee from './employee'

export const Models = {
  ...employee.Model,
}

export const actions: Record<string, Actions> = {
  ...employee.actions,
}

export const schema = { Models, actions }
export default schema
