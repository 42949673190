import { Type } from 'class-transformer'
import { Entity } from '..'
import { Auto, DealAutoAttribute } from '@/entities/public'
import { EnablementMetadata } from '@/entities/purchase'

export class StockViewDetails extends Entity {
  @Type(() => Auto)
  auto: Auto;

  @Type(() => EnablementMetadata)
  enablementMetadata: EnablementMetadata;

  @Type(() => DealAutoAttribute)
  attributes: DealAutoAttribute[];

  expectedPublicationAmount: number | null;
  appraisalAmount: number | null;
  enablementCost: number | null;
  purchaseAmount: number | null;
  appraisalLink: string | null
  idDeal: number;
  idAuto: number;
  idEnablement: number;
  idNegotiation: number;

  get totalCost () {
    const { enablementMetadata } = this
    return enablementMetadata?.totalCost || 0
  }
}
