const common = {
  setting: 'Configuración',
  settings: 'Configuraciones',
  welcome_message: 'Bienvenido a GENIO',
  dashboard: 'Dashboard',
  cancel: 'Cancelar',
  send: 'Enviar',
  search: 'Buscar...',
  edit: 'Editar',
  more: 'Más',
  others: 'Otros',
  user_not_found: 'Usuario no se consigue debe registrarse',
  // Password rules
  lower_case: 'Caracter en Minuscula',
  upper_case: 'Caracter en Mayuscula',
  number_case: 'Caracter Numerico',
  special_case: 'Caracter Especial',
  minimum_case: 'Minimo 8 Caracteres',
  white_space_case: 'Sin Espacios en Blanco',
  is_required: 'Campo Requerido',
}

export default common
