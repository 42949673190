import gql from 'graphql-tag'
import { ActivityTypeFragment } from '@/graphql/fragments/crm'

export const mutation = gql`
  ${ActivityTypeFragment}
  mutation insertActivityType($fields: crm_activity_type_insert_input!) {
    record: insert_crm_activity_type_one(object: $fields) {
      ...ActivityType
    }
  }
`

export default mutation
