import gql from 'graphql-tag'

export const query = gql`
  query findInspectionView($filter: purchase_inspection_detail_view_bool_exp){
    records: purchase_inspection_detail_view(where: $filter) {
      id
      idInspectedComponent:id_inspected_component
    }
  }
`

export default query
