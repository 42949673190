<template>
  <svg width="60" height="25" viewBox="0 0 60 25" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2506 14.4757C12.3277 14.4757 14.4059 14.4757 16.4853 14.4757C17.5212 14.4757 17.8021 14.6899 17.8803 15.4572C18.0058 16.691 17.4441 17.8475 17.1391 19.0261C16.6937 20.7561 16.1032 22.4641 15.5692 24.1814C15.4403 24.5986 14.7094 24.8043 13.8427 24.6386C13.166 24.5086 13.6448 24.1653 13.6989 23.969C14.2743 21.905 14.7451 19.8248 15.469 17.7838C15.7602 16.9621 15.4265 16.6579 14.2951 16.6536C13.5711 16.6536 12.8471 16.6536 12.122 16.6536C11.4913 16.6536 11.1242 16.8907 10.9837 17.3334C10.6085 18.5052 10.2149 19.6744 9.87421 20.8522C9.55309 21.9568 9.29067 23.0785 9.00293 24.1916C8.77273 25.0974 7.84045 24.5698 7.25116 24.6054C6.48346 24.6513 6.98298 24.1857 7.03017 24.0106C7.59069 21.9543 8.20876 19.9073 8.78539 17.8534C9.08234 16.7947 8.87056 16.6239 7.41114 16.6281C7.0014 16.6281 6.59204 16.6281 6.18306 16.6281C4.70292 16.6239 4.47963 16.7938 4.13549 17.8985C3.49095 19.9727 3.08696 22.0843 2.29395 24.1355C2.14087 24.5358 0.797695 24.8748 0.389103 24.5952C0.199193 24.4644 0.343064 24.2893 0.366083 24.1364C0.547936 22.9587 1.08083 21.8362 1.35706 20.6695C1.81745 18.71 2.46084 16.7734 2.98568 14.8207C3.08581 14.446 3.36895 14.4732 3.7223 14.4732C5.90146 14.4772 8.07755 14.478 10.2506 14.4757Z" />
    <path d="M23.7052 14.5079C24.649 14.5079 25.595 14.5351 26.5365 14.4995C27.3054 14.4706 27.6242 14.7297 27.6979 15.2574C27.8452 16.3145 27.5528 17.3367 27.2513 18.3556C26.7218 20.14 26.1406 21.9244 25.6399 23.7174C25.4535 24.3904 25.0035 24.6606 24.0896 24.6461C22.3631 24.6198 20.6252 24.5884 18.8976 24.6461C17.4623 24.6963 17.0284 24.0207 17.2114 23.1786C17.5567 21.5794 18.0965 20.0023 18.5673 18.4201C18.8573 17.4463 19.0368 16.4581 19.3936 15.4919C19.6411 14.8257 20.0025 14.4213 21.0579 14.4918C21.9315 14.5504 22.8189 14.5037 23.7052 14.5037V14.5079ZM21.7957 22.4419C22.1408 22.4288 22.4867 22.4305 22.8316 22.447C23.696 22.5227 24.1414 22.1734 24.3014 21.6219C24.7019 20.2394 25.084 18.8526 25.4097 17.4582C25.5248 16.9484 25.2095 16.6187 24.3946 16.6518C23.6419 16.6816 22.8845 16.6722 22.1306 16.6518C21.4999 16.6374 21.15 16.8405 20.9647 17.2832C20.4721 18.4592 20.1533 19.6624 19.8598 20.8708C19.4535 22.56 19.6181 22.4479 21.7957 22.4419Z" />
    <path d="M44.2453 14.4973C45.2224 14.4973 46.2019 14.5075 47.1745 14.4931C47.9318 14.4812 48.3024 14.793 48.3254 15.291C48.3657 16.0557 48.3254 16.806 48.0181 17.5606C47.6498 18.469 47.4426 19.4122 47.1722 20.3426C46.8695 21.4082 46.5783 22.4763 46.2882 23.5444C46.05 24.418 45.7127 24.6338 44.4939 24.6364C42.7935 24.6403 41.0931 24.6403 39.3928 24.6364C38.1705 24.6364 37.7814 24.3321 37.7699 23.4008C37.7515 22.2206 38.3937 21.1337 38.6527 19.99C38.9727 18.5752 39.4733 17.1859 39.8808 15.7779C40.2261 14.5976 40.3907 14.488 41.9744 14.4939H43.1081L44.2453 14.4973ZM42.3036 22.4185C42.8388 22.4185 43.3751 22.4279 43.9092 22.4185C44.1016 22.4236 44.2898 22.3764 44.4383 22.2859C44.5867 22.1954 44.6851 22.0678 44.7148 21.9274C45.1476 20.4548 45.5988 18.9839 45.9959 17.5088C46.1478 16.942 45.8071 16.6259 44.9531 16.659C44.3258 16.6871 43.6939 16.659 43.0644 16.659C41.7822 16.6658 41.6372 16.8035 41.293 17.7416C40.9385 18.7078 40.8326 19.7087 40.4874 20.6715C39.8958 22.3795 39.9326 22.45 42.3036 22.4185Z" />
    <path d="M25.2774 10.6089C24.3336 10.6089 23.3875 10.586 22.4449 10.614C21.5022 10.642 21.104 10.2801 21.0637 9.65975C21.0166 9.21967 21.0437 8.77697 21.1443 8.34181C21.8464 5.77221 22.5968 3.21026 23.3023 0.641513C23.4359 0.141869 23.8962 0.26678 24.3129 0.26678C25.8091 0.259132 25.8091 0.261682 25.488 1.35614C24.9206 3.28079 24.3371 5.20374 23.7938 7.13434C23.5176 8.12088 23.6557 8.2118 25.0276 8.21265C25.4673 8.21265 25.9081 8.21265 26.3489 8.21265C27.6611 8.21265 27.8141 8.12768 28.0984 7.15473C28.7215 5.0236 29.3395 2.8919 29.9526 0.759627C30.2266 -0.204823 31.2325 0.342407 31.8747 0.292272C32.6206 0.23364 32.22 0.724787 32.1556 0.954216C31.4086 3.7286 30.6685 6.50469 29.8433 9.26717C29.5187 10.3557 29.3138 10.6879 27.5413 10.6149C26.7909 10.5834 26.0324 10.6098 25.2774 10.6089Z" />
    <path d="M18.9265 0.257975C19.8392 0.257975 20.7507 0.269872 21.6623 0.257975C22.1998 0.248628 22.3529 0.384586 22.1457 0.758469C22.0564 0.956181 21.9921 1.15957 21.9535 1.36603C21.5207 2.98053 21.889 2.66273 19.8208 2.70011C19.2246 2.71031 18.6249 2.71796 18.0287 2.69502C17.5683 2.67717 17.2553 2.76214 17.0941 3.11563C16.7258 3.92033 16.8075 4.01465 17.9689 4.0155C18.8183 4.0155 19.6677 4.0325 20.5159 4.01126C21.0109 3.99851 21.2249 4.09623 21.0914 4.48626C20.9096 5.02499 20.7461 5.56712 20.5942 6.11095C20.5079 6.41006 20.2305 6.44065 19.8864 6.4381C18.9115 6.43045 17.9355 6.45764 16.9629 6.42875C16.3989 6.41261 16.1572 6.53667 15.9973 6.96493C15.614 7.97187 15.5104 9.01704 15.1191 10.024C14.9292 10.5134 14.6829 10.6859 14.0372 10.6103C13.7868 10.5882 13.5337 10.5882 13.2833 10.6103C12.6652 10.6477 12.6031 10.5253 12.7527 10.058C13.3673 8.14182 13.868 6.20527 14.4262 4.27977C14.7795 3.05615 15.1709 1.83848 15.5242 0.615714C15.6094 0.320006 15.8246 0.248628 16.1906 0.253727C17.1056 0.265623 18.0149 0.257126 18.9265 0.257975Z" />
    <path d="M47.6265 24.6346C48.0028 22.5323 48.8016 20.5983 49.2896 18.615C49.5198 17.6591 49.75 16.6963 50.0953 15.7599C50.3681 15.0249 50.8457 14.4055 52.3731 14.4581C54.3827 14.5287 56.4015 14.5074 58.4133 14.4641C59.1419 14.4488 59.2041 14.634 59.0418 15.0589C58.9037 15.4158 58.7184 15.7752 58.6896 16.1389C58.6504 16.6301 58.3132 16.6734 57.7688 16.6649C56.2587 16.6411 54.7464 16.6878 53.2374 16.6479C52.2488 16.6216 51.7815 17.0056 51.5904 17.626C50.9574 19.6832 50.3025 21.7378 49.7765 23.8103C49.6107 24.4587 49.3598 24.7544 48.4494 24.6405C48.1732 24.6057 47.8877 24.6346 47.6265 24.6346Z" />
    <path d="M44.8448 10.6048C43.7111 10.6048 42.5786 10.5963 41.4449 10.6048C40.9649 10.6099 40.7681 10.5317 40.8913 10.1213C41.461 8.2204 41.9962 6.31445 42.5314 4.40764C42.8698 3.20357 43.1806 1.99524 43.5143 0.79117C43.573 0.578736 43.5293 0.270282 43.9747 0.268582C44.5939 0.268582 45.2753 0.107983 45.8163 0.346758C46.1385 0.488664 45.8588 0.873594 45.7886 1.13106C45.2132 3.22226 44.6377 5.31176 44.0012 7.39276C43.8067 8.03091 43.9621 8.26459 44.9093 8.2204C46.1638 8.16177 47.4265 8.21361 48.6856 8.19916C49.2807 8.19236 49.6939 8.19406 49.3474 8.79397C49.131 9.16871 49.0792 9.60802 49.039 10.0227C48.9906 10.5113 48.6672 10.6277 48.0572 10.6099C46.9902 10.5835 45.9187 10.6056 44.8448 10.6048Z" />
    <path d="M35.9518 10.6068C34.8504 10.6068 33.7489 10.5881 32.6486 10.6145C32.0892 10.6272 31.9212 10.51 32.0409 10.0919C32.5645 8.27769 33.056 6.45841 33.5647 4.64253C33.9269 3.34866 34.2944 2.05508 34.6674 0.761783C34.7295 0.54765 34.7065 0.251942 35.1634 0.269786C35.7803 0.291879 36.1061 0.336066 36.9935 0.373454C37.2237 0.382801 36.9935 0.859503 36.9336 1.10083C36.3443 3.23536 35.7516 5.3699 35.1162 7.49764C34.9563 8.03807 35.0748 8.24965 35.8989 8.22161C37.249 8.17488 38.6048 8.22161 39.9572 8.20122C40.5672 8.19272 40.7997 8.30914 40.4832 8.75525C40.4403 8.84255 40.4136 8.93373 40.4038 9.02631C39.9814 10.6077 39.9814 10.6077 37.745 10.6085C37.1477 10.6085 36.5492 10.6085 35.9518 10.6085V10.6068Z" />
    <path d="M38.4157 14.498C38.2293 15.2297 38.3674 16.0802 37.7689 16.5374C37.1704 16.9946 36.0425 16.6547 35.1528 16.692C34.6751 16.7124 34.4115 16.7872 34.3022 17.1687C33.7267 19.186 33.1167 21.199 32.5193 23.2129C32.4583 23.4169 32.4146 23.6242 32.3455 23.8264C32.1027 24.5402 31.3707 24.8351 30.428 24.6371C30.0171 24.5521 30.1817 24.3524 30.2255 24.1978C30.5075 23.201 30.8113 22.2077 31.1071 21.2126C31.4823 19.9465 31.8583 18.6807 32.2351 17.4152C32.3847 16.9147 32.1821 16.6555 31.4294 16.7031C31.0841 16.7252 30.7388 16.692 30.3935 16.692C28.766 16.692 28.766 16.692 29.1977 15.5169C29.5729 14.4972 29.5729 14.4972 31.0576 14.4972L38.4157 14.498Z" />
  </svg>
</template>

<script>
  export default {}
</script>
