import { PurchaseOrderFragment } from '@/graphql/fragments/purchase'
import gql from 'graphql-tag'

export const Fragment = gql`
  ${PurchaseOrderFragment}
  fragment PurchaseForm on purchase_purchase_order {
    ...PurchaseOrder
    negotiation {
      id
    }
  }
`
export default Fragment
