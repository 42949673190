import gql from 'graphql-tag'

import { LeadFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${LeadFragment}
  query findLead($filter: crm_lead_bool_exp, $limit: Int = 6) {
    records: crm_lead(where: $filter, limit: $limit) {
      ...Lead
    }
  }
`

export default query
