import { Actions } from '../../interfaces'
import { Pipeline } from '@/entities/crm'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { Pipeline },
  actions: { [Pipeline.name]: actions },
}
