import gql from 'graphql-tag'

export const getBusinessTypes = gql`
  query business_types {
    crmPipeline: crm_pipeline {
      color
      icon
      id
      name
      description
    }
  }
`
