import { Actions } from '../../interfaces'

import { Reserve } from '@/entities/sales'
import create from './create'
import fetch from './fetch'
import fetchLite from './fetchLite'
import update from './update'
import find from './find'

export const actions: Actions = {
  create,
  fetch,
  fetchLite,
  update,
  find,
}

export default {
  Model: { Reserve },
  actions: { [Reserve.name]: actions },
}
