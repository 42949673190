import gql from 'graphql-tag'
import { VariantFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${VariantFragment}
  query findVariant($filter: settings_variant_bool_exp, $order: [settings_variant_order_by!], $limit: Int){
    records: settings_variant(where: $filter, order_by: $order, limit: $limit) {
      ...Variant
    }
  }
`

export default query
