import { Employee, Entity } from '@/models'
import { Price } from '@/models/interfaces'
import { Stock as IStock } from './interfaces'
import { fixPrice } from '@/utils/general'

export class Stock extends Entity implements IStock {
  price: Price;
  executive?: Employee

  constructor (stock: IStock) {
    super(stock)
    const { price, executive } = stock

    this.price = price
    if (executive) this.executive = new Employee(executive)
  }

  get discount (): string | undefined {
    const { price: { min, max } } = this
    return fixPrice(max - min)
  }
}
