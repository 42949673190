import { Type } from 'class-transformer'
import { EnumEntity } from '..'

import { Person } from '.'
import { PersonTypeEnum } from '@/graphql'

export class PersonType extends EnumEntity {
  static readonly system: PersonTypeEnum = 'system'
  static readonly company: PersonTypeEnum = 'company'
  static readonly natural: PersonTypeEnum = 'natural_person'

  @Type(() => Person)
  people: Person[];

  declare name: PersonTypeEnum

  get isCompany () {
    return this.name === PersonType.company
  }

  get isNatural () {
    return this.name === PersonType.natural
  }
}
