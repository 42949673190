import gql from 'graphql-tag'
import { FileProcessFragment } from '@/graphql/fragments/files'

export const mutation = gql`
  ${FileProcessFragment}
  mutation updateFileProcess($id: Int!,$fields: files_file_process_set_input!) {
    record: update_files_file_process_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...FileProcess
    }
  }
`

export default mutation
