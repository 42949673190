import { Type } from 'class-transformer'
import { Entity } from '..'

import { Ownership } from '.'
import { Person } from '../persons'

export class AutoOwner extends Entity {
  @Type(() => Person)
  person: Person;

  @Type(() => Ownership)
  ownership: Ownership;

  ownershipDate: string;
}
