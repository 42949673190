import { GetterTree } from 'vuex'
import { State as RootState } from '@/store/state'
import dayjs, { Dayjs } from 'dayjs'
import { localToUtc } from '@/utils/general'

export interface Dashboard {
  date: Dayjs
}

export function initState (): Dashboard {
  return {
    date: dayjs(),
  }
}

export const getters: GetterTree<Dashboard, RootState> = {
  date: (state: Dashboard) => state.date,
  currentMonth: (state: Dashboard) => {
    return {
      _gte: localToUtc(state.date.startOf('month')).format('YYYY-MM-DDTHH:mm:ss'),
      _lte: localToUtc(state.date.endOf('month')).format('YYYY-MM-DDTHH:mm:ss'),
    }
  },
  prevMonth: (state: Dashboard) => {
    return {
      _gte: localToUtc(state.date.startOf('month').subtract(1, 'month')).format('YYYY-MM-DDTHH:mm:ss'),
      _lte: localToUtc(state.date.startOf('month').subtract(1, 'month').endOf('month')).format('YYYY-MM-DDTHH:mm:ss'),
    }
  },
  lastYear: (state: Dashboard) => {
    return {
      _gte: localToUtc(state.date.subtract(1, 'year').startOf('month')).format('YYYY-MM-DDTHH:mm:ss'),
      _lte: localToUtc(state.date.endOf('month')).format('YYYY-MM-DDTHH:mm:ss'),
    }
  },
}
