import { ProcessExpenseFragment } from '@/graphql/fragments/settings'
import gql from 'graphql-tag'

export const query = gql`
  ${ProcessExpenseFragment}
  query findSettingsProcessExpense($filter: settings_process_expense_bool_exp,$order: [settings_process_expense_order_by!], $limit: Int){
    records: settings_process_expense(where:$filter,order_by: $order, limit: $limit) {
      ...ProcessExpense
    }
  }
`

export default query
