import gql from 'graphql-tag'
import { AgreementProductFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${AgreementProductFragment}
  query findAgreementProduct($filter: settings_agreement_product_bool_exp, $order: [settings_agreement_product_order_by!], $limit: Int){
    records: settings_agreement_product(where: $filter, order_by: $order, limit: $limit) {
      ...AgreementProduct
    }
  }
`

export default query
