import gql from 'graphql-tag'

export const findSimpleAttributesByVersionYear = gql`
  query get_simple_attributes_by_version_years_array($versionYearIds: [Int!]!) {
    attributes:get_simple_attributes_by_version_years_array(version_year_ids: $versionYearIds) {
      id
      rotationIndex {
        id
      }
      attributes {
        value
        componentValueId
        componentId
        component
      }
      generations {
        id
        sku
        rotationIndex {
          id
        }
        attributes {
          component
          componentId
          componentValueId
          value
        }
      }
    }
  }
`
