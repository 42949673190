import { Type } from 'class-transformer'
import { Entity } from '..'

import { Person, BankData } from '.'

export class PersonAccount extends Entity {
  @Type(() => Person)
  holder: Person;

  @Type(() => BankData)
  bankData: BankData;

  active: boolean;

  get name () {
    const { bankData } = this
    return `${bankData?.name}`
  }
}
