import gql from 'graphql-tag'
import { BrandFragment } from '@/graphql/fragments/vehicle'

export const mutation = gql`
  ${BrandFragment}
  mutation insertBrand($fields: vehicle_brand_insert_input!) {
    record: insert_vehicle_brand_one(object: $fields) {
      ...Brand
    }
  }
`

export default mutation
