import { Type } from 'class-transformer'
import { Entity } from '..'

import { InspectionAssessment } from '.'

export class InspectionAssessmentOption extends Entity {
  @Type(() => InspectionAssessment)
  inspectionAssessments: InspectionAssessment[];

  name: string;
}
