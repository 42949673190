import VueRouter, { Route, RouteRecord } from 'vue-router'
import { Store } from 'vuex'
import { NavigationGuard } from 'vue-router/types/router'

import { State } from '@/store/state'
import { Link } from '@/store/app/state'
import { firebaseIsLoaded, toRouteRecord } from '@/router/helpers'
import { IForms } from '@/store/resources/form/state'
import { Resource } from '@/entities/public'

let store: Store<State>

function _hasDataTablePermissions (links: Array<Link>, { meta, name }: RouteRecord): boolean {
  return meta.public || links.some(({ route }) => name === route.name)
}

function hasSlug (form: Resource | Record<string, any>, name: string): boolean {
  if (form.slug) {
    const camelCaseName = form.slug.replace(/_([a-z])/g, g => g[1].toUpperCase())

    return name.includes(form.slug) || name.includes(camelCaseName)
  }

  for (const key in form) {
    const isObject = form.hasOwnProperty(key) && typeof form[key] === 'object'
    if (isObject && hasSlug(form[key], name)) {
      return true
    }
  }
  return false
}

function _hasFormPermissions (forms: IForms, { path }: Route | RouteRecord): boolean {
  if (!path.includes('form')) return false

  return hasSlug(forms, path)
}

const requireLogin: NavigationGuard = async (to, from, next) => {
  if (to.name === from.name) return next()
  if (!to.name) return next({ name: 'NotFound' })

  await firebaseIsLoaded(store)
  const _isLoggedIn = store.getters['user/isLoggedIn']
  const _user = store.getters['user/user']
  const _roles = store.getters['user/roles']
  const links = store.getters['resources/links'] as Array<Link>
  const forms = store.getters['resources/forms'] as IForms
  const current = toRouteRecord(to)

  if (current?.meta.requiresLogin) {
    if (!_isLoggedIn) return next({ name: 'login' })
    if (_isLoggedIn && (!_user?.id || !_roles?.length)) return next({ name: 'forbidden' })
    if (!_hasDataTablePermissions(links, current) && !_hasFormPermissions(forms, to)) return next({ name: 'forbidden' })
  } else {
    if (_isLoggedIn && (current.name === 'login' || current.path === '/')) return next({ name: 'home' })
  }

  next()
}

const auth = (router: VueRouter, _store: Store<State>): void => {
  store = _store
  router.beforeEach(requireLogin)
}

export default auth
