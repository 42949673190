// @ts-ignore
export * from './createResourceGroup.gql'
// @ts-ignore
export * from './createResource.gql'
// @ts-ignore
export * from './deleteResource.gql'
// @ts-ignore
export * from './updateResource.gql'
// @ts-ignore
export * from './findResource.gql'
