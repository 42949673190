import gql from 'graphql-tag'
import { EntityFields } from '..'
import { PersonFragment } from '../persons'
import { EvaluationFragment } from '.'
import { FinancedItemFragment } from '@/graphql/fragments/loans/financedItem'

export const BaseFinancingFragment = gql`
  fragment BaseFinancing on loans_financing{
    ${EntityFields}
    warrantyAmount: warranty_amount
    initial
  }
`

export const FinancingFragment = gql`
  ${BaseFinancingFragment}
  ${PersonFragment}
  ${EvaluationFragment}
  ${FinancedItemFragment}
  fragment
  Financing on loans_financing{
    ...BaseFinancing
    applicant {
      ...Person
    }
    evaluations {
      ...Evaluation
    }
    financedItems:financed_items {
      ...FinancedItem
    }
  }
`
