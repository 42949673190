import gql from 'graphql-tag'
import { EntityFields } from '..'
import { PersonFragment } from '.'
import { AgreementTypeFragment } from '../settings'

export const BaseAgreementFragment = gql`
  fragment BaseAgreement on persons_agreement{
    ${EntityFields}
  }
`

export const AgreementFragment = gql`
  ${BaseAgreementFragment}
  ${PersonFragment}
  ${AgreementTypeFragment}
  fragment Agreement on persons_agreement{
    ...BaseAgreement
    person {
      ...Person
    }
    type {
      ...AgreementType
    }
  }
`
