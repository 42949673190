import { Type } from 'class-transformer'
import { Entity } from '..'

import { RegistrationAttribute, Component, Attribute } from '.'
import { InspectionComponent } from '../purchase'
import { DealAutoAttribute } from '../public'

class componentMetadata {
  metadata: {
    hidden: boolean
  }
}

export class ComponentValue extends Entity {
  public static readonly automatic = 'Automática'
  public static readonly manual = 'Mecánica'
  public static readonly cvt = 'CVT'
  public static readonly automated = 'Automatizada'
  @Type(() => Attribute)
  attributes: Attribute[];

  @Type(() => Component)
  component: Component;

  @Type(() => DealAutoAttribute)
  dealAutoAttributes: DealAutoAttribute[];

  @Type(() => InspectionComponent)
  inspectionComponents: InspectionComponent[];

  @Type(() => RegistrationAttribute)
  registrationAttributes: RegistrationAttribute[];

  @Type(() => componentMetadata)
  metadata: componentMetadata

  value: string;

  get isAutomatic () {
    const { value } = this
    return value === ComponentValue.automatic
  }

  get isManual () {
    const { value } = this
    return value === ComponentValue.manual
  }

  get isCvt () {
    const { value } = this
    return value === ComponentValue.cvt
  }

  get isAutomated () {
    const { value } = this
    return value === ComponentValue.automated
  }

  get inspectionComponent () {
    const { inspectionComponents } = this

    return inspectionComponents?.[0]
  }

  get inspectionParameter () {
    const { inspectionParameters } = this

    if (!inspectionParameters?.length) return null

    return inspectionParameters[0]
  }

  get inspectionParameters () {
    const { inspectionComponent } = this

    if (!inspectionComponent) return null

    const { inspectionParameters } = inspectionComponent

    return inspectionParameters
  }

  findQuestion (order: number) {
    if (!order) return
    const { inspectionParameters } = this

    if (!inspectionParameters?.length) return null

    return inspectionParameters.find(param => param.order === order || param.order === null)
  }
}
