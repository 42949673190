import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const ItemTypeFragment = gql`
  fragment ItemType on sales_item_type {
    ${EntityFields}
    ${EnumFields}
    billable
    negative
  }
`
