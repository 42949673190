import gql from 'graphql-tag'
import { StockFragment } from '@/graphql/fragments/sales'
import { AutoFragment } from '@/graphql/fragments/public'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'
import { BaseAutoMotiveRegistrationFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${StockFragment}
  ${AutoFragment}
  ${DealAutoAttributeFragment}
  ${BaseAutoMotiveRegistrationFragment}
  query findStock($filter: sales_stock_bool_exp, $order: [sales_stock_order_by!], $limit: Int) {
    records: sales_stock(where: $filter, order_by: $order, limit: $limit) {
      ...Stock
      enablement {
        id
        inspection {
          id
          appraisal {
            id
            expectedPublicationAmount:expected_publication_amount
            deal{
              id
              autoAttributes:auto_attributes(order_by:{id:desc}){
                ...DealAutoAttribute
              }
              auto{
                ...AutoBase
                version: version_year {
                  ...VersionYear
                }
                generation {
                  id
                  registrations:automotive_registrations{
                    ...AutoMotiveRegistration
                  }
                }
                status: process_status {
                  ...ProcessStatus
                }
              }
            }
          }
        }
      }
    }
  }
`
export default query
