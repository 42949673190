import { Type } from 'class-transformer'
import { Entity } from '..'

import { FileProcess } from '../files'

export class ValidationType extends Entity {
  @Type(() => FileProcess)
  fileProcess: FileProcess[];

  name: string;
  description: string;
}
