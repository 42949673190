import { Entity } from '..'
import { Type } from 'class-transformer'
import { Financing } from './Financing'
import { SaleOrderItem } from '@/entities/sales'

export class FinancedItem extends Entity {
  @Type(() => Financing)
  financing: Financing

  @Type(() => SaleOrderItem)
  saleOrderItem: SaleOrderItem
}
