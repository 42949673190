import gql from 'graphql-tag'
import { EntityFields } from '..'
import { AgreementProductFragment, QuotaCharacteristicFragment } from '../settings'

export const BaseVariantFragment = gql`
  fragment BaseVariant on settings_variant {
    ${EntityFields}
    period
    unit
    active
    description
    purchasable
  }
`
export const VariantFragment = gql`
  ${BaseVariantFragment}
  ${AgreementProductFragment}
  ${QuotaCharacteristicFragment}
  fragment Variant on settings_variant {
    ...BaseVariant
    agreementProduct: agreement_product {
      ...AgreementProduct
    }
    quotaCharacteristics: quota_characteristics {
      ...QuotaCharacteristic
    }
  }
`
