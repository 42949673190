import { Actions } from '../../interfaces'
import { Employee } from '@/entities/hr'
import find from './find'
import create from './create'
import update from './update'
import findLite from './findLite'

export const actions: Actions = {
  find,
  create,
  update,
  findLite,
}

export default {
  Model: { Employee },
  actions: { [Employee.name]: actions },
}
