import gql from 'graphql-tag'
import { PaymentTypeFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${PaymentTypeFragment}
  query findPaymentType($filter: finance_payment_type_bool_exp) {
    records: finance_payment_type(where: $filter) {
      ...PaymentType
    }
  }
`
export default query
