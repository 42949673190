import { Actions } from '../../interfaces'
import { Brand } from '@/entities/vehicle'

import find from './find'
import update from './update'
import create from './create'

export const actions: Actions = {
  find,
  update,
  create,
}

export default {
  Model: { Brand },
  actions: { [Brand.name]: actions },
}
