import { Type } from 'class-transformer'
import { Entity } from '..'

import { Person, Relationship } from '.'

export class ContactPerson extends Entity {
  @Type(() => Person)
  person: Person;

  @Type(() => Relationship)
  relationship: Relationship;

  @Type(() => Person)
  contact: Person;

  active: boolean;
}
