import { Type } from 'class-transformer'
import { Entity } from '..'
import { Icon } from '@/entities/crm'

export class Rol extends Entity {
  @Type(() => Icon)
  icon: Icon;

  name: string;
  description: string;
  slug: string;
}
