import gql from 'graphql-tag'
import { ProductApproachFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${ProductApproachFragment}
  query findProductApproach($filter: settings_product_approach_bool_exp, $order: [settings_product_approach_order_by!], $limit: Int){
    records: settings_product_approach(where: $filter, order_by: $order, limit: $limit) {
      ...ProductApproach
    }
  }
`

export default query
