import { Type } from 'class-transformer'
import { Entity } from '..'

import { Attribute, ComponentCategory, ComponentValue, RegistrationAttribute } from '.'
import { InspectionComponent } from '../purchase'
import { DealAutoAttribute } from '../public'
import { Icon } from '@/entities/settings'
import { ComponentType } from '../settings/ComponentType'

export class Component extends Entity {
    @Type(() => Attribute)
    attributes: Attribute[];

    @Type(() => ComponentCategory)
    category: ComponentCategory;

    @Type(() => ComponentType)
    type: ComponentType;

    @Type(() => ComponentValue)
    values: ComponentValue[];

    @Type(() => DealAutoAttribute)
    dealAutoAttributes: DealAutoAttribute[];

    @Type(() => InspectionComponent)
    inspectionComponents: InspectionComponent[];

    @Type(() => RegistrationAttribute)
    registrationAttributes: RegistrationAttribute[];

    @Type(() => Icon)
    icon?: Icon

    name: string;
    required: boolean;
    slug?: string;
    multiple?: boolean;
    position?: number;
    favorite: boolean

    get question () {
      const { inspectionParameter } = this

      if (!inspectionParameter) return ''

      const { name } = inspectionParameter

      return name
    }

    get inspectionComponent () {
      const { inspectionComponents } = this

      return inspectionComponents?.[0]
    }

    get inspectionParameter () {
      const { inspectionParameters } = this

      if (!inspectionParameters?.length) return null

      return inspectionParameters[0]
    }

    get inspectionParameters () {
      const { inspectionComponent } = this

      if (!inspectionComponent) return null

      const { inspectionParameters } = inspectionComponent

      return inspectionParameters
    }

    findQuestion (order: number) {
      if (!order) return
      const { inspectionParameters } = this

      if (!inspectionParameters?.length) return null

      return inspectionParameters.find(param => param.order === order || param.order === null)
    }
}
