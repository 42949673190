import gql from 'graphql-tag'
import { EntityFields } from '@/graphql/fragments'

export const query = gql`
  query model($filter: vehicle_model_bool_exp,$order: [vehicle_model_order_by!]) {
    records: vehicle_model(where: $filter, order_by: $order){
      ${EntityFields}
      name
      segment
      officialWeb:official_web
      priceLink: price_link
    }
  }
`
export default query
