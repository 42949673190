import { Module } from 'vuex'

import { State as RootState } from '@/store/state'
import { actions } from '@/store/persons/address/actions'
import {
  Address,
  initState,
  getters,
  mutations,
} from '@/store/persons/address/state'

const module: Module<Address, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
}

export default module
