import gql from 'graphql-tag'
import { BaseCityFragment } from '@/graphql/fragments/public'

export const query = gql`
    ${BaseCityFragment}
    query findCity($filter: city_bool_exp!, $limit: Int=6) {
        records: city(where: $filter, limit: $limit, order_by: {name: asc}) {
            ...BaseCity
        }
    }
`
export default query
