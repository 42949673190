import { Type } from 'class-transformer'
import { Entity } from '..'

import { Inspection, InspectionComponent, InspectionQualification } from '.'
import { Process } from '../settings'
import { ExtraGuarantee } from '../sales'

export class InspectionInspectedComponent extends Entity {
  @Type(() => Inspection)
  inspection: Inspection;

  @Type(() => InspectionComponent)
  inspectionComponent: InspectionComponent;

  @Type(() => Process)
  process: Process;

  @Type(() => InspectionQualification)
  inspectionQualifications: InspectionQualification[];

  @Type(() => ExtraGuarantee)
  extraGuarantee: ExtraGuarantee[];

  cost: number | null;
  supervisorCost: number | null;
  comment: string | null;

  get componentQualification () {
    return this.inspectionQualifications.flatMap(qualification => qualification?.assessment)
  }

  get componentScoreQualification () {
    return this.inspectionQualifications?.[0]?.assessment?.score
  }

  get componentQualificationValue () {
    return this.inspectionQualifications.flatMap(qualification => qualification?.value)
  }

  get inspectionQualification () {
    return this.inspectionQualifications[0]
  }

  get isInspectionInspectedComponentValidated () {
    const { inspectionQualifications } = this

    return inspectionQualifications?.every(inspectionQualifications => inspectionQualifications.validated)
  }

  findInspectionParameter (id) {
    const { inspectionQualifications } = this

    if (!inspectionQualifications?.length) return null

    return inspectionQualifications.find(inspectionQualification => inspectionQualification?.parameter?.id === id)
  }

  findInspectionParameterByOrder (order) {
    const { inspectionQualifications } = this

    if (!inspectionQualifications?.length) return null

    return inspectionQualifications.find(inspectionQualification => inspectionQualification?.parameter?.order === order)
  }

  findInspectionQualificationByOrder (order) {
    const { inspectionQualifications } = this

    if (!inspectionQualifications?.length) return null

    return inspectionQualifications.filter(inspectionQualification => inspectionQualification?.parameter?.order === order)
  }
}
