import { Type } from 'class-transformer'
import { Entity } from '..'

import { EvaluationRequirement } from './EvaluationRequirement'

export class RequirementType extends Entity {
  @Type(() => EvaluationRequirement)
  evaluations: EvaluationRequirement[];

  name: string;

  description: string;
}
