import gql from 'graphql-tag'
import { ItemTypeFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${ItemTypeFragment}
  query findSaleOrderItemType($filter: sales_item_type_bool_exp, $order: [sales_item_type_order_by!], $limit: Int) {
    records: sales_item_type(where: $filter, order_by: $order, limit: $limit) {
      ...ItemType
    }
  }
`
export default query
