import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.ts$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      // @ts-ignore
      messages[locale] = locales(key).default
    }
  })
  return messages
}

export default new VueI18n({
  locale: 'es', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages: loadLocaleMessages(),
  // @ts-ignore
  missing: (locale, key, vm) => {
    if (
      !key.includes('$vuetify') &&
      key.includes('error') &&
      key.includes('.')
    ) {
      // @ts-ignore
      return vm.$t(`error.default`)
    }
    return key
  },
})
