import { Actions } from '../../interfaces'
import { Lead } from '@/entities/crm'

import find from './find'
import findLite from './findLite'
import create from './create'
import update from './update'
import fetch from './fetch'
import fetchLite from './fetchLite'

export const actions: Actions = {
  find,
  findLite,
  create,
  update,
  fetch,
  fetchLite,
}

export default {
  Model: { Lead },
  actions: { [Lead.name]: actions },
}
