import gql from 'graphql-tag'

export const sendMileagePrice = gql`
  mutation upsertMileagePrice($input: UpdateMileagePriceInput!) {
    data:upsert_mileagePrice(input: $input) {
      id
      amount
      amountFinanced
      link
      mileageFrom
      mileageTo
    }
  }
`
