import negotiations from './negotiations'
import evaluations from './evaluations'
import reserve from './reserve'
import sales from './salesOrder'

export const queries = {
  negotiations,
  evaluations,
  reserve,
  sales,
}
export default queries
