<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M23.9873 12.9497C23.9873 11.9435 23.9057 11.2092 23.729 10.4478H12.239V14.9893H18.9833C18.8474 16.118 18.1132 17.8177 16.4814 18.9598L16.4585 19.1118L20.0915 21.9262L20.3431 21.9513C22.6547 19.8165 23.9873 16.6754 23.9873 12.9497Z"
        fill="#4285F4"
      />
      <path
        d="M12.239 24.9156C15.5432 24.9156 18.3171 23.8277 20.3432 21.9513L16.4815 18.9598C15.4481 19.6805 14.0611 20.1836 12.239 20.1836C9.00285 20.1836 6.25616 18.0488 5.27705 15.0982L5.13353 15.1103L1.35598 18.0338L1.30658 18.1711C3.319 22.1688 7.45267 24.9156 12.239 24.9156Z"
        fill="#34A853"
      />
      <path
        d="M5.27702 15.0982C5.01867 14.3367 4.86916 13.5208 4.86916 12.6778C4.86916 11.8347 5.01867 11.0189 5.26343 10.2574L5.25658 10.0952L1.4317 7.12479L1.30655 7.18431C0.47714 8.84323 0.0012207 10.7061 0.0012207 12.6778C0.0012207 14.6494 0.47714 16.5122 1.30655 18.1711L5.27702 15.0982Z"
        fill="#FBBC05"
      />
      <path
        d="M12.239 5.17192C14.537 5.17192 16.0871 6.16454 16.971 6.99405L20.4247 3.62183C18.3036 1.65019 15.5432 0.440002 12.239 0.440002C7.45266 0.440002 3.319 3.18669 1.30658 7.18434L5.26345 10.2574C6.25616 7.30676 9.00285 5.17192 12.239 5.17192Z"
        fill="#EB4335"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24.56"
          fill="white"
          transform="translate(0 0.440033)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
  export default {}
</script>
