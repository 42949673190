import gql from 'graphql-tag'
import { EntityFields } from '..'
import { CompanyFragment } from '../persons/person'

export const BaseExternalOfferFragment = gql`
  fragment ExternalOfferBase on purchase_appraisal_external_offer {
    ${EntityFields}
    amount
  }
`

export const ExternalOfferFragment = gql`
  ${BaseExternalOfferFragment}
  ${CompanyFragment}
  fragment ExternalOffer on purchase_appraisal_external_offer {
    ...ExternalOfferBase
    company {
      ...Company
    }
  }
`
