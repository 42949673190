import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const CurrencyFragmentBase = gql`
  fragment CurrencyBase on settings_currency{
    ${EntityFields}
    ${EnumFields}
  }
`

export const CurrencyFragment = gql`
  ${CurrencyFragmentBase}
  fragment Currency on settings_currency{
    ...CurrencyBase
  }
`
