import gql from 'graphql-tag'
import { BasePriceFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${BasePriceFragment}
  query findBonus($filter: sales_bonus_bool_exp, $order: [sales_bonus_order_by!], $limit: Int) {
    records: sales_bonus(where: $filter, order_by: $order, limit: $limit) {
      ...Bonus
    }
  }
`
export default query
