import gql from 'graphql-tag'
import Fragment from '@/graphql/forms/sales/stock/fragment'

export const mutation = gql`
  ${Fragment}
  mutation insertStock($fields: sales_stock_insert_input!) {
    record: insert_sales_stock_one(object: $fields){
      id
    }
  }
`

export default mutation
