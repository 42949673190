import { Actions } from '../../interfaces'

import { RequirementType } from '@/entities/loans'
import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { RequirementType },
  actions: { [RequirementType.name]: actions },
}
