import gql from 'graphql-tag'

import { ReserveFragment, SaleOrderFragment } from '@/graphql/fragments/sales'
import { LeadFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${ReserveFragment}
  ${LeadFragment}
  ${SaleOrderFragment}
  query fetchReserve($id: Int!) {
    records: sales_reserve_by_pk(id: $id) {
      ...Reserve
      idSaleOrder:id_sale_order
      saleOrder:sale_order {
        ...SaleOrder
        deal {
          id
          lead {
            ...Lead
          }
          stock{
            id
          }
        }
      }
    }
  }
`

export default query
