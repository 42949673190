export class UnimplementedModel extends Error {
  constructor ({ model }) {
    super(`Unimplemented Model: ${model}`)
  }
}

export class UnimplementedAction extends Error {
  constructor ({ name, model }) {
    super(`Unimplemented action "${name}" for Model: ${model}`)
  }
}

export class UnregisteredQuery extends Error {
  constructor ({ uid }) {
    super(`Query ${uid} is not registered`)
  }
}

export class MissingVariable extends Error {
  constructor ({ name, path }) {
    super(`variable: "${name}" at "${path}" path is undefined`)
  }
}
