import gql from 'graphql-tag'
import { BonusFragment } from '@/graphql/fragments/sales'

export const mutation = gql`
  ${BonusFragment}
  mutation insertBonus($fields: sales_bonus_insert_input!) {
    record: insert_sales_bonus_one(object: $fields){
      ...Bonus
    }
  }
`

export default mutation
