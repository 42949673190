import { GetterTree, MutationTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Details } from '@/utils/generalInterface'
import { PersonFormatted } from '@/store/persons/person'
import { ConsultRutInterface } from '@/store/persons/state'
import { EnterpriseFormatted } from '@/store/persons/enterprise'

export interface Labor {
  contract: Details[]
  activity: Details[]
  rent: Details[]
  laborPerson?: PersonFormatted | undefined
  rutInfo?: ConsultRutInterface | undefined
  enterprisePerson?: EnterpriseFormatted | undefined
  laborCreated?: any
  rutErrorMessage: string
  laborInfo: any
  laborPersonUpdated: any
}

export function initState (): Labor {
  return {
    contract: [],
    activity: [],
    rent: [],
    laborPerson: undefined,
    rutInfo: undefined,
    enterprisePerson: undefined,
    laborCreated: undefined,
    rutErrorMessage: '',
    laborInfo: undefined,
    laborPersonUpdated: undefined,
  }
}

export const mutations: MutationTree<Labor> = {
  setLaborDataUpdated (state, payload) {
    state.laborPersonUpdated = payload
  },
  setLaborData (state: Labor, data: any) {
    state.laborInfo = data
  },
  setRutErrorMessage (state: Labor, value: string) {
    state.rutErrorMessage = value
  },
  setEnterprisePerson (state, enterprisePerson) {
    state.enterprisePerson = enterprisePerson
  },
  setContract (state, contract: Details[]) {
    state.contract = contract
  },
  setActivity (state, activity: Details[]) {
    state.activity = activity
  },
  setRent (state, rent: Details[]) {
    state.rent = rent
  },
  setRutInfoLabor: (state, rutInfo) => {
    state.rutInfo = rutInfo
  },
  setFoundPersonLabor (state, payload) {
    state.laborPerson = payload
  },
  setLaborDataCreated (state, payload) {
    state.laborCreated = payload
  },
}

export const getters: GetterTree<Labor, RootState> = {
  enterprisePerson: state => state.enterprisePerson,
  contract: ({ contract }): Array<Details> => {
    return contract
  },
  activity: ({ activity }): Array<Details> => {
    return activity
  },
  rent: ({ rent }): Array<Details> => {
    return rent
  },
  getRutInfoLabor: ({ rutInfo }) => {
    return rutInfo
  },
  getLaborPerson: state => state.laborPerson,
  laborDataCreated: state => state.laborCreated,
  rutErrorMessage: state => state.rutErrorMessage,
  laborInfo: state => state.laborInfo,
  laborPersonUpdated: state => state.laborPersonUpdated,
}
