import { Actions } from '../../interfaces'

import find from './find'
import { SupportDocumentType } from '@/entities/settings'

export const actions: Actions = {
  find,
}

export default {
  Model: { SupportDocumentType },
  actions: { [SupportDocumentType.name]: actions },
}
