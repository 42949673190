import gql from 'graphql-tag'

import { PurchaseOrderFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
    ${PurchaseOrderFragment}
    mutation insertPurchaseOrder($fields: purchase_purchase_order_insert_input!) {
        record: insert_purchase_purchase_order_one(object: $fields) {
            ...PurchaseOrder
        }
    }
`

export default mutation
