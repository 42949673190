import gql from 'graphql-tag'

export const mutation = gql`
  mutation deleteInspectionQualification($id: Int!) {
    record: delete_purchase_inspection_qualification_by_pk(id: $id) {
      id
    }
  }
`

export default mutation
