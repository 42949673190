import gql from 'graphql-tag'
import { DailyAccountBalanceFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${DailyAccountBalanceFragment}
  query findDailyAccountBalance($filter: finance_daily_account_balance_bool_exp,$order:[finance_daily_account_balance_order_by!]) {
    records: finance_daily_account_balance(where: $filter,order_by: $order) {
      ...DailyAccountBalance
    }
  }
`
export default query
