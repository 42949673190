import { Module } from 'vuex'

import { State as RootState } from '@/store/state'
import { actions } from '@/store/persons/reference/actions'
import {
  Reference,
  initState,
  getters,
  mutations,
} from '@/store/persons/reference/state'

const module: Module<Reference, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
}

export default module
