import { Type } from 'class-transformer'
import { Entity } from '..'

import { Agreement } from './Agreement'
import { Person } from './Person'
import { Evaluation } from '../loans'

export class ResponsibleAgreement extends Entity {
  @Type(() => Agreement)
  agreement: Agreement;

  @Type(() => Person)
  responsible: Person;

  @Type(() => Evaluation)
  evaluations: Evaluation[];

  idAgreement: number;

  idResponsible: number;
}
