import { Entity, Icon } from '@/models/interfaces'
import { Stock } from '@/models/stock/interfaces'

export interface Brand extends Entity {
  name: string
}

export interface Model extends Entity {
  name: string
  brand: Brand
}

export interface Version extends Entity {
  name: string
  year: number
  model: Model
}

export enum VehicleStatus {
  buying,
  unavailable,
  enabling,
  available,
  reserved,
  selling,
  sold,
}

export interface Vehicle extends Entity {
  ppu: string
  stock?: Stock
  version: Version
  status: VehicleStatus
}

export const VehicleStatusIcons: Record<VehicleStatus, Icon> = {
  [VehicleStatus.available]: {
    name: 'Disponible',
    icon: 'mdi-circle',
    color: '#13CE66',
  },
  [VehicleStatus.selling]: {
    name: 'Venta en proceso',
    icon: 'mdi-square',
    color: 'primary',
  },
  [VehicleStatus.sold]: {
    name: 'Vendido',
    icon: 'mdi-circle',
    color: 'primary',
  },
  [VehicleStatus.reserved]: {
    name: 'Reservado',
    icon: 'mdi-circle',
    color: 'purple',
  },
  [VehicleStatus.buying]: {
    name: 'Comprando',
    icon: 'mdi-warning',
    color: 'grey',
  },
  [VehicleStatus.unavailable]: {
    name: 'Indisponible',
    icon: 'mdi-error',
    color: 'red',
  },
  [VehicleStatus.enabling]: {
    name: 'Habilitación',
    icon: 'mdi-circle',
    color: 'orange',
  },
}
