<template>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.3 14.6V21.8923H18.5858C18.4518 21.4851 18.3909 21.078 18.3909 20.6769H14.7363C14.7363 20.0084 14.1942 19.4615 13.5182 19.4615V17.0307C14.1942 17.0307 14.7363 16.4899 14.7363 15.8154H20.8272C20.8272 16.4899 21.3754 17.0307 22.0454 17.0307H22.1069C22.515 17.0307 22.8615 17.1401 23.2635 17.2738V14.6H12.3ZM17.7818 16.4231C16.7463 16.4413 15.9545 17.2131 15.9545 18.2461C15.9545 19.2792 16.7463 20.0327 17.7818 20.0692C18.0132 20.0692 18.2508 20.0206 18.4761 19.9294C18.6406 19.261 18.9147 18.629 19.5908 18.0091C19.5177 17.2009 18.7502 16.4048 17.7818 16.4231ZM23.6473 18.4102L21.2901 20.7802L20.4678 19.9477L19.609 20.8106L21.284 22.5L24.5 19.2671L23.6473 18.4102Z"
    />
    <path
      d="M11.6208 16.1458C11.6208 13.5 11.25 13.95 15.4 13.95C16.5354 13.95 21.3542 14 22.3958 14V12.5L20.2292 6.24998C20.0208 5.64581 19.4375 5.20831 18.75 5.20831H7.29168C6.60418 5.20831 6.02084 5.64581 5.81251 6.24998L3.64584 12.5V20.8333C3.64584 21.4062 4.11459 21.875 4.68751 21.875H5.72918C6.30209 21.875 6.77084 21.4062 6.77084 20.8333V19.7916H11.6208C11.6208 18.2291 11.6208 17.1875 11.6208 16.1458ZM7.29168 6.77081H18.75L20.3125 11.4583H5.72918L7.29168 6.77081ZM7.29168 16.6666C6.42709 16.6666 5.72918 15.9687 5.72918 15.1041C5.72918 14.2396 6.42709 13.5416 7.29168 13.5416C8.15626 13.5416 8.85418 14.2396 8.85418 15.1041C8.85418 15.9687 8.15626 16.6666 7.29168 16.6666Z"
    />
  </svg>
</template>

<script>
  export default {}
</script>
