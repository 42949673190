import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const ChannelFragment = gql`
  fragment Channel on crm_channel {
    ${EntityFields}
    ${EnumFields}
    color
    icon
  }
`
