import { Actions } from '../interfaces'

import brand from './brand'
import model from './model'
import version from './version'
import year from './year'
import versionYear from './version-year'
import attribute from './attribute'
import component from './component'
import componentValue from './componentValue'
import automotiveRegistration from './automotiveRegistration'
import generation from './generation'
import componentCategory from './componentCategory'
import mileagePrice from './mileagePrice'
import rotationIndex from './rotationIndex'
import vehicleGama from './vehicleGama'
import concessionaire from './concessionaire'

export const Models = {
  ...vehicleGama.Model,
  ...brand.Model,
  ...model.Model,
  ...version.Model,
  ...year.Model,
  ...versionYear.Model,
  ...attribute.Model,
  ...component.Model,
  ...componentValue.Model,
  ...automotiveRegistration.Model,
  ...componentCategory.Model,
  ...generation.Model,
  ...mileagePrice.Model,
  ...rotationIndex.Model,
  ...concessionaire.Model,
}

export const actions: Record<string, Actions> = {
  ...vehicleGama.actions,
  ...brand.actions,
  ...model.actions,
  ...version.actions,
  ...year.actions,
  ...versionYear.actions,
  ...attribute.actions,
  ...component.actions,
  ...componentValue.actions,
  ...automotiveRegistration.actions,
  ...generation.actions,
  ...componentCategory.actions,
  ...mileagePrice.actions,
  ...rotationIndex.actions,
  ...concessionaire.actions,
}

export const schema = { Models, actions }
export default schema
