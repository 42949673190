import gql from 'graphql-tag'

export const mutation = gql`
  mutation updateDailyAccountBalance($id: Int!, $fields: finance_daily_account_balance_set_input!) {
    record: update_finance_daily_account_balance_by_pk(pk_columns: {id: $id}, _set: $fields) {
      id
    }
  }
`

export default mutation
