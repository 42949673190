import gql from 'graphql-tag'
import { MaintenanceTypeFragment } from '@/graphql/fragments/public/maintenanceType'

export const query = gql`
  ${MaintenanceTypeFragment}
  query findMaintenanceType($filter: maintenance_type_bool_exp!) {
    records: maintenance_type(where: $filter) {
      ...MaintenanceType
    }
  }
`
export default query
