import gql from 'graphql-tag'
import { EntityFields } from '..'

export const BaseQuotaFragment = gql`
  fragment BaseQuota on loans_quota{
    ${EntityFields}
    amount
  }
`

export const QuotaFragment = gql`
  ${BaseQuotaFragment}
  fragment Quota on loans_quota{
    ...BaseQuota
    characteristic {
      id
    }
    currency {
      id
      name
    }
  }
`
