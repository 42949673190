import gql from 'graphql-tag'

import { ReserveFragment, SaleOrderFragment } from '@/graphql/fragments/sales'
import { LeadFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${ReserveFragment}
  ${LeadFragment}
  ${SaleOrderFragment}
  query fetchLiteReserve($id: Int!) {
    records: sales_reserve_by_pk(id: $id) {
      ...Reserve
    }
  }
`

export default query
