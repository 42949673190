import gql from 'graphql-tag'
import { ReserveFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${ReserveFragment}
  query findReserve($filter: sales_reserve_bool_exp, $order: [sales_reserve_order_by!], $limit: Int) {
    records: sales_reserve(where: $filter, order_by: $order, limit: $limit) {
      ...Reserve
    }
  }
`
export default query
