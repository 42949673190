import { ActionTree } from 'vuex'

import { State as RootState } from '@/store/state'
import { Resources } from './state'
import { getUserResources } from '@/graphql/queries/resources'

export const actions: ActionTree<Resources, RootState> = {
  loadResources: async ({ rootState: { apolloClient }, commit }) => {
    commit('setAll', await getUserResources(apolloClient))
    commit('loadLinks')
    commit('loadForms')
  },
  cleanResources: ({ commit }) => commit('clean'),
  setActive: ({ commit }, resource) => {
    commit('setActive', resource)
    commit('setActiveCursor')
  },
  setFilter: ({ commit }, filter) => {
    commit('setFilter', filter)
    commit('setActiveCursor')
  },
  setCursorSize: ({ commit }, size) => commit('setCursorSize', size),
  setCursorPage: ({ commit }, page) => commit('setCursorPage', page),
  setPeriod: ({ commit }, period) => commit('setPeriod', period),
  refresh: async ({ dispatch, getters: { active: resource, period, filter, cursor } }, { force }) => {
    await dispatch('datatables/refresh', { resource, period, filter, cursor, force })
  },
}
