import { Transform, Type } from 'class-transformer'
import { Entity } from '..'
import { Auto } from '../public/Auto'
import { Person } from '../persons'
import { ProcessTrafficTicket } from '../purchase'
import { Currency, InfractionType } from '../settings'
import dayjs, { Dayjs } from 'dayjs'
import { fixPrice } from '@/utils/general'

export class TrafficTicket extends Entity {
  @Type(() => Currency)
  amountCurrency: Currency;

  @Type(() => Auto)
  auto: Auto

  @Type(() => Person)
  court: Person

  @Type(() => InfractionType)
  type: InfractionType

  @Type(() => Currency)
  tariffCurrency: Currency;

  @Type(() => ProcessTrafficTicket)
  processTrafficTicket: ProcessTrafficTicket[];

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  infractionDate: Dayjs;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  judgmentDate: Dayjs;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  reportDate: Dayjs;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  admissionDate: Dayjs;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  paymentDate: Dayjs;

  uid: string;
  role: string;
  amount: number;
  tariff: number;

  get name () {
    const { uid, amountCurrency, amount } = this

    return `${amountCurrency.name.toUpperCase()} ${amount} / ID: ${uid}`
  }

  get infractionDateFormatted () {
    return this.infractionDate.format('DD/MM/YYYY')
  }

  get judgmentDateFormatted () {
    return this.judgmentDate?.format('DD/MM/YYYY')
  }

  get reportDateFormatted () {
    return this.reportDate?.format('DD/MM/YYYY')
  }

  get admissionDateFormatted () {
    return this.admissionDate?.format('DD/MM/YYYY')
  }

  get paymentDateFormatted () {
    return this.paymentDate?.format('DD/MM/YYYY')
  }

  get tariffName () {
    const { tariffCurrency, tariff } = this

    return `${tariffCurrency.name.toUpperCase()} ${fixPrice(tariff)}`
  }

  get amountName () {
    const { amountCurrency, amount } = this

    return `${amountCurrency.name.toUpperCase()} ${amount}`
  }
}
