import gql from 'graphql-tag'

import { InspectedComponentFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${InspectedComponentFragment}
  mutation insertInspectionInspectedComponent($fields: purchase_inspection_inspected_component_insert_input!) {
    record: insert_purchase_inspection_inspected_component_one(object: $fields) {
      ...InspectedComponent
    }
  }
`

export default mutation
