import { Actions } from '../../interfaces'
import { DealNote } from '@/entities/crm'

import find from './find'
import fetch from './fetch'
import create from './create'
import update from './update'

export const actions: Actions = {
  find,
  fetch,
  create,
  update,
}

export default {
  Model: { DealNote },
  actions: { [DealNote.name]: actions },
}
