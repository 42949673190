import { Actions } from '../interfaces'

import channel from './channel'
import intention from './intention'
import deal from './deal'
import lead from './lead'
import pipeline from './pipeline'
import dealNote from './dealNote'
import leadActivity from './leadActivity'
import activityType from './activityType'

export const Models = {
  ...channel.Model,
  ...intention.Model,
  ...deal.Model,
  ...lead.Model,
  ...pipeline.Model,
  ...dealNote.Model,
  ...leadActivity.Model,
  ...activityType.Model,
}

export const actions: Record<string, Actions> = {
  ...channel.actions,
  ...intention.actions,
  ...deal.actions,
  ...lead.actions,
  ...pipeline.actions,
  ...dealNote.actions,
  ...leadActivity.actions,
  ...activityType.actions,
}

export const schema = { Models, actions }
export default schema
