import gql from 'graphql-tag'
import Fragment from './fragment'

export const mutation = gql`
  ${Fragment}
  mutation updateAppraisalResponse($id: Int!,$fields: purchase_appraisal_response_set_input!) {
    record: update_purchase_appraisal_response_by_pk(pk_columns: {id: $id},_set: $fields) {
      ...AppraisalResponseForm
    }
  }
`

export default mutation
