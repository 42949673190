import gql from 'graphql-tag'

export const mutation = gql`
  mutation insertEnablement($fields: purchase_enablement_insert_input!) {
    record: insert_purchase_enablement_one(object: $fields) {
      id
    }
  }
`

export default mutation
