import gql from 'graphql-tag'
import { YearFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${YearFragment}
  query year($filter: vehicle_year_bool_exp) {
    records: vehicle_year(where: $filter, order_by: {id: desc}){
      ...Year
    }
  }
`
export default query
