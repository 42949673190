import gql from 'graphql-tag'
import { BaseDocumentIntervenerFragment } from '@/graphql/fragments/documents'

export const mutation = gql`
  ${BaseDocumentIntervenerFragment}
  mutation insertIntervener($fields: documents_intervener_insert_input!) {
    record: insert_documents_intervener_one(object: $fields) {
      ...BaseDocumentIntervener
    }
  }
`

export default mutation
