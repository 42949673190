import gql from 'graphql-tag'

export const getResource = gql`
  subscription getResources(
    $offset: Int! = 0
    $limit: Int! = 5
    $search: String! = "%%"
  ) {
    resources: resource(
      offset: $offset
      limit: $limit
      where: { name: { _ilike: $search } }
      order_by: { order: asc, name: asc }
    ) {
      id
      name
      slug
      icon
      description
      position: order
      resourceGroup: resource_group {
        id
        title
      }
    }
  }
`

export const getResourcesGroup = gql`
  subscription getResourcesGroup {
    resourceGroup: resource_group {
      id
      title
    }
  }
`

export const getTotalResources = gql`
  subscription getTotalResources($search: String! = "%%") {
    totalResources: resource_aggregate(where: { name: { _ilike: $search } }) {
      aggregate {
        count
      }
    }
  }
`

export const getResourcesForRoles = gql`
  query getResourcesForRoles {
    resources: resource {
      id
      name
      slug
      icon
      description
      position: order
      resourceGroup: resource_group {
        id
        title
      }
    }
  }
`
