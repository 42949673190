import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const BaseFieldFragment = gql`
  fragment BaseField on documents_field{
    ${EntityFields}
    ${EnumFields}
    structure
  }
`
