import { Resource } from '@/entities/public'

export class MetadataStructure extends Error {
  constructor (part) {
    super(`"${part}" part is required and missing in metadata`)
  }
}

export class MissingAPI extends MetadataStructure {
  constructor () {
    super('api')
  }
}

export class MissingField extends Error {
  constructor (name: string, { slug, metadata: { model } }: Resource) {
    super(`The "${name}" field is missing, in "${slug}" form for "${model}" Model`)
  }
}

export class IncorrectNestedField extends Error {
  constructor (name: string, { slug, metadata: { model } }: Resource) {
    super(`The "${name}" field is not a valid type, in "${slug}" form for "${model}" Model`)
  }
}

export class MissingDetailsFields extends Error {
  constructor () {
    super(`"Fields" is required in person details properties and is missing`)
  }
}

export class MissingDetailsValue extends Error {
  constructor () {
    super(`"Target" is empty value`)
  }
}

export class MissingForm extends Error {
  constructor (model) {
    super(`Form "${model}" is missing`)
  }
}

export class MissingDetailsFieldsName extends Error {
  constructor () {
    super(`"FieldsName" is required in person details properties and is missing`)
  }
}

export class DetailsFieldsLongitude extends Error {
  constructor () {
    super(`"FieldsName" and "Fields" doesn't have the same longitude`)
  }
}
