import gql from 'graphql-tag'
import { PaymentOrderFragment } from '@/graphql/fragments/finance'

export const mutation = gql`
  ${PaymentOrderFragment}
  mutation updatePaymentOrder($id: Int!,$fields: finance_payment_order_set_input!) {
    record: update_finance_payment_order_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...PaymentOrder
    }
  }
`

export default mutation
