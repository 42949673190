import gql from 'graphql-tag'
import { ExpenseFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${ExpenseFragment}
  query findExchange($filter: settings_expense_bool_exp!,$order: [settings_expense_order_by!], $limit: Int){
    records:settings_expense(where: $filter,order_by: $order, limit: $limit) {
      ...Expense
    }
  }
`

export default query
