import gql from 'graphql-tag'
import { EntityFields } from '..'
import { PersonFragment } from '@/graphql/fragments/persons'
import { PaymentFragment } from '@/graphql/fragments/finance/payment'

export const BasePaymentRecipientFragment = gql`
  fragment BasePaymentRecipient on finance_payment_recipient{
    ${EntityFields}
  }
`

export const PaymentRecipientFragment = gql`
  ${BasePaymentRecipientFragment}
  ${PersonFragment}
  ${PaymentFragment}
  fragment PaymentRecipient on finance_payment_recipient{
    ...BasePaymentRecipient
    person {
      ...Person
    }
    payments(where: {_or:[ {id_closing_reason: {_is_null: true}},{closing_reason: {type: {name: {_neq: "canceled"}}}}]}){
      ...Payment
    }
  }
`
