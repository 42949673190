import { Actions } from '../../interfaces'
import { InspectionView } from '@/entities/purchase'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { InspectionView },
  actions: { [InspectionView.name]: actions },
}
