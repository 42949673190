import gql from 'graphql-tag'
import { Auto } from '@/entities/public'
import { QueryBuilder } from '../utils'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import {
  BaseAttributeFragment,
  BaseComponentFragment, BaseComponentValueFragment,
  BaseVersionYearFragment, VersionFragment, YearFragment,
} from '@/graphql/fragments/vehicle'
import { ProcessStatusFragment } from '@/graphql/fragments/settings'

const query = gql`
  ${BaseAutoFragment}
  ${BaseVersionYearFragment}
  ${VersionFragment}
  ${BaseAttributeFragment}
  ${BaseComponentFragment}
  ${BaseComponentValueFragment}
  ${YearFragment}
  ${ProcessStatusFragment}
  query autos($filter: auto_bool_exp, $order: [auto_order_by!], $limit: Int, $offset: Int) {
    records: auto(order_by: $order, limit: $limit, offset: $offset, where: $filter) {
      ...AutoBase
      status:process_status {
        ...ProcessStatus
      }
      version:version_year {
        ...VersionYearBase
        version {
          ...Version
        }
        year{
          ...Year
        }
        attributes{
          ...AttributeBase
          componentValue:component_value {
            ...ComponentValueBase
          }
          component {
            ...ComponentBase
          }
        }
      }
    }
    total: auto_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const autos = QueryBuilder(query, Auto)
export default autos
