import gql from 'graphql-tag'

export const sumCategoryScore = gql`
  query sumCategoryScore($categoryName: String!, $id_inspection: Int!) {
    response:purchase_inspection_assessment_aggregate(where: {inspection_qualifications: {component: {_and: {id_inspection: {_eq: $id_inspection}, inspection_component: {component: {category: {name: {_eq: $categoryName}}}}}}}}) {
      aggregate {
        sum {
          score
        }
      }
    }
  }
`

export const getInspectionsQuery = gql`
  query inspections($start_date: timestamp!, $end_date: timestamp!) {
    records: purchase_inspection(where: {created_at: {_gt: $start_date, _lte: $end_date}}) {
      id
      status {
        id
        description
        status {
          id
          name
          description
        }
      }
      createdAt: created_at
      closingReason: closing_reason {
        description
        id
        type {
          id
          name
          description
        }
      }
      appraisal {
        id
        deal {
          id
          lead {
            id
            executive {
              id
              person {
                id
                firstName: first_name
                secondName: second_name
                surname
                secondSurname: second_surname
              }
            }
          }
        }
      }
    }
  }
`
