import gql from 'graphql-tag'
import { NoteFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${NoteFragment}
  query findDealNote($filter: crm_deal_note_bool_exp) {
    records: crm_deal_note (where: $filter) {
      ...Note
    }
  }
`

export default query
