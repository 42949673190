import gql from 'graphql-tag'
import { DailyAccountBalanceFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${DailyAccountBalanceFragment}
  query fetchDailyAccountBalance($id: Int!) {
    records: finance_daily_account_balance_by_pk(id: $id) {
      ...DailyAccountBalance
    }
  }
`

export default query
