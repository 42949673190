import gql from 'graphql-tag'
import { BaseFieldFragment } from '@/graphql/fragments/documents'

export const query = gql`
  ${BaseFieldFragment}
  query findDocumentType($filter: documents_field_bool_exp) {
    records: documents_field(where: $filter, order_by: {id: desc}) {
      ...BaseField
    }
  }
`
export default query
