import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ProcessStatusFragment } from '../settings/processStatus'
import { ClosingReasonFragment } from '../settings/closingReason'
import { ExternalOfferFragment } from './appraisalExternalOffer'
import { ResponseFragment } from './appraisalResponse'
import { EmployeeFragment } from '../hr'
import { PersonFragment } from '@/graphql/fragments/persons'

export const BaseAppraisalFragment = gql`
  fragment AppraisalBase on purchase_appraisal {
    ${EntityFields}
    agreedAmount:agreed_amount
    expectedPublicationAmount:expected_publication_amount
    link
  }
`

export const AppraisalFragment = gql`
  ${BaseAppraisalFragment}
  ${EmployeeFragment}
  ${ProcessStatusFragment}
  ${ClosingReasonFragment}
  ${PersonFragment}
  ${ExternalOfferFragment}
  ${ResponseFragment}
  fragment Appraisal on purchase_appraisal {
    ...AppraisalBase
    appraiser {
      ...Employee
      person{
        ...Person
      }
    }
    processStatus: status {
      ...ProcessStatus
    }
    closingReason: closing_reason {
      ...ClosingReason
    }
    responses(order_by: {created_at: desc_nulls_last}) {
      ...Response
    }
    externalOffers: external_offers(order_by: {amount: desc_nulls_last}) {
      ...ExternalOffer
    }
  }
`
