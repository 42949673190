import { Actions } from '../../interfaces'

import find from './find'
import { ProcessStatus } from '@/entities/settings'

export const actions: Actions = {
  find,
}

export default {
  Model: { ProcessStatus },
  actions: { [ProcessStatus.name]: actions },
}
