import { Type } from 'class-transformer'
import { Entity } from '..'

import { Component } from '.'

export class ComponentCategory extends Entity {
  @Type(() => Component)
  components: Component[];

  name: string;
}
