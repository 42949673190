import { Type } from 'class-transformer'
import { Entity } from '..'

import { RegistrationAttribute, RegistrationFile, Generation } from '.'

export class AutomotiveRegistration extends Entity {
  @Type(() => RegistrationFile)
  registrationFile: RegistrationFile;

  @Type(() => Generation)
  generation: Generation;

  @Type(() => RegistrationAttribute)
  registrationAttributes: RegistrationAttribute[];

  sku: string;
  year: number;
  appraisal: number | null;
  permission: string | null;
}
