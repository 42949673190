import dayjs, { Dayjs } from 'dayjs'
import { resourceFilter } from '@/graphql/generated-types'

enum Type {
  exact,
  since,
  until,
  between,
}

interface IPeriod {
  name: string
  title: string
  type: Type
  from?: Dayjs
  to?: Dayjs
  field?: string
}

export class Period {
  readonly name: string
  readonly title: string
  readonly type: Type

  from?: Dayjs
  to?: Dayjs

  private field: string

  constructor ({ name, title, type, from, to, field = 'created_at' }: IPeriod) {
    this.name = name
    this.title = title
    this.type = type
    this.from = from
    this.to = to
    this.field = field
  }

  private get since () {
    const { from } = this
    if (!from) return undefined

    return from.startOf('day').toISOString()
  }

  private get until () {
    let { to } = this
    if (!to) {
      if (this.type === Type.exact && this.from) to = this.from
      else return undefined
    }

    return to.endOf('day').toISOString()
  }

  get where (): resourceFilter {
    const { since, until, type } = this
    if ((!since && !until) || (type === Type.between && (!since || !until))) return undefined

    const { field } = this
    switch (type) {
      case Type.exact:
      case Type.between:
        return { _and: [{ [field]: { _gte: since } }, { [field]: { _lte: until } }] }
      case Type.since:
        return { [field]: { _gte: since } }
      case Type.until:
        return { [field]: { _lte: until } }
    }
  }
}

export const periods: Array<Period> = [
  { name: 'week', title: 'Esta semana', type: Type.since, from: dayjs().startOf('week') },
  { name: 'today', title: 'Hoy', type: Type.exact, from: dayjs() },
  { name: 'yesterday', title: 'Ayer', type: Type.exact, from: dayjs().subtract(1, 'day') },
  { name: 'month', title: 'Este mes', type: Type.since, from: dayjs().startOf('month') },
  {
    name: 'last_month',
    title: 'Último mes',
    type: Type.between,
    from: dayjs().subtract(1, 'month').startOf('month'),
    to: dayjs().subtract(1, 'month').endOf('month'),
  },
  { name: 'year', title: 'Este año', type: Type.since, from: dayjs().startOf('year') },
  {
    name: 'last_year',
    title: 'Último año',
    type: Type.between,
    from: dayjs().subtract(1, 'year').startOf('year'),
    to: dayjs().subtract(1, 'year').endOf('year'),
  },
  // { name: 'range', title: 'Rango de fechas', type: Type.between },
  // { name: 'day', title: 'Dia Exacto', type: Type.exact },
  { name: 'last_7_days', title: 'Últimos 7 días', type: Type.since, from: dayjs().subtract(7, 'days') },
  { name: 'last_30_days', title: 'Últimos 30 días', type: Type.since, from: dayjs().subtract(30, 'days') },
  { name: 'last_90_days', title: 'Últimos 90 días', type: Type.since, from: dayjs().subtract(90, 'days') },
  { name: 'last_6_months', title: 'Últimos 6 meses', type: Type.since, from: dayjs().subtract(6, 'months') },
  { name: 'last_12_months', title: 'Últimos 12 meses', type: Type.since, from: dayjs().subtract(12, 'months') },
].map(period => new Period(period))
