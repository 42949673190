import { Actions } from '../interfaces'

import documentType from './documentType'
import document from './document'
import intervener from './intervener'
import transferView from './transferView'
import field from './field'

export const Models = {
  ...documentType.Model,
  ...document.Model,
  ...intervener.Model,
  ...transferView.Model,
  ...field.Model,
}

export const actions: Record<string, Actions> = {
  ...documentType.actions,
  ...document.actions,
  ...intervener.actions,
  ...transferView.actions,
  ...field.actions,
}

export const schema = { Models, actions }
export default schema
