import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ClosingReasonFragment, ProcessFragment } from '../settings'
import { AppraisalFragment, EnablementFragment } from '../purchase'
import { EmployeeFragment } from '../hr'
import { PersonAddressFragment } from '../persons/personAddress'
import { DealFragment } from '@/graphql/fragments/crm'

export const BaseInspectionFragment = gql`
  fragment InspectionBase on purchase_inspection{
    ${EntityFields}
    scheduledDate: scheduled_date
    date
    inspectorQualification:inspector_qualification
    supervisorQualification:supervisor_qualification
    metadata
  }
`

export const InspectionFragment = gql`
  ${BaseInspectionFragment}
  ${ProcessFragment}
  ${EmployeeFragment}
  ${EnablementFragment}
  ${ClosingReasonFragment}
  ${PersonAddressFragment}
  ${AppraisalFragment}
  ${DealFragment}
  fragment Inspection on purchase_inspection{
    ...InspectionBase
    status {
      ...ProcessStatus
    }
    inspector {
      ...Employee
    }
    supervisor {
      ...Employee
    }
    closingReason: closing_reason {
      ...ClosingReason
    }
    address {
      ...PersonAddress
    }
    appraisal {
      ...Appraisal
      deal {
        ...Deal
        lead {
          id
        }
      }
    }
  }
`
