import { ActionTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Platforms } from '@/store/vehicles/platform/state'
import { allPlatforms } from '@/store/vehicles/queries/allPlatforms'

const actions: ActionTree<Platforms, RootState> = {
  loadPlatforms: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { platform },
    } = await apolloClient.query({
      query: allPlatforms,
    })

    await commit('setPlatforms', platform)
  },
}

export default actions
