import gql from 'graphql-tag'
import { BasePriceFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${BasePriceFragment}
  query fetchPrice($id: Int!) {
    records: sales_stock_by_pk(id: $id) {
      ...BasePrice
    }
  }
`

export default query
