import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ConditionTypeFragment } from '.'

export const BaseEvaluationConditionFragment = gql`
  fragment BaseEvaluationCondition on loans_evaluation_condition{
    ${EntityFields}
  }
`

export const EvaluationConditionFragment = gql`
  ${BaseEvaluationConditionFragment}
  ${ConditionTypeFragment}
  fragment EvaluationCondition on loans_evaluation_condition{
    ...BaseEvaluationCondition

    conditionType: condition_type {
      ...ConditionType
    }
  }
`
