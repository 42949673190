import { Stock } from '@/entities/sales/Stock'

export class StockAdvertiser extends Stock {
  get car () {
    const { auto } = this

    return auto?.car
  }

  get mercadolibre () {
    const { car } = this

    const post = car?.posts?.find(post => post.platform.name === 'MercadoLibre')

    return post?.url
  }

  get chileautos () {
    const { car } = this
    const post = car?.posts?.find(post => post.platform.name === 'ChileAutos')

    return post?.url
  }

  get yapo () {
    const { car } = this

    const post = car?.posts?.find(post => post.platform.name === 'YAPO')

    const url = post?.url
    if (!url?.length) return null

    return url
  }
}
