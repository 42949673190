import gql from 'graphql-tag'

export const insertNaturalPerson = gql`
  mutation insertNaturalPerson(
    $insertNaturalPerson: persons_person_insert_input!
  ) {
    insertNaturalPerson: insert_persons_person_one(
      object: $insertNaturalPerson
    ) {
      id
    }
  }
`

export const insertCompany = gql`
  mutation insertCompany($insertCompany: persons_person_insert_input!) {
    insertCompany: insert_persons_person_one(object: $insertCompany) {
      id
    }
  }
`

export const updateNaturalPerson = gql`
  mutation updateNaturalPerson($person: persons_person_set_input!, $id: Int!) {
    update_persons_person(where: { id: { _eq: $id } }, _set: $person) {
      affected_rows
    }
  }
`

export const updateMainPhoneNumber = gql`
  mutation updateMainPhoneNumber($id: Int!, $phone: String!) {
    update_persons_person(where: {id: {_eq: $id}}, _set: {main_phone: $phone}) {
      affected_rows
    }
  }
`
