import gql from 'graphql-tag'
import { AttributeFragment } from '@/graphql/fragments/vehicle/attribute'

export const query = gql`
  ${AttributeFragment}
  query attributes($filter: vehicle_attribute_bool_exp, $order: [vehicle_attribute_order_by!], $distinct: [vehicle_attribute_select_column!]) {
    records: vehicle_attribute(where: $filter, order_by: $order, distinct_on: $distinct){
      ...Attribute
      generation {
        id
      }
    }
  }
`
export default query
