import gql from 'graphql-tag'
import { DocumentTypeFragment } from '@/graphql/fragments/documents'

export const query = gql`
  ${DocumentTypeFragment}
  query findDocumentType($filter: documents_document_type_bool_exp) {
    records: documents_document_type(where: $filter, order_by: {id: desc}) {
      ...DocumentType
    }
  }
`
export default query
