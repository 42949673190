import { Actions } from '../../interfaces'
import { PersonType } from '@/entities/persons'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { PersonType },
  actions: { [PersonType.name]: actions },
}
