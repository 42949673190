import gql from 'graphql-tag'
import { PaymentFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${PaymentFragment}
  query findLitePayment($filter: finance_payment_bool_exp) {
    records: finance_payment(where: $filter) {
      id
    }
  }
`
export default query
