import gql from 'graphql-tag'
import { PaymentOrderFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${PaymentOrderFragment}
  query findPaymentOrder($filter: finance_payment_order_bool_exp) {
    records: finance_payment_order(where: $filter) {
      ...PaymentOrder
    }
  }
`
export default query
