import { Actions } from '../../interfaces'
import { Generation } from '@/entities/vehicle'

import find from './find'
import fetch from './fetch'
import update from './update'
import findLite from './findLite'

export const actions: Actions = {
  find,
  fetch,
  update,
  findLite,
}

export default {
  Model: { Generation },
  actions: { [Generation.name]: actions },
}
