import { Employee, Entity, Icon, Price } from '@/models/interfaces'

export interface Stock extends Entity {
  price: Price
  executive?: Employee
}

export enum StockStatus {
  reserved,
  reservationProcess,
  available,
  sold,
}

export const StockStatusIcons: Record<StockStatus, Icon> = {
  [StockStatus.reserved]: {
    name: 'Reservado',
    icon: 'mdi-rocket-launch',
    background: 'purple',
    tooltip: 'Reservado',
  },
  [StockStatus.reservationProcess]: {
    name: 'Reservado',
    icon: 'mdi-rocket-launch',
    background: 'purple',
    tooltip: 'Reservado',
  },
  [StockStatus.sold]: {
    name: 'Vendido',
    icon: 'mdi-rocket-launch',
    background: 'red',
    tooltip: 'Vendido',
  },
  [StockStatus.available]: {
    name: 'Disponible',
    icon: 'mdi-rocket-launch',
    background: 'green',
    tooltip: 'Disponible',
  },
}
