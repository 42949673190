import { Type } from 'class-transformer'
import { Entity } from '..'

import { Component, ComponentValue, Generation } from '.'

export class AutoAttribute extends Entity {
  @Type(() => Component)
  component: Component;

  @Type(() => ComponentValue)
  componentValue: ComponentValue;

  value?: string;

  declare _component: Component

  get base (): Component {
    const { _component } = this
    if (_component) return _component

    return this._component = this.component || this.componentValue.component
  }

  get name () {
    const { base } = this
    return base.name
  }

  get slug () {
    const { base } = this
    return base.slug
  }

  get val () {
    return this.value || this.componentValue.value
  }
}

export class Attribute extends AutoAttribute {
  @Type(() => Generation)
  generation: Generation;

  validated: boolean
}
