import gql from 'graphql-tag'
import { EntityFields } from '..'
import { BonusTypeFragment } from './bonusType'

export const BaseBonusFragment = gql`
  fragment BaseBonus on sales_bonus{
    ${EntityFields}
    amount
  }
`

export const BonusFragment = gql`
  ${BaseBonusFragment}
  ${BonusTypeFragment}
  fragment Bonus on sales_bonus{
    ...BaseBonus
    type {
      ...BonusType
    }
  }
`
