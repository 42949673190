import gql from 'graphql-tag'
import { PersonLaborDataFragment } from '@/graphql/fragments/persons'

export const mutation = gql`
  ${PersonLaborDataFragment}
  mutation updatePersonLaborData($id: Int!, $fields: persons_labor_data_set_input!) {
    record: update_persons_labor_data_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...PersonLaborData
    }
  }
`

export default mutation
