import gql from 'graphql-tag'

import { EntityFields } from '..'
import { AttributeFragment, AutoMotiveRegistrationFragment } from '.'
import { CountryFragment } from '../public'

export const BaseGenerationFragment = gql`
  fragment GenerationBase on vehicle_generation {
    ${EntityFields}
    sku
    idRotationIndex:id_rotation_index
  }
`

export const GenerationFragment = gql`
  ${BaseGenerationFragment}
  ${CountryFragment}
  ${AttributeFragment}
  ${AutoMotiveRegistrationFragment}
  fragment Generation on vehicle_generation {
    ...GenerationBase
    country {
      ...Country
    }
    attributes {
      ...Attribute
    }
    registration:automotive_registrations{
      ...AutoMotiveRegistration
    }
  }
`
