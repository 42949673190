import gql from 'graphql-tag'
import { PersonFragment } from '@/graphql/fragments/persons'
import { AddressFragment } from '@/graphql/fragments/persons/address'

export const query = gql`
  ${PersonFragment}
  ${AddressFragment}
  query findPerson($filter: persons_person_bool_exp!, $limit: Int = 6, $order: [persons_person_order_by!]) {
    records: persons_person(where: $filter, limit: $limit, order_by: $order){
      ...Person
      employee{
        id
      }
    }
  }
`
export default query
