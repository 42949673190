import gql from 'graphql-tag'

import { DealFragment, LeadFragment } from '@/graphql/fragments/crm'
import { AutoMotiveRegistrationFragment } from '@/graphql/fragments/vehicle'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'
import { FinancingFragment } from '@/graphql/fragments/loans'
import { BaseSaleOrderFragment, SaleOrderItemFragment, StockFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${LeadFragment}
  ${DealFragment}
  ${BaseAutoFragment}
  ${AutoMotiveRegistrationFragment}
  ${DealAutoAttributeFragment}
  ${FinancingFragment}
  ${BaseSaleOrderFragment}
  ${SaleOrderItemFragment}
  ${StockFragment}
  query fetchLiteLead($id: Int!) {
    records: crm_lead_by_pk(id: $id) {
      ...Lead
      deals {
        ...Deal
        auto {
          ...AutoBase
          version: version_year {
            ...VersionYear
          }
          generation {
            id
            registrations:automotive_registrations{
              ...AutoMotiveRegistration
            }
            sku
          }
          status: process_status {
            ...ProcessStatus
          }

        }
        autoAttributes: auto_attributes{
          ...DealAutoAttribute
        }
        saleOrders: sale_orders {
          ...BaseSaleOrder
          saleOrderItems: sale_order_items(order_by: {item_type: {id: asc}}) {
            ...SaleOrderItem
          }
          financings {
            ...Financing
          }
        }
        stock {
          ...Stock
        }
      }
    }
  }
`

export default query
