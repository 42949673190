import gql from 'graphql-tag'

import { OfferFragment } from '@/graphql/fragments/loans'

export const query = gql`
  ${OfferFragment}
  query findOffer($filter: loans_offer_bool_exp, $limit: Int = 6) {
    records: loans_offer(where: $filter, limit: $limit) {
      ...Offer
    }
  }
`

export default query
