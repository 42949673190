import { Type } from 'class-transformer'
import { Entity } from '..'
import { Person } from '@/entities/persons'
import { DeductibleType } from '@/entities/settings'
import { Offer } from '@/entities/loans'

export class SaleProduct extends Entity {
  @Type(() => Person)
  broker: Person

  @Type(() => Person)
  company: Person

  @Type(() => DeductibleType)
  deductible: DeductibleType

  @Type(() => Offer)
  offer: Offer

  cost: number;

  comment: string;
  name: string;
}
