import gql from 'graphql-tag'

import { SaleProductFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${SaleProductFragment}
  query findSaleProduct($filter: sales_sale_product_bool_exp) {
    records: sales_sale_product(where: $filter) {
      ...SaleProduct
    }
  }
`

export default query
