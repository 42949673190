import gql from 'graphql-tag'

export const getUtilityCredits = gql`
  query utilityCredits($aggregate: loans_offer_bool_exp) {
    records: loans_offer_aggregate(where: $aggregate) {
      aggregate {
        sum {
          fee
        }
      }
      nodes {
        id
        fee
        evaluation {
          id
          signedDate: signed_date
        }
      }
    }
  }
`

export const getLoanEvaluationsAggregates = gql`
  query loanEvaluationsAggregate($aggregate: loans_evaluation_view_bool_exp) {
    records: loans_evaluation_view_aggregate(where: $aggregate) {
      aggregate {
        count
      }
      nodes {
        createdAt: created_at
        signedDate: signed_date
        approvalDate: approval_date
        processStatus: proccess_status {
          id
          status {
            id
            name
          }
        }
        channel {
          id
          name
          description
          color
        }
        executive {
          id
          person {
            id
            firstName: first_name
            surname
          }
        }
        auto {
          registrationPlate: registration_plate
          versionYear: version_year {
            version {
              model {
                name
                brand {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export const getCreditsTarget = gql`
  {
    settings_process(where: {table_name: {_eq: "evaluation"}}) {
      config
    }
  }
`

export const getSignedLoans = gql`
  query signedLoans($model: persons_agreement_bool_exp, $aggregate: loans_evaluation_bool_exp, $order: [persons_agreement_order_by!]) {
    records: persons_agreement(where: $model, order_by: $order) {
      person {
        id
        alias
      }
      evaluations: evaluations_aggregate(where: $aggregate) {
        aggregate {
          count(distinct: true)
        }
      }
    }
  }
`

export const getLoanEvaluations = gql`
  query loanEvaluations($model: loans_evaluation_bool_exp) {
    loans_evaluation(where: $model) {
      id
      created_at
      status {
        id
        color
        status {
          id
          name
          description
        }
      }
      closing_reason {
        id
        type {
          id
          name
          description
          color
        }
      }
      financing {
        id
        sale_order {
          id
          deal {
            id
            id_closing_reason
            stock {
              id
              status {
                id
                status {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
