import { Module } from 'vuex'
import { getters, initState, Resources } from '@/store/settings/resources/state'
import { State as RootState } from '@/store/state'
import { mutations } from '@/store/settings/resources/mutations'
import { actions } from '@/store/settings/resources/actions'

const module: Module<Resources, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
}

export default module
