import gql from 'graphql-tag'

import { EntityFields } from '..'
import { ComponentCategoryFragment } from '.'
import { ComponentTypeFragment } from '../settings'

export const BaseComponentFragment = gql`
    fragment ComponentBase on vehicle_component {
        ${EntityFields}
        name
        required
        slug
        multiple
        position
        icon
        favorite
    }
`

export const ComponentFragment = gql`
    ${BaseComponentFragment}
    ${ComponentCategoryFragment}
    ${ComponentTypeFragment}
    fragment Component on vehicle_component {
        ...ComponentBase
        category {
            ...ComponentCategory
        }
        type {
            ...ComponentType
        }
        values{
            value
            id
        }
    }
`
