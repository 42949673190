import gql from 'graphql-tag'

import { EvaluationConditionFragment } from '@/graphql/fragments/loans'

export const mutation = gql`
  ${EvaluationConditionFragment}
  mutation updateEvaluationCondition($id: Int!, $fields: loans_evaluation_condition_set_input!) {
    record: update_loans_evaluation_condition_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...EvaluationCondition
    }
  }
`

export default mutation
