import gql from 'graphql-tag'

export const mutation = gql`
  mutation insertUser($fields: user_insert_input!) {
    record: insert_user_one(object: $fields) {
      id
    }
  }
`

export default mutation
