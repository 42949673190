import gql from 'graphql-tag'
import { DocumentFragment } from '@/graphql/fragments/documents'

export const query = gql`
  ${DocumentFragment}
  query findDocument($filter: documents_document_bool_exp) {
    records: documents_document(where: $filter, order_by: {id: desc}) {
      ...Document
    }
  }
`
export default query
