import gql from 'graphql-tag'
import { EnumFields } from '..'

// TODO: include [icon, color] when available
export const AddressTypeFragment = gql`
  fragment AddressType on persons_address_type {
    ${EnumFields}
    icon
    color
    personType:person_type
  }
`
