import gql from 'graphql-tag'
import { Employee } from '@/entities/hr'
import { QueryBuilder } from '../utils'
import { PersonFragment } from '@/graphql/fragments/persons'

const query = gql`
  ${PersonFragment}
  query employee($filter: hr_employee_bool_exp, $order: [hr_employee_order_by!], $limit: Int, $offset: Int) {
    records: hr_employee(order_by: $order, limit: $limit, offset: $offset, where: $filter) {
      id
      createdAt:created_at
      person{
        ...Person
        user {
          id
          email
          roles {
            id
            rol{
              id
              name
              description
            }
          }
        }
      }
    }
    total: hr_employee_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const employee = QueryBuilder(query, Employee)
export default employee
