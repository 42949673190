import { Type } from 'class-transformer'
import { Entity } from '..'

import { Lead } from '.'
import { Icon } from '../settings'

export class Pipeline extends Entity {
  @Type(() => Lead)
  leads: Lead[];

  @Type(() => Icon)
  icon?: Icon

  name: string;
  description: string;
  active: boolean;
  color?: string;

  get status () {
    return this
  }

  get title () {
    return this.description
  }

  toString () {
    return this.description
  }
}
