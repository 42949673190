import { Actions } from '../../interfaces'
import { Version } from '@/entities/vehicle'

import find from './find'
import update from './update'
import create from './create'

export const actions: Actions = {
  find,
  update,
  create,
}

export default {
  Model: { Version },
  actions: { [Version.name]: actions },
}
