import gql from 'graphql-tag'

import { EntityFields } from '..'
import { ComponentFragment } from '.'

export const BaseComponentValueFragment = gql`
  fragment ComponentValueBase on vehicle_component_value {
    ${EntityFields}
    value
    metadata
  }
`

export const ComponentValueFragment = gql`
  ${BaseComponentValueFragment}
  ${ComponentFragment}
  fragment ComponentValue on vehicle_component_value {
    ...ComponentValueBase
    component {
      ...Component
    }
  }
`
