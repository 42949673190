import VueRouter from 'vue-router'
import { Store } from 'vuex'
import { State } from '@/store/state'

import auth from './auth'

let router: VueRouter

const middlewares = (_router: VueRouter): void => {
  router = _router
}

middlewares.injectStore = (store: Store<State>): void => {
  auth(router, store)
}

export default middlewares
