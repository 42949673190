import gql from 'graphql-tag'
import { EntityFields } from '..'
import { InspectionParameterFragment } from '@/graphql/fragments/purchase/inspectionParameter'

export const BaseInspectionComponentFragment = gql`
  fragment BaseInspectionComponent on purchase_inspection_component{
    ${EntityFields}
    order
    config
  }
`

export const InspectionComponentFragment = gql`
  ${BaseInspectionComponentFragment}
  ${InspectionParameterFragment}
  fragment InspectionComponent on purchase_inspection_component{
    ...BaseInspectionComponent
    inspectionParameters:inspection_parameters{
      ...InspectionParameter
    }
  }
`
