import gql from 'graphql-tag'

export const query = gql`
  query findAppraisalDetail($filter: purchase_appraisal_detail_bool_exp) {
    records: purchase_appraisal_detail(where: $filter) {
      id
      rotationIndex: rotation_index
      averageMileage: average_mileage
      link
      vehicleNumber: vehicle_number
      minimumPrice: minimum_price
      medianPrice: median_price
      maximumPrice: maximum_price
      zeroMileagePrice: zero_mileage_price
      previousYearPrice: previous_year_price
      currentYearPrice: current_year_price
      nextYearPrice: next_year_price
      estimatedPublicationPrice: estimated_publication_price
      residualPercentage: residual_percentage
      averageMileageConfig: average_mileage_config
      initialAppraisal: initial_appraisal
      bruteInitialAppraisal: brute_initial_appraisal
      metadata
    }
  }
`

export default query
