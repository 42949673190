import gql from 'graphql-tag'
import { EntityFields } from '..'
import { VariantFragment } from '@/graphql/fragments/settings'
import { QuotaFragment } from '@/graphql/fragments/loans/quota'
import { OfferProductFragment } from '@/graphql/fragments/loans/offerProduct'

export const BaseOfferFragment = gql`
  fragment BaseOffer on loans_offer{
    ${EntityFields}
    fee
    coverage
    rate
    startPayment:start_payment
    accepted
  }
`

export const OfferFragment = gql`
  ${BaseOfferFragment}
  ${VariantFragment}
  ${QuotaFragment}
  ${OfferProductFragment}
  fragment Offer on loans_offer{
    ...BaseOffer
    variant{
      ...Variant
    }
    quotas{
      ...Quota
    }
    products{
      ...OfferProduct
    }
  }
`
