const vuetify = {
  $vuetify: {
    dataTable: {
      itemsPerPageText: 'Filas por página:',
      ariaLabel: {
        sortDescending: 'Orden descendente.',
        sortAscending: 'Orden ascendente.',
        sortNone: 'Sin ordenar.',
        activateNone: 'Pulse para quitar orden.',
        activateDescending: 'Pulse para ordenar descendente.',
        activateAscending: 'Pulse para ordenar ascendente.',
      },
      sortBy: 'Ordenado por',
    },
    dataFooter: {
      itemsPerPageText: 'Filas por página:',
      itemsPerPageAll: 'Todos',
      nextPage: 'Página siguiente',
      prevPage: 'Página anterior',
      firstPage: 'Primer página',
      lastPage: 'Última página',
      pageText: '{0}-{1} de {2}',
    },
    pagination: {
      ariaLabel: {
        previous: 'Anterior',
        next: 'Siguiente',
        wrapper: 'Envoltorio',
        currentPage: 'Pagina Actual',
        page: 'Pagina',
      },
    },
  },
}

export default vuetify
