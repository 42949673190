import gql from 'graphql-tag'

export const getFinancialReports = gql`
  query appraisal {
    getAppraisalFinancialReport: get_appraisal_financial_report
  }
`

export const getInspectionReports = gql`
  query inspections {
    getInspectionFinancialReport: get_inspection_financial_report(statuses: ["pending"])
  }
`

export const getReserveReports = gql`
  query reserve {
    getReserveFinancialReport: get_reserve_financial_report
  }
`

export const getSalesReports = gql`
  query sales {
    getSalesFinancialReport: get_sales_financial_report
  }
`

export const getCreditsReports = gql`
  query credits {
    getFinancingFinancialReport: get_financing_financial_report
  }
`

export const getInspections = gql`
  query inspections($start_date:String!, $end_date:String!) {
    records: get_inspection_status_report_by_date(start_date: $start_date, end_date: $end_date)
  }
`
export const getPurchasesReport = gql`
  query purchases{
    unvalidated: get_inspection_active_unvalidated_financial_report
    negotiation: get_negotiation_financial_report
    process: get_purchase_financial_report
  }
`

export const getDeliveryStatus = gql`
  query get_sales_handing_report_by_date($start_date:String!, $end_date:String!){
    getSalesHandingReportByDate: get_sales_handing_report_by_date(start_date: $start_date, end_date: $end_date)
  }
`
