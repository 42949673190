import gql from 'graphql-tag'
import { RolFragment } from '@/graphql/fragments/public'

export const query = gql`
  ${RolFragment}
  query findRol($filter: rol_bool_exp!,$order: [rol_order_by!]) {
    records: rol(where: $filter,order_by: $order){
      ...Rol
    }
  }
`
export default query
