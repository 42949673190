import gql from 'graphql-tag'
import { StockFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${StockFragment}
  query findLiteStock($filter: sales_stock_bool_exp, $order: [sales_stock_order_by!], $limit: Int) {
    records: sales_stock(where: $filter, order_by: $order, limit: $limit) {
      ...Stock
    }
  }
`
export default query
