import gql from 'graphql-tag'
import { EnablementFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${EnablementFragment}
  mutation updateEnablement($id: Int!, $fields: purchase_enablement_set_input!) {
    record: update_purchase_enablement_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...Enablement
    }
  }
`

export default mutation
