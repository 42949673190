import gql from 'graphql-tag'
import { QueryBuilder } from '../utils'

import { Appraisal } from '@/entities/purchase'
import { AppraisalFragment, BaseInspectionFragment } from '@/graphql/fragments/purchase'
import { DealFragment, LeadFragment, PipelineFragment } from '@/graphql/fragments/crm'
import { BaseStockFragment, PriceFragment } from '@/graphql/fragments/sales'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import { PersonFragment } from '@/graphql/fragments/persons'
import { ProcessFragment } from '@/graphql/fragments/settings'

const query = gql`
  ${AppraisalFragment}
  ${DealFragment}
  ${LeadFragment}
  ${BaseInspectionFragment}
  ${BaseStockFragment}
  ${PriceFragment}
  ${ProcessFragment}
  ${BaseAutoFragment}
  ${PipelineFragment}
  ${PersonFragment}
  query appraisalExecutiveLeads($filter: purchase_appraisal_bool_exp, $order: [purchase_appraisal_order_by!], $limit: Int, $offset: Int) {
    records: purchase_appraisal(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      ...Appraisal
      detail {
        id
      }
      responsesAggregate:responses_aggregate(where: {type: {_eq: appraised}}) {
        aggregate {
          count
        }
      }
      deal {
        id
        auto{
          ...AutoBase
          version: version_year {
            ...VersionYear
          }
          status: process_status {
            ...ProcessStatus
          }
          deals{
            id
            type: deal_type{
              name
            }
            appraisalsAggregate: appraisals_aggregate {
              aggregate {
                count
              }
            }
          }
        }
        lead {
          id
          pipeline {
            ...Pipeline
          }
          client {
            ...Person
          }
          deals(where: {type: {_eq: sale}}){
            id
          }
          executive{
            id
            person{
              ...Person
            }
          }
        }
      }
      inspection {
        ...InspectionBase
        status {
          ...ProcessStatus
        }
      }
    }
    total: purchase_appraisal_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const appraisals = QueryBuilder(query, Appraisal)
export default appraisals
