import gql from 'graphql-tag'

export const relationsShips = gql`
  query relationShips {
    relationships: persons_general_relation {
      name
      description
    }
  }
`

export const currentReferences = gql`
  query currentReferences($id: Int!) {
    references: persons_reference_person(where: { id_person: { _eq: $id } }) {
      active
      person {
        phone: main_phone
        id
      }
    }
  }
`

export const isReferenceRequired = gql`
  subscription isReferenceRequired($id: Int!) {
    isReference: persons_reference_person(where: { id_person: { _eq: $id } }) {
      active
      id
    }
  }
`
