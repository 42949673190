import { Type } from 'class-transformer'
import { Entity } from '..'

import { Person } from './Person'
import { ResponsibleAgreement } from './ResponsibleAgreement'
import { AgreementType, Product } from '../settings'
import { Evaluation } from '../loans'

export class Agreement extends Entity {
  @Type(() => Person)
  person: Person;

  @Type(() => AgreementType)
  type: AgreementType;

  @Type(() => ResponsibleAgreement)
  responsibles: ResponsibleAgreement[];

  @Type(() => Product)
  products: Product[];

  @Type(() => Evaluation)
  evaluations: Evaluation[];

  priority?: number;
  active: boolean;

  get name () {
    const { person } = this
    if (!person) return ''
    const { alias } = person
    return alias
  }
}
