import { Type } from 'class-transformer'
import { Entity } from '..'

import { Auto, AutoOwner } from '.'
import { Deal } from '../crm'

export class Ownership extends Entity {
  @Type(() => Auto)
  auto: Auto

  @Type(() => Deal)
  deals: Deal[]

  @Type(() => AutoOwner)
  owners: AutoOwner[]
}
