import { Type } from 'class-transformer'
import { Entity } from '..'

import { VersionYear } from './VersionYear'

export class ReferenceAuto extends Entity {
  @Type(() => VersionYear)

  ppu!: string

  checkDigit!: string
  brand!: string
  model!: string
  autoType!: string
  year!: string
}
