import gql from 'graphql-tag'

export const getBrands = gql`
  subscription brands($year: Int!) {
    brands: vehicle_brand(
      where: {
        models: { versions: { version_years: { id_year: { _eq: $year } } } }
      }
    ) {
      id
      name
    }
  }
`

export const getModels = gql`
  subscription models($brand: Int!, $year: Int) {
    models: vehicle_model(
      where: {
        id_brand: { _eq: $brand }
        _and: { versions: { version_years: { id_year: { _eq: $year } } } }
      }
    ) {
      id
      name
    }
  }
`

export const getYears = gql`
  query years {
    years: vehicle_year(order_by: { id: desc }) {
      id
    }
  }
`

export const getVersions = gql`
  subscription versions($model: Int!, $year: Int) {
    versions: vehicle_version(
      where: {
        id_model: { _eq: $model }
        _and: { version_years: { id_year: { _eq: $year } } }
      }
    ) {
      id
      name
    }
  }
`

export const getVersionYear = gql`
  query getVersionYear($year: Int!, $version: Int!) {
    versionYear: vehicle_version_year(
      where: { id_year: { _eq: $year }, version: { id: { _eq: $version } } }
    ) {
      id
    }
  }
`

export const getAttributesWithOutSku = gql`
  query getAttributes($versionYear: Int!) {
    attributes: vehicle_attribute(
      where: { version_year: { id: { _eq: $versionYear } } }
      order_by: { component: { position: asc } }
    ) {
      component {
        name
      }
      component_value {
        value
      }
      value
      country {
        name
      }
    }
  }
`

export const getSkus = gql`
  query getSkus($versionYear: Int!) {
    skus: vehicle_attribute(
      where: { version_year: { id: { _eq: $versionYear } } }
      distinct_on: sku
    ) {
      name: sku
      id
    }
  }
`

export const getAttributesWithSku = gql`
  query getAttributesWithSku($sku: String!, $versionYear: Int!) {
    attributes: vehicle_attribute(
      where: {
        sku: { _eq: $sku }
        _and: { id_version_year: { _eq: $versionYear } }
      }
      order_by: { component: { position: asc } }
    ) {
      component_value {
        value
      }
      component {
        name
      }
      value
    }
  }
`
