import { Entity, Stock } from '@/models'
import { Price } from '@/models/interfaces'
import {
  Brand as IBrand,
  Model as IModel,
  Version as IVersion,
  Vehicle as IVehicle,
  VehicleStatus,
} from './interfaces'

class Brand extends Entity implements IBrand {
  name: string;

  constructor (brand: IBrand) {
    super(brand)
    const { name } = brand

    this.name = name
  }

  get title () {
    const { name } = this
    return `${name}`
  }
}

class Model extends Entity implements IModel {
  brand: Brand;
  name: string;

  constructor (model: IModel) {
    super(model)
    const { name, brand } = model

    this.name = name
    this.brand = new Brand(brand)
  }

  get title () {
    const { brand, name } = this
    return `${brand.title} ${name}`
  }
}

class Version extends Entity implements IVersion {
  year: number;
  name: string;
  model: Model;

  constructor (version: IVersion) {
    super(version)
    const { year, name, model } = version

    this.year = year
    this.name = name
    this.model = new Model(model)
  }

  get title () {
    const { model, name, year } = this
    return `${model.title} ${name} ${year}`
  }
}

// TODO: Define which status are part of the stock
const stockStatus = [
  VehicleStatus.available,
  VehicleStatus.selling,
  VehicleStatus.reserved,
  VehicleStatus.sold,
]

const availableStatus = [
  VehicleStatus.enabling,
  VehicleStatus.available,
]

export class Vehicle extends Entity implements IVehicle {
  ppu: string;
  version: Version;
  stock?: Stock;
  status: VehicleStatus;

  constructor (vehicle: IVehicle) {
    super(vehicle)
    const { ppu, version, stock, status } = vehicle

    this.ppu = ppu
    this.version = new Version(version)
    this.status = status

    if (this.isStock) {
      if (!stock) throw new Error('Stock is required')
      this.stock = new Stock(stock)
    }
  }

  get isStock () {
    const { status } = this
    return stockStatus.includes(status)
  }

  get isAvailable () {
    const { status } = this
    return availableStatus.includes(status)
  }

  get name () {
    return this.version.title
  }

  get price (): Price | undefined {
    return this.stock?.price
  }

  get date () {
    // TODO: Return date from reserved/selling/sold
    const date = undefined
    return this.formatDate(date, 'DD/MM/YYYY')
  }

  toString () {
    return this.name
  }
}
