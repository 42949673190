import { Actions } from '../../interfaces'
import { MileagePrice } from '@/entities/vehicle'

import create from './create'
import update from './update'
import find from './find'

export const actions: Actions = {
  create,
  update,
  find,
}

export default {
  Model: { MileagePrice },
  actions: { [MileagePrice.name]: actions },
}
