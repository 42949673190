import { Actions } from '../../interfaces'
import { AppraisalDetail } from '@/entities/purchase'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { AppraisalDetail },
  actions: { [AppraisalDetail.name]: actions },
}
