import gql from 'graphql-tag'
import { PersonFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${PersonFragment}
  query findTransferView($filter: documents_transfer_view_bool_exp) {
    records: documents_transfer_view(where: $filter) {
      documentId:document_id
      document {
        id
        interveners{
          id
          person{
            ...Person
          }
          field{
            id
            name
          }
        }
      }
    }
  }
`
export default query
