import { Type } from 'class-transformer'
import { Entity } from '..'

import { AgreementType } from './AgreementType'
import { Product } from './Product'

export class Service extends Entity {
  @Type(() => AgreementType)
  agreementTypes: AgreementType[];

  @Type(() => Product)
  products: Product[];

  name: string;

  description: string;
}
