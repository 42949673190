import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/sysop_users',
    name: 'sysop-user',
    redirect: { name: 'sysop-users' },
    component: () => import('@/layouts/app/Index.vue'),
    children: [
      {
        path: 'sysop-users',
        name: 'sysop-users',
        meta: { inherit: true },
        component: () => import('./SysopUsers.vue'),
      },
    ],
  },
]

export default routes
