import { Actions } from '../../interfaces'
import { Alias } from '@/entities/public'

import fetch from './fetch'
import create from './create'
import update from './update'
import find from './find'
import remove from './remove'

export const actions: Actions = {
  fetch,
  find,
  create,
  update,
  remove,
}

export default {
  Model: { Alias },
  actions: { [Alias.name]: actions },
}
