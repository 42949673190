import gql from 'graphql-tag'
import { BrandFragment } from '@/graphql/fragments/vehicle'

export const query = gql`
  ${BrandFragment}
  query brand($filter: vehicle_brand_bool_exp, $order: [vehicle_brand_order_by!]) {
    records: vehicle_brand(where: $filter, order_by: $order){
      ...Brand
    }
  }
`
export default query
