import { Actions } from '../../interfaces'

import { DailyAccountBalance } from '@/entities/finance'
import create from './create'
import fetch from './fetch'
import find from './find'
import update from './update'

export const actions: Actions = {
  create,
  fetch,
  find,
  update,
}

export default {
  Model: { DailyAccountBalance },
  actions: { [DailyAccountBalance.name]: actions },
}
