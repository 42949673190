import { Type } from 'class-transformer'
import { Entity } from '..'
import { TrafficTicket } from '../public'

export class InfractionType extends Entity {
  @Type(() => TrafficTicket)
  trafficTickets: TrafficTicket[];

  name: string;
  description: string;
}
