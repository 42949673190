import gql from 'graphql-tag'
import { EntityFields } from '..'
import { BankDataFragment } from '@/graphql/fragments/persons'

export const DailyAccountBalanceFragment = gql`
    ${BankDataFragment}
    fragment DailyAccountBalance on finance_daily_account_balance{
        ${EntityFields}
        date
        accountBalance:account_balance
        availableBalance:available_balance
        usedCreditLine:used_credit_line
        creditAmount:credit_amount
        dailyVariation:daily_variation
        creditVariation:credit_variation
        previousRegistrationDate:previous_registration_date
        bankAccount:bank_account{
            ...BankData
        }
    }
`
