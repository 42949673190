import gql from 'graphql-tag'
import { EntityFields } from '..'
import { RequirementTypeFragment } from '@/graphql/fragments/loans/requirementType'

export const BaseEvaluationRequirementFragment = gql`
  fragment BaseEvaluationRequirement on loans_evaluation_requirement{
    ${EntityFields}
  }
`

export const EvaluationRequirementFragment = gql`
  ${BaseEvaluationRequirementFragment}
  ${RequirementTypeFragment}
  fragment EvaluationRequirement on loans_evaluation_requirement{
    ...BaseEvaluationRequirement

    requirementType: requirement_type {
      ...RequirementType
    }
  }
`
