export interface AggregatePath {
  path: string
  child?: AggregatePath
}

export interface Entity {
  id: number
  createdAt?: string
  updatedAt?: string
  Aggregate?: AggregatePath
}

export enum PriceUpdate {
  none,
  up,
  down
}

export interface Price {
  min: number
  max: number
  updated: PriceUpdate
}

export interface Icon {
  name?: string
  icon: string
  color?: string
  colorHexadecimal?: string
  priority?: number
  order?: number
  rotate?: boolean
  background?: string
  tooltip?: string
}

export * from './user/interfaces'
export * from './stock/interfaces'
export * from './vehicle/interfaces'
export * from './appraisal/interfaces'
export * from './financing/interfaces'
export * from './deal/interfaces'
export * from './lead/interfaces'
