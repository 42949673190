const files = require.context('.', true, /\.ts$/)
const translations = {}

files.keys().forEach(fileName => {
  if (fileName === './index.ts') return
  const name = fileName.replace(/(\.\/|\.ts)/g, '')
  translations[name] = files(fileName).default
})

export default translations
