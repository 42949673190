import gql from 'graphql-tag'

import { FileParameterFragment } from '@/graphql/fragments/files'

export const query = gql`
  ${FileParameterFragment}
  query fetchFileParameter($id: Int!) {
    records: files_file_parameter_by_pk(id: $id) {
      ...FileParameter
    }
  }
`

export default query
