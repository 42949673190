import gql from 'graphql-tag'

export const getDealByChannelAggregate = gql`
  query channel($model: crm_channel_bool_exp, $aggregate: crm_deal_bool_exp, $order: [crm_channel_order_by!]) {
    aggregates: crm_channel(where: $model, order_by: $order) {
      id
      name
      description
      deals: deals_aggregate(where: $aggregate) {
        aggregate {
          count(distinct: true)
        }
      }
    }
  }
`
