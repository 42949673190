import gql from 'graphql-tag'
import { Document } from '@/entities/documents'
import { QueryBuilder } from '../utils'
import { ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { AutoFragment } from '@/graphql/fragments/public'
import { EmployeeFragment } from '@/graphql/fragments/hr'
import { StockFragment } from '@/graphql/fragments/sales'
import { PersonFragment } from '@/graphql/fragments/persons'

const query = gql`
  ${ProcessStatusFragment}
  ${AutoFragment}
  ${EmployeeFragment}
  ${ClosingReasonFragment}
  ${StockFragment}
  ${PersonFragment}
  query document($filter: documents_document_bool_exp, $order: [documents_document_order_by!], $limit: Int, $offset: Int) {
    records: documents_document(order_by: $order, limit: $limit, offset: $offset, where: $filter) {
      id
      closingReason: closing_reason {
        ...ClosingReason
      }
      status: process_status {
        ...ProcessStatus
      }
      saleOrders: sale_orders {
        id
        deal{
          id
          auto {
            ...Auto
          }
          stock{
            ...Stock
          }
          lead{
            id
            executive{
              ...Employee
              person{
                ...Person
              }
            }
          }
        }
      }
    }
    total: documents_document_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const document = QueryBuilder(query, Document)
export default document
