import gql from 'graphql-tag'

import { InspectionComponentFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${InspectionComponentFragment}
  mutation updateInspectionComponent($id: Int!,$fields: purchase_inspection_component_set_input!) {
    record: update_purchase_inspection_component_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...InspectionComponent
    }
  }
`

export default mutation
