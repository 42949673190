import gql from 'graphql-tag'

import { InspectionFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${InspectionFragment}
  query findInspection($filter: purchase_inspection_bool_exp,$order: [purchase_inspection_order_by!]){
    records: purchase_inspection(where: $filter, order_by: $order){
      ...Inspection
    }
  }
`

export default query
