import gql from 'graphql-tag'

import { FinancedItemFragment } from '@/graphql/fragments/loans/financedItem'

export const query = gql`
  ${FinancedItemFragment}
  query findFinancedItem($filter: loans_financed_item_bool_exp, $limit: Int) {
    records: loans_financed_item(where: $filter, limit: $limit) {
      ...FinancedItem
    }
  }
`

export default query
