export interface IRole {
  id: number
  name: string
  icon: string
  slug: string
  description: string
}

export class Role implements IRole {
  id: number
  name: string
  icon: string
  slug: string
  description: string

  constructor ({ id, name, icon, slug, description }: IRole) {
    this.id = id
    this.name = name
    this.icon = icon
    this.slug = slug
    this.description = description
  }
}
