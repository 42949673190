import gql from 'graphql-tag'

import { ProcessTrafficTicketFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${ProcessTrafficTicketFragment}
  query findProcessTrafficTicket($filter: purchase_process_traffic_ticket_bool_exp){
    records: purchase_process_traffic_ticket(where: $filter) {
      ...ProcessTrafficTicket
    }
  }
`

export default query
