import { EmployeeFragment } from '@/graphql/fragments/hr'
import gql from 'graphql-tag'
import { Fragment } from './fragment'

export const query = gql`
  ${Fragment}
  ${EmployeeFragment}
  query fetchAppraisal($id: Int!) {
    records: purchase_appraisal_by_pk(id: $id) {
      ...AppraisalForm
      appraiser {
        ...Employee
      }
      inspection{
        id
      }
    }
  }
`

export default query
