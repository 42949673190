import gql from 'graphql-tag'
import { EmployeeFragment } from '@/graphql/fragments/hr'

export const query = gql`
  ${EmployeeFragment}
  query findEmployee($filter: hr_employee_bool_exp, $limit: Int,$order: [hr_employee_order_by!]) {
    records: hr_employee(where: $filter, limit: $limit,order_by: $order)  {
      ...Employee
    }
  }
`
export default query
