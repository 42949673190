import gql from 'graphql-tag'

import { InspectionFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${InspectionFragment}
  mutation insertInspection($fields: purchase_inspection_insert_input!) {
    record: insert_purchase_inspection_one(object: $fields) {
      ...Inspection
    }
  }
`

export default mutation
