import gql from 'graphql-tag'
import { EntityFields } from '..'
import { PersonFragment } from '../persons'

export const BaseAutoOwnerFragment = gql`
    fragment AutoOwnerBase on auto_owner{
        ${EntityFields}
    }
`

export const AutoOwnerFragment = gql`
    ${BaseAutoOwnerFragment}
    ${PersonFragment}
    fragment AutoOwner on auto_owner {
        ...AutoOwnerBase
        person {
            ...Person
        }
    }
`
