import gql from 'graphql-tag'
import { StockAdvertiser } from '@/entities/sales'
import { QueryBuilder } from '../utils'
import {
  BaseStockFragment,
  KeyIdentifierFragment,
  PriceFragment,
  StockViewDetailFragment,
} from '@/graphql/fragments/sales'
import {
  BaseAttributeFragment,
  BaseComponentFragment,
  BaseComponentValueFragment, BaseVersionYearFragment, VersionFragment,
  YearFragment,
} from '@/graphql/fragments/vehicle'
import { ProcessStatusFragment } from '@/graphql/fragments/settings'
import { PipelineFragment } from '@/graphql/fragments/crm'
import { AutoFragment } from '@/graphql/fragments/public'

const query = gql`
  ${BaseStockFragment}
  ${VersionFragment}
  ${BaseVersionYearFragment}
  ${BaseAttributeFragment}
  ${BaseComponentFragment}
  ${BaseComponentValueFragment}
  ${YearFragment}
  ${PriceFragment}
  ${ProcessStatusFragment}
  ${KeyIdentifierFragment}
  ${PipelineFragment}
  ${StockViewDetailFragment}
  ${AutoFragment}
  query stock($filter: sales_stock_bool_exp, $order: [sales_stock_order_by!], $limit: Int, $offset: Int) {
    records: sales_stock(order_by: $order, limit: $limit, offset: $offset, where: $filter) {
      ...StockBase
      viewDetails: stock_detail {
        ...StockViewDetail
        auto{
          ...Auto
          car {
            ppu
            posts(where: {status: {_eq: "PUBLIC"}}) {
              id
              url
              platform {
                id
                name
              }
              status
            }
          }
          generation {
            id
            registration:automotive_registrations{
              permission
            }
            attributes(where: {component: {id: {_lte: 4}}}) {
              component {
                id
                slug
              }
              componentValue: component_value {
                id
                value
              }
              value
            }
          }
        }
        attributes {
          component {
            id
            slug
          }
          componentValue: component_value {
            id
            value
          }
          value
        }
      }
      prices(order_by: {id: desc}, limit: 2) {
        ...Price
      }
      status {
        ...ProcessStatus
      }
      keyIdentifier: key_identifier{
        ...KeyIdentifier
      }
    }
    total: sales_stock_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const stockAdvertiser = QueryBuilder(query, StockAdvertiser)
export default stockAdvertiser
