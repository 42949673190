import gql from 'graphql-tag'

import { FinancedItemFragment } from '@/graphql/fragments/loans'

export const mutation = gql`
  ${FinancedItemFragment}
  mutation insertFinancedItem($fields: loans_financed_item_insert_input!) {
    record: insert_loans_financed_item_one(object: $fields) {
      ...FinancedItem
    }
  }
`

export default mutation
