import { Type } from 'class-transformer'
import { Entity } from '..'

import { LeadActivity, Deal } from '.'

export class DealNote extends Entity {
  @Type(() => LeadActivity)
  activity: LeadActivity;

  @Type(() => Deal)
  deal: Deal;

  note: string;
}
