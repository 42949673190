import gql from 'graphql-tag'
import { SaleOrderFragment, StockFragment } from '@/graphql/fragments/sales'
import { FinancingFragment } from '@/graphql/fragments/loans'
import { PersonFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${SaleOrderFragment}
  ${FinancingFragment}
  ${StockFragment}
  ${PersonFragment}
  query findSaleOrder($filter: sales_sale_order_bool_exp, $order: [sales_sale_order_order_by!], $limit: Int) {
    records: sales_sale_order(where: $filter, order_by: $order, limit: $limit) {
      ...SaleOrder
      deal{
        id
        auto{
          id
        }
        stock {
          ...Stock
          transfer{
            documentId:document_id
            document {
              id
              support {
                id
                name
              }
              interveners{
                id
                person{
                  ...Person
                }
              }
            }
          }
        }
        lead{
          id
        }
      }
      financings(order_by: {id: desc}) {
        ...Financing
      }
    }
  }
`
export default query
