import gql from 'graphql-tag'
import { EntityFields } from '..'
import { SaleOrderItemFragment } from '@/graphql/fragments/sales'

export const BaseFinancedItemFragment = gql`
  fragment BaseFinancedItem on loans_financed_item{
    ${EntityFields}
  }
`

export const FinancedItemFragment = gql`
  ${BaseFinancedItemFragment}
  ${SaleOrderItemFragment}
  fragment FinancedItem on loans_financed_item{
    ...BaseFinancedItem
    saleOrderItem:sale_order_item {
      ...SaleOrderItem
    }
  }
`
