import gql from 'graphql-tag'
import { EntityFields } from '..'

export const ExchangeRateFragmentBase = gql`
  fragment ExchangeRateBase on settings_exchange_rate{
    ${EntityFields}
    rate
  }
`

export const ExchangeRateFragment = gql`
  ${ExchangeRateFragmentBase}
  fragment ExchangeRate on settings_exchange_rate{
    ...ExchangeRateBase
  }
`
