import gql from 'graphql-tag'
import { PaymentRecipientFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${PaymentRecipientFragment}
  query findPaymentRecipient($filter: finance_payment_recipient_bool_exp) {
    records: finance_payment_recipient(where: $filter) {
      ...PaymentRecipient
    }
  }
`
export default query
