import gql from 'graphql-tag'
import { EntityFields } from '..'

import { BusinessApproachFragment } from './businessApproach'
import { AgreementProductFragment } from './agreementProduct'

export const BaseProductApproachFragment = gql`
  fragment BaseProductApproach on settings_product_approach {
    ${EntityFields}
  }
`

export const ProductApproachFragment = gql`
  ${BaseProductApproachFragment}
  ${BusinessApproachFragment}
  ${AgreementProductFragment}
  fragment ProductApproach on settings_product_approach {
    ...BaseProductApproach
    businessApproach: business_approach {
      ...BusinessApproach
    }
    agreementProduct: agreement_product {
      ...AgreementProduct
    }
  }
`
