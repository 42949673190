import gql from 'graphql-tag'
import { EntityFields } from '..'
import { AgreementProductFragment } from '@/graphql/fragments/settings'

export const BaseOfferProductFragment = gql`
  fragment BaseOfferProduct on loans_offer_product{
    ${EntityFields}
  }
`

export const OfferProductFragment = gql`
  ${BaseOfferProductFragment}
  ${AgreementProductFragment}
  fragment OfferProduct on loans_offer_product{
    ...BaseOfferProduct
    agreementProduct:agreement_product {
      ...AgreementProduct
    }
  }
`
