import gql from 'graphql-tag'

import { EntityFields } from '..'
import { AddressTypeFragment } from './addressType'
import { AddressFragment } from './address'

export const BasePersonAddressFragment = gql`
  fragment PersonAddressBase on persons_person_address {
    ${EntityFields}
    active
    default
  }
`

export const PersonAddressFragment = gql`
  ${BasePersonAddressFragment}
  ${AddressTypeFragment}
  ${AddressFragment}
  fragment PersonAddress on persons_person_address{
    ...PersonAddressBase
    type {
      ...AddressType
    }
    address {
      ...Address
    }
  }
`
