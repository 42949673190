import gql from 'graphql-tag'
import { AppraisalFragment } from '@/graphql/fragments/purchase'
import { DealFragment } from '@/graphql/fragments/crm'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'

export const query = gql`
  ${AppraisalFragment}
  ${DealFragment}
  ${DealAutoAttributeFragment}
  query findAppraisal($filter: purchase_appraisal_bool_exp) {
    records: purchase_appraisal(where: $filter) {
      ...Appraisal
      deal{
        id
        lead{
          id
          executive{
            id
            person{
              ...Person
            }
          }
        }
        autoAttributes:auto_attributes{
          ...DealAutoAttribute
        }
      }
    }
  }
`

export default query
