import gql from 'graphql-tag'
import { Fragment } from './fragment'
import { BasePersonAccountFragment } from '@/graphql/fragments/persons/personAccount'
import { BaseBankDataFragment } from '@/graphql/fragments/persons/bankData'
import { AccountTypeFragment } from '@/graphql/fragments/persons/accountType'
import { PersonAddressFragment } from '@/graphql/fragments/persons/personAddress'
import { PersonLaborDataFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${Fragment}
  ${BasePersonAccountFragment}
  ${BaseBankDataFragment}
  ${AccountTypeFragment}
  ${PersonAddressFragment}
  ${PersonLaborDataFragment}
  query fetchPerson($id: Int!) {
    records: persons_person_by_pk(id: $id) {
      ...PersonForm
      accounts(where: {active: {_eq:true}}){
        ...BasePersonAccount
        holder{
          ...PersonForm
        }
        bankData:bank_data {
          ...BaseBankData
          accountType:account_type {
            ...AccountType
          }
          bank {
            ...PersonForm
          }
          holders(where: {active: {_eq:true}}){
            ...BasePersonAccount
            holder{
              ...PersonForm
            }
          }
        }
      }
      laborData:labor_data(where: {active: {_eq:true}}){
        ...PersonLaborData
      }
      addresses(where: {active: {_eq:true}}){
        ...PersonAddress
      }
    }
  }
`

export default query
