import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ComponentFragment, ComponentValueFragment } from '@/graphql/fragments/vehicle'
import { ProcessFragment } from '@/graphql/fragments/settings'

export const BaseDealAutoAttributeFragment = gql`
  fragment BaseDealAutoAttribute on deal_auto_attribute {
    ${EntityFields}
     value
     record: id_process_record
  }
`

export const DealAutoAttributeFragment = gql`
  ${BaseDealAutoAttributeFragment}
  ${ComponentFragment}
  ${ComponentValueFragment}
  ${ProcessFragment}
  fragment DealAutoAttribute on deal_auto_attribute {
    ...BaseDealAutoAttribute
    component {
      ...Component
    }
    componentValue: component_value {
      ...ComponentValue
    }
    process {
      ...Process
    }
  }
`
