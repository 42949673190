import gql from 'graphql-tag'
import { PaymentFragment } from '@/graphql/fragments/finance'

export const mutation = gql`
  ${PaymentFragment}
  mutation updatePayment($id: Int!, $fields: finance_payment_set_input!) {
    record: update_finance_payment_by_pk(pk_columns: {id: $id}, _set: $fields) {
      id
    }
  }
`

export default mutation
