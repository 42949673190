import gql from 'graphql-tag'

import { SaleOrderFragment, StockFragment } from '@/graphql/fragments/sales'
import { FinancingFragment } from '@/graphql/fragments/loans'
import { PersonFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${SaleOrderFragment}
  ${FinancingFragment}
  ${StockFragment}
  ${PersonFragment}
  query fetchSaleOrder($id: Int!) {
    records: sales_sale_order_by_pk(id: $id) {
      ...SaleOrder
      deal{
        id
        stock {
          ...Stock
          transfer{
            documentId:document_id
            document {
              id
              support {
                id
                name
              }
              interveners{
                id
                person{
                  ...Person
                }
                field{
                  id
                  name
                }
              }
            }
          }
        }
        lead{
          id
        }
      }
      financings(order_by: {id: desc}) {
        ...Financing
      }
    }
  }
`

export default query
