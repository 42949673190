import gql from 'graphql-tag'
import { Fragment } from './fragment'

export const query = gql`
  ${Fragment}
  query fetchFinancing($id: Int!) {
    records: loans_financing_by_pk(id: $id) {
      ...FinancingFragment
    }
  }
`

export default query
