import { Type } from 'class-transformer'
import { Entity } from '..'

import { Brand, Version } from '.'

class VersionAggregate {
  years: {
    aggregate: {
      min: {
        year: number
      }
      max: {
        year: number
      }
    }
  }
}

export class Model extends Entity {
  @Type(() => Brand)
  brand: Brand;

  @Type(() => Version)
  versions: Version[];

  @Type(() => VersionAggregate)
  versionsAggregate: VersionAggregate[];

  name: string;
  segment?: string;
  officialWeb: string;
  priceLink: string;

  get title () {
    const { brand, name } = this
    return `${brand.name} ${name}`
  }

  get versionAggregateMinAndMax () {
    const { versionsAggregate } = this
    if (!versionsAggregate?.length) {
      return { min: undefined, max: undefined }
    }

    let minYear = versionsAggregate[0].years.aggregate.min.year
    let maxYear = versionsAggregate[0].years.aggregate.max.year

    versionsAggregate.forEach(aggregate => {
      if (aggregate.years.aggregate.min.year < minYear) {
        minYear = aggregate.years.aggregate.min.year
      }
      if (aggregate.years.aggregate.max.year > maxYear) {
        maxYear = aggregate.years.aggregate.max.year
      }
    })

    return {
      min: minYear,
      max: maxYear,
    }
  }

  get versionManagerTitle () {
    const { versionAggregateMinAndMax, name } = this
    if (!versionAggregateMinAndMax.min && !versionAggregateMinAndMax.max) {
      return name
    }
    return `${name} (${versionAggregateMinAndMax.min} - ${versionAggregateMinAndMax.max})`
  }
}
