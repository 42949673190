
  import Vue from 'vue'
  import UpdatePwa from '@/components/updatePwa.vue'
  import GlobalNotification from '@/components/global/GlobalNotification.vue'
  import { Component, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import NetworkDetector from '@/components/NetworkDetector.vue'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import { Schedule } from '@/entities/persons'
  import { Debounce } from '@/utils/decorators'
  import { UserData } from '@/store/user/state'

@Component({
  components: { UpdatePwa, GlobalNotification, NetworkDetector },
  methods: {
    ...mapActions('app', ['setMobile', 'setIsResize']),
    ...mapMutations('app', ['setSystem', 'setSchedule']),
    ...mapActions('resources/form', ['fetchData']),
    ...mapActions('user', ['sendHeartbeat']),
  },
  computed: {
    ...mapGetters('user', ['user']),
  },
})
  export default class App extends Vue {
  setMobile!: (payload: boolean) => void
  setIsResize!: (payload: number) => void
  setSystem!: (payload: string) => void
  setSchedule!: (payload: Array<Schedule>) => void
  sendHeartbeat!: (id: number) => Promise<void>
  user!: UserData

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  heartbeatInterval: Record<string, any> | null = null

  async mounted () {
    await this.getSystem()
  }

  @Debounce()
  async getSystem () {
    const system = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { type: { name: { _eq: 'system' } } },
    })

    this.setSchedule(system?.[0]?.metadata?.schedule)
    this.setSystem(system?.[0]?.alias)
  }

  @Watch('$vuetify.breakpoint', { immediate: true, deep: true }) onBreakpointChange (val) {
    // if this width changes, update resourceView DT mobile breakpoint
    this.setMobile(val.width < 875)
  }

  @Watch('$vuetify.breakpoint.width', { immediate: true, deep: true }) onWidthChange (val) {
    this.setIsResize(val)
  }

  @Watch('$vuetify.breakpoint.height', { immediate: true, deep: true }) onHeightChange (val) {
    this.setIsResize(val)
  }

  @Watch('user', { immediate: true, deep: true })
  async onUserChange (val) {
    if (!val?.id) return

    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval)
      this.heartbeatInterval = null
    }

    await this.sendHeartbeat(val.id)

    this.heartbeatInterval = setInterval(() => {
      this.sendHeartbeat(val.id)
    }, 30000)
  }

  beforeDestroy () {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval)
    }
  }
  }
