<template>
  <v-dialog v-model="showDialog" persistent max-width="400px">
    <v-card>
      <v-card-title class="text-h5 text-center">
        <v-row align="center" justify="center" no-gutters>
          <v-icon :color="alertColor">{{ icon }}</v-icon>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-alert :type="alertType" dense border="left" :color="alertColor" class="py-1">
          {{ message }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-row align="center" justify="center" no-gutters>
          <v-btn color="primary" text @click="closeDialog">Cerrar</v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator'

@Component
  export default class NetworkDetector extends Vue {
  showDialog = false;
  message = '';
  title = 'Estado de Conexión';
  alertType = 'info';
  alertColor = 'info';
  icon = '';

  mounted () {
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  }

  beforeDestroy () {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  }

  updateOnlineStatus (showMessage = true) {
    const wasOffline = localStorage.getItem('wasOffline') === 'true'
    const isOnline = navigator.onLine

    if (isOnline) {
      if (wasOffline) {
        this.message = 'Has recuperado la conexión a Internet.'
        this.alertType = 'success'
        this.alertColor = 'green'
        this.icon = 'mdi-wifi'
        this.showDialog = showMessage
      }
    } else {
      this.message = 'Perdió conexión a internet'
      this.alertType = 'error'
      this.alertColor = 'red'
      this.icon = 'mdi-wifi-off'
      this.showDialog = showMessage
    }

    localStorage.setItem('wasOffline', (!isOnline).toString())
  }

  closeDialog () {
    this.showDialog = false
  }
  }
</script>

<style scoped>
</style>
