import gql from 'graphql-tag'

import { ReserveFragment } from '@/graphql/fragments/sales'

export const mutation = gql`
  ${ReserveFragment}
  mutation insertReserve($fields: sales_reserve_insert_input!) {
    record: insert_sales_reserve_one(object: $fields) {
      id
      saleOrder:sale_order {
        id
      }
    }
  }
`

export default mutation
