import gql from 'graphql-tag'
import { IntentionFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${IntentionFragment}
  query findIntention {
    records: crm_intention {
      ...Intention
    }
  }
`

export default query
