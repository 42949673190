import gql from 'graphql-tag'

import { NoteFragment } from '@/graphql/fragments/crm'

export const query = gql`
  ${NoteFragment}
  query fetchDealNote($id: Int!) {
    records: crm_deal_note_by_pk(id: $id) {
      ...Note
    }
  }
`

export default query
