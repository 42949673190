import gql from 'graphql-tag'
import { AutoFragment } from '@/graphql/fragments/public'

export const mutation = gql`
  ${AutoFragment}
  mutation insertAuto($fields: auto_insert_input!) {
    record: insert_auto_one(object: $fields) {
      ...Auto
    }
  }
`

export default mutation
