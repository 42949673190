import { Actions } from '../../interfaces'

import { FileParameter } from '@/entities/files'
import fetch from './fetch'
import find from './find'

export const actions: Actions = {
  fetch,
  find,
}

export default {
  Model: { FileParameter },
  actions: { [FileParameter.name]: actions },
}
