import { Type } from 'class-transformer'
import { Entity } from '..'

import { Icon } from '../settings'
import { Payment } from '../finance/Payment'

export class PaymentType extends Entity {
  public static readonly transfer = 'electronic_transfer'
  public static readonly cash = 'cash'
  public static readonly bank = 'bank_deposit'
  public static readonly debitCard = 'debit_card'
  public static readonly creditCard = 'credit_card'
  public static readonly okView = 'ok_view'
  public static readonly check = 'check'
  public static readonly billExchange = 'bill_exchange'
  public static readonly financing = 'financing'

  @Type(() => Payment)
  payments: Payment[]

  @Type(() => Icon)
  icon?: Icon

  name: string;
  description: string;
  color?: string;
  active: boolean

  toString () {
    return this.description
  }

  get isTransfer () {
    const { name } = this
    return name === PaymentType.transfer
  }

  get isCash () {
    const { name } = this
    return name === PaymentType.cash
  }

  get isBank () {
    const { name } = this
    return name === PaymentType.bank
  }

  get isDebitCard () {
    const { name } = this
    return name === PaymentType.debitCard
  }

  get isCreditCard () {
    const { name } = this
    return name === PaymentType.creditCard
  }

  get isOkView () {
    const { name } = this
    return name === PaymentType.okView
  }

  get isCheck () {
    const { name } = this
    return name === PaymentType.check
  }

  get isBillExchange () {
    const { name } = this
    return name === PaymentType.billExchange
  }

  get isFinancing () {
    const { name } = this
    return name === PaymentType.financing
  }
}
