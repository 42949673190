import { Type } from 'class-transformer'
import { Entity } from '..'
import { Process } from '@/entities/settings'
import { Document, Field } from '.'

export class DocumentType extends Entity {
  @Type(() => Process)
  process: Process;

  @Type(() => Document)
  documents: Document[];

  @Type(() => Field)
  fields: Field[];
}
