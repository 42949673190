import { GetterTree } from 'vuex'
import { State as RootState } from '@/store/state'

export interface Settings {
  id: string
}

export function initState (): Settings {
  return {
    id: '',
  }
}

export const getters: GetterTree<Settings, RootState> = {}
