import gql from 'graphql-tag'
import { Enablement } from '@/entities/purchase'
import { QueryBuilder } from '../utils'
import { InspectionFragment } from '@/graphql/fragments/purchase'
import { ProcessStatusFragment } from '@/graphql/fragments/settings'
import { StockViewDetailFragment } from '@/graphql/fragments/sales'
import { AutoFragment } from '@/graphql/fragments/public'

const query = gql`
  ${InspectionFragment}
  ${ProcessStatusFragment}
  ${StockViewDetailFragment}
  ${AutoFragment}
  query enablement($filter: purchase_enablement_bool_exp, $order: [purchase_enablement_order_by!], $limit: Int, $offset: Int) {
    records: purchase_enablement(order_by: $order, limit: $limit, offset: $offset, where: $filter) {
      id
      createdAt: created_at
      metadata
      status{
        ...ProcessStatus
      }
      stock {
        id
        status{
          ...ProcessStatus
        }
        keyIdentifier:key_identifier {
          id
          keyId: key_id
        }
        viewDetails: stock_detail {
          ...StockViewDetail
          auto{
            ...Auto
          }
        }
      }
    }
    total: purchase_enablement_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const enablement = QueryBuilder(query, Enablement)
export default enablement
