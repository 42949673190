import gql from 'graphql-tag'
import {
  AppraisalFragment,
  BaseInspectionFragment,
  EnablementFragment,
  InspectedComponentFragment,
  ResponseFragment,
} from '@/graphql/fragments/purchase'
import { ClosingReasonFragment, ProcessFragment } from '@/graphql/fragments/settings'
import { EmployeeFragment } from '@/graphql/fragments/hr'
import { PersonAddressFragment } from '@/graphql/fragments/persons/personAddress'
import { DealFragment } from '@/graphql/fragments/crm'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'
import { AttributeFragment, AutoMotiveRegistrationFragment, VersionYearFragment } from '@/graphql/fragments/vehicle'
import { MaintenanceFragment } from '@/graphql/fragments/public'

export const query = gql`
  ${BaseInspectionFragment}
  ${ProcessFragment}
  ${EmployeeFragment}
  ${EnablementFragment}
  ${ClosingReasonFragment}
  ${PersonAddressFragment}
  ${AppraisalFragment}
  ${DealFragment}
  ${DealAutoAttributeFragment}
  ${InspectedComponentFragment}
  ${AutoMotiveRegistrationFragment}
  ${AttributeFragment}
  ${VersionYearFragment}
  ${MaintenanceFragment}
  ${ResponseFragment}
  query fetchInspection($id: Int!) {
    records: purchase_inspection_by_pk(id: $id) {
      ...InspectionBase
    }
  }
`

export default query
