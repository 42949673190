import { RouteConfig } from 'vue-router'

const routes: Array<RouteConfig> = [
  {
    path: '/datatable',
    name: 'datatable',
    component: () => import('@/layouts/datatables/Index.vue'),
    meta: { requiresLogin: true, auth: { param: 'role' } },
    children: [
      {
        path: ':role/coo_version_manager',
        name: 'version_manager',
        meta: { inherit: true },
        component: () => import('./VersionManagerView.vue'),
      },
      {
        path: ':role/:resource',
        name: 'role_resource',
        component: () => import('./ResourceView.vue'),
        props: ({ params: { role } }) => ({ role }),
        meta: { inherit: true },
        children: [
          {
            path: ':model/:id(\\d+)?',
            name: 'linked_model',
            component: () => import('./LinkedModel.vue'),
            meta: { inherit: true },
            props: ({ params: { model: name, id } }) => ({ name, id: Number(id) }),
          },
          {
            path: ':model/remove/:id(\\d+)?',
            name: 'remove_linked_model',
            component: () => import('./RemoveLinkedModel.vue'),
            meta: { inherit: true },
            props: ({ params: { model: name, id } }) => ({ name, id: Number(id) }),
          },
          {
            path: ':id(\\d+)/:model',
            name: 'add_linked_model',
            component: () => import('@/components/core/ModelDialog.vue'),
            meta: { inherit: true },
            props: ({ params: { model: title, id } }) => ({ mode: 'create', title, id: Number(id) }),
          },
        ],
      },
    ],
  },
]

export default routes
