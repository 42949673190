import gql from 'graphql-tag'
import { PaymentFragment } from '@/graphql/fragments/finance'

export const mutation = gql`
  ${PaymentFragment}
  mutation insertPayment($fields: finance_payment_insert_input!) {
    record: insert_finance_payment_one(object: $fields) {
      id
    }
  }
`

export default mutation
