<template>
  <svg width="262" height="262" viewBox="0 0 262 262" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2_7808)">
      <path
        d="M256.869 21.4661C252.654 17.6042 246.664 16.3172 241.234 18.1067L10.8643 94.0462C4.58602 96.1156 0.328518 101.72 0.0179045 108.323C-0.292197 114.926 3.42032 120.905 9.476 123.556L66.4482 148.482C66.7261 149.772 83.0811 225.627 83.6374 228.206C84.3512 231.517 86.0322 234.467 89.5932 235.375C93.1952 236.292 95.74 234.324 98.4276 232.312C99.9233 231.192 140.706 200.654 140.706 200.654L190.132 241.1C193.011 243.457 196.53 244.686 200.117 244.686C201.825 244.686 203.55 244.407 205.227 243.838C210.429 242.074 214.286 237.828 215.543 232.482L261.58 36.7481C262.889 31.1831 261.085 25.328 256.869 21.4661ZM102.11 162.766C102.082 162.833 102.055 162.908 102.029 162.998L92.4983 196.294L81.8627 146.967L154.996 106.334L103.865 159.797C103.059 160.64 102.467 161.658 102.11 162.766ZM105.135 208.066L109.464 192.942L113.604 178.476L128.439 190.616L105.135 208.066ZM246.601 33.2244L200.565 228.958C200.542 229.055 200.511 229.189 200.286 229.265C200.063 229.341 199.954 229.255 199.877 229.191L145.793 184.934C145.792 184.933 145.791 184.931 145.789 184.93L120.731 164.424L201.236 80.2477C203.865 77.4983 204.09 73.2403 201.766 70.2288C199.44 67.2168 195.263 66.3566 191.939 68.205L72.7331 134.436L15.6443 109.458C15.4703 109.382 15.3746 109.339 15.3884 109.045C15.4022 108.752 15.5015 108.719 15.6816 108.659L246.051 32.7203C246.163 32.684 246.289 32.642 246.474 32.8109C246.659 32.9808 246.628 33.1097 246.601 33.2244Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2_7808">
        <rect width="262" height="262" fill="white" />
      </clipPath>
    </defs>
  </svg>

</template>

<script>
  export default {}
</script>
