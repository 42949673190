import { Transform, Type } from 'class-transformer'
import { Entity } from '..'

import { ActivityType, ContractType, Person, RentType } from '.'
import dayjs, { Dayjs } from 'dayjs'

export class LaborData extends Entity {
  @Type(() => Person)
  person: Person;

  @Type(() => Person)
  employer: Person;

  @Type(() => ActivityType)
  activityType: ActivityType;

  @Type(() => ContractType)
  contractType: ContractType;

  @Type(() => RentType)
  rentType: RentType;

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs(value), { toClassOnly: true })
  admissionDate: Dayjs;

  rent?: number;
  jobTitle?: string;
  executiveComment?: string;
  jobKey?: string;
  active: boolean;
  nameActivityType: string
  nameRentType: string
  nameContractType: string
}
