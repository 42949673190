import gql from 'graphql-tag'

import { EvaluationFragment } from '@/graphql/fragments/loans'

export const mutation = gql`
  ${EvaluationFragment}
  mutation insertEvaluation($fields: loans_evaluation_insert_input!) {
    record: insert_loans_evaluation_one(object: $fields) {
      ...Evaluation
    }
  }
`

export default mutation
