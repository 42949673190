import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'

import user from './user'
import app from './app'
import resources from './resources'
import toolkit from './toolkit'

import Vehicle from './vehicles'
import Notification from './notification'
import Settings from './settings'
import Dashboard from './dashboards'
import Persons from './persons'

import { State, initState, mutations, getters } from './state'

Vue.use(Vuex)

const store: StoreOptions<State> = {
  state: initState,
  mutations,
  getters,
  modules: {
    user,
    app,
    resources,
    toolkit,
    vehicle: Vehicle,
    notification: Notification,
    settings: Settings,
    persons: Persons,
    dashboard: Dashboard,
  },
}

export default new Vuex.Store(store)
