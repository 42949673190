import gql from 'graphql-tag'
import {
  DealFragment,
  LeadFragment,
  NoteFragment,
} from '@/graphql/fragments/crm'
import { AppraisalFragment } from '@/graphql/fragments/purchase'
import { BaseSaleOrderFragment, SaleOrderItemFragment, StockFragment } from '@/graphql/fragments/sales'
import { FinancingFragment } from '@/graphql/fragments/loans'
import PersonFragment from '../../persons/person/fragment'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'

export const Fragment = gql`
  ${LeadFragment}
  ${PersonFragment}
  ${DealFragment}
  ${AppraisalFragment}
  ${BaseSaleOrderFragment}
  ${FinancingFragment}
  ${StockFragment}
  ${NoteFragment}
  ${SaleOrderItemFragment}
  ${DealAutoAttributeFragment}
  fragment DealForm on crm_deal {
    ...Deal
    lead {
      ...Lead
      client {
        ...PersonForm
      }
    }
    autoAttributes: auto_attributes {
      ...DealAutoAttribute
    }
    appraisals {
      ...Appraisal
    }
    saleOrders: sale_orders {
      ...BaseSaleOrder
      saleOrderItems: sale_order_items(order_by: {item_type: {id: asc}}) {
        ...SaleOrderItem
      }
      financings {
        ...Financing
      }
    }
    stock {
      ...Stock
    }
    notes(order_by: { created_at: asc_nulls_last }, limit: 1) {
      ...Note
    }
  }
`
export default Fragment
