import { Actions } from '../../interfaces'

import { Evaluation } from '@/entities/loans'
import create from './create'
import fetch from './fetch'
import find from './find'
import update from './update'

export const actions: Actions = {
  create,
  fetch,
  find,
  update,
}

export default {
  Model: { Evaluation },
  actions: { [Evaluation.name]: actions },
}
