import { VueApolloClient } from '@/plugins/apollo'

import userResources from './resources'
import { Resource } from '@/entities/public'
import { plainToInstance } from 'class-transformer'

export async function getUserResources (client?: VueApolloClient): Promise<Array<Resource>> {
  if (!client) return []

  const {
    data: { resources },
  } = await client.query({ query: userResources, fetchPolicy: 'cache-first' })

  return plainToInstance(Resource, resources as Object[])
}
