import { Actions } from '../../interfaces'

import { FinancialCategory } from '@/entities/finance'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { FinancialCategory },
  actions: { [FinancialCategory.name]: actions },
}
