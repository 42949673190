import { Actions } from '../../interfaces'
import { Intention } from '@/entities/crm'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { Intention },
  actions: { [Intention.name]: actions },
}
