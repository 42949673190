import { GetterTree, MutationTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Person } from '@/store/persons/state'
import { Details } from '@/utils/generalInterface'

export interface Bank {
  accountAdded?: CreateBankData | null
  addAccount: boolean
  accountFound?: BankDataFulled | null
  banks: Array<Details>
  accountTypes: Array<Details>
  biPersonalInfo: BankDataFulledBiPersonal | null
}

export interface CreateBankData {
  bankData: BankDataFulled
  personAccount: PersonAccount
  exists: boolean
}

export interface BankDataFulledBiPersonal {
  id: number
  idHolder?: number | null
  idBank?: number | null
  bank?: BankInfo | null
  accountNumber: string
  accountType: AccountType
  personAccounts?: PersonAccountsEntity | null
  persons?: PersonAccountsEntity[] | null
}

export interface BankDataFulled {
  id: number
  idHolder?: number | null
  idBank?: number | null
  bank?: BankInfo | null
  accountNumber: string
  accountType: AccountType
  personAccounts?: PersonAccountsEntity[] | null
  persons?: PersonAccountsEntity[] | null
  bipersonal?: boolean
}

export interface BankInfo {
  companyName: string
  alias: string
}

export interface AccountType {
  name: string
  description: string
}

export interface PersonAccountsEntity {
  id: number
  person?: Person | null
  active?: boolean | null
}

export interface PersonAccount {
  id: number
}

export function initState (): Bank {
  return {
    banks: [],
    accountTypes: [],
    accountAdded: null,
    addAccount: false,

    accountFound: null,
    biPersonalInfo: null,
  }
}

export const mutations: MutationTree<Bank> = {
  setBiPersonalInfo (state, payload: BankDataFulledBiPersonal) {
    state.biPersonalInfo = payload
  },
  setBanks: (state, banks) => {
    state.banks = banks
  },
  setAccountFound (state, payload) {
    state.accountFound = payload
  },
  setAccountAdded (state, payload) {
    state.accountAdded = payload
  },
  setAddAccountState: (state, addAccount: boolean) => {
    state.addAccount = addAccount
  },
  setAccountTypes: (state, accountTypes) => {
    state.accountTypes = accountTypes
  },
}

export const getters: GetterTree<Bank, RootState> = {
  addAccount: ({ addAccount }): boolean => {
    return addAccount
  },
  isAccountAdded: ({ accountAdded }) => {
    return accountAdded
  },
  accountFound: ({ accountFound }) => {
    return accountFound
  },
  banks: ({ banks }) => {
    return banks
  },
  accountTypes: ({ accountTypes }) => {
    return accountTypes
  },
  biPersonalInfo: ({ biPersonalInfo }) => {
    return biPersonalInfo
  },
}
