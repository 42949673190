import { Transform, Type } from 'class-transformer'
import dayjs, { Dayjs } from 'dayjs'

import { Aggregates } from './aggregate'

export class Entity {
  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  createdAt: Dayjs;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  updatedAt?: Dayjs;

  id: number;

  createdBy: Record<string, any>;
  updatedBy?: Record<string, any>;

  declare _aggregates?: Aggregates

  formatDate (date: Dayjs, format = 'DD/MM/YYYY HH:mm'): string {
    if (!date?.isValid()) return

    const local = dayjs(date)
    const offset = dayjs().utcOffset()
    const localDate = local.add(offset, 'minute')

    return localDate.format(format)
  }

  formatDateDayjs (date: Dayjs) {
    if (!date?.isValid()) return

    const local = dayjs(date)
    const offset = dayjs().utcOffset()

    return local.add(offset, 'minute')
  }

  get created (): string {
    const { createdAt } = this
    return this.formatDate(createdAt)
  }

  get createdWithOutFormat (): Dayjs {
    const { createdAt } = this
    return this.formatDateDayjs(createdAt)
  }

  get modified (): string {
    const { updatedAt } = this
    return this.formatDate(updatedAt)
  }

  get aggregates () {
    const { _aggregates } = this
    return _aggregates
  }

  get isClosed (): boolean {
    if (!('closingReason' in this)) return false

    const { closingReason } = this
    return Boolean(closingReason)
  }

  setAggregates (aggregates: Aggregates) {
    this._aggregates = aggregates
  }

  toString () {
    const { id } = this
    return id ? `${id}` : ''
  }
}

export class EnumEntity {
  name: string
  description: string

  private _aggregates?: Aggregates

  get aggregates () {
    const { _aggregates } = this
    return _aggregates
  }

  setAggregates (aggregates: Aggregates) {
    this._aggregates = aggregates
  }

  toString () {
    return this.name
  }
}
