import gql from 'graphql-tag'

import { NoteFragment } from '@/graphql/fragments/crm'

export const mutation = gql`
  ${NoteFragment}
  mutation insertDealNote($fields: crm_deal_note_insert_input!) {
    record: insert_crm_deal_note_one(object: $fields) {
      ...Note
    }
  }
`

export default mutation
