import gql from 'graphql-tag'
import { AggregatePath } from '@/entities/interfaces'
import { StockFragment } from '@/graphql/fragments/sales'

const structure: Array<AggregatePath> = [
  { name: 'active', title: 'Activos', aggregations: ['count'], color: 'blue' },
  { name: 'pending', title: 'Sin Gestión', aggregations: ['count'], color: 'yellow' },
  { name: 'delayed', title: 'Atrasados', aggregations: ['count'], color: 'red' },
  { name: 'assigned', title: 'Asignadas', aggregations: ['count'], color: 'primary', hidden: true },
]
const query = gql`
  ${StockFragment}
  query aggregates($aggregate: sales_stock_bool_exp, $order: [sales_stock_order_by!]) {
    sales_stock_aggregate(where: $aggregate, order_by: $order) {
      ...Stock
      aggregate {
        count(distinct: true)
      }
    }
  }
`
export const aggregate = { structure, query }
export default aggregate
