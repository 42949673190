import { State as RootState } from '@/store/state'
import { ActionTree } from 'vuex'
import { Persons, PersonsDetails } from '@/store/persons/state'
import {
  consultRut,
  findPerson,
  getCountry,
  getCountryFiltered,
  getCountryFilteredById,
  getMaritalStatus,
  getPersonById,
  getPersonGender,
  getPersonType,
} from '@/store/persons/queries/personInfo'
import { insertNaturalPerson, updateMainPhoneNumber, updateNaturalPerson } from '@/store/persons/mutation/createPerson'
import { deepCopy, fixDate, isEmpty, removeValues } from '@/utils/general'
import { PersonFormatted } from '@/store/persons/person'
import { PersonsEntity } from '@/utils/generalInterface'
import { currentReferences, isReferenceRequired } from '@/store/persons/reference/queries/info'
import { isAccountRequired } from '@/store/persons/bank/mutation/bank'
import { isAddressRequired } from '@/store/persons/address/mutation/address'
import { isLaborDataRequired } from '@/store/persons/labor/queries/info'
import { plainToInstance } from 'class-transformer'
import { PersonType } from '@/entities/persons'

// eslint-disable-next-line max-statements
const setPersonData = (
  person: PersonsDetails,
  foundPerson: PersonFormatted | undefined
): PersonsEntity => {
  const variables: PersonsEntity = {}

  if (person.rut || (foundPerson && person.rut !== foundPerson.rut)) {
    // @ts-ignore
    variables.uid = removeValues(person.rut)
  }
  if (person.name || (foundPerson && person.name !== foundPerson.name)) {
    variables.first_name = person.name
  }
  if (
    person.secondName ||
    (foundPerson && person.secondName !== foundPerson.secondName)
  ) {
    variables.second_name = person.secondName
  }
  if (
    person.surname ||
    (foundPerson && person.surname !== foundPerson.surname)
  ) {
    variables.surname = person.surname
  }
  if (
    person.secondSurname ||
    (foundPerson && person.secondSurname !== foundPerson.secondSurname)
  ) {
    variables.second_surname = person.secondSurname
  }
  if (person.email || (foundPerson && person.email !== foundPerson.email)) {
    if (
      person.emailWork ||
      (foundPerson && person.emailWork?.toLowerCase() !== foundPerson.emailWork?.toLowerCase())
    ) {
      variables.email = {
        personal: person.email?.toLowerCase(),
        work: person.emailWork?.toLowerCase(),
      }
    } else {
      variables.email = { personal: person.email?.toLowerCase() }
    }
  }
  if (
    person.emailWork ||
    (foundPerson && person.emailWork?.toLowerCase() !== foundPerson.emailWork.toLowerCase())
  ) {
    if (person.email || (foundPerson && person.email?.toLowerCase() !== foundPerson.email.toLowerCase())) {
      variables.email = {
        personal: person.email?.toLowerCase(),
        work: person.emailWork?.toLowerCase(),
      }
    } else {
      variables.email = {
        work: person.emailWork?.toLowerCase(),
      }
    }
  }

  if ((person.phone && !foundPerson?.id) || (foundPerson && person.phone !== foundPerson.phone)) {
    variables.main_phone = person.phone
  }
  if (
    person.phoneWork ||
    (foundPerson && person.phoneWork !== foundPerson.phoneWork)
  ) {
    variables.secondary_phone = {
      work: person.phoneWork,
    }
  }
  if (
    !isEmpty(person.birthDate) ||
    (foundPerson && person.birthDate !== foundPerson.birthDate)
  ) {
    variables.birth_date = fixDate(person.birthDate)
  }
  if (person.type || (foundPerson && person.type !== foundPerson.type)) {
    variables.name_person_type = person.type
  }
  if (
    person.maritalStatus ||
    (foundPerson && person.maritalStatus && person.maritalStatus !== foundPerson.maritalStatus)
  ) {
    variables.name_marital_status = person.maritalStatus
  }

  if (
    person.nationality ||
    (foundPerson && person.nationality !== foundPerson.nationality)
  ) {
    variables.id_country = person.nationality?.toString()
  }

  if (person.gender || (foundPerson && person.gender !== foundPerson.gender)) {
    variables.name_gender = person.gender
  }

  if (person.companyName || (foundPerson && person.companyName !== foundPerson.companyName)) {
    variables.company_name = person.companyName
  }

  if (person.alias || (foundPerson && person.alias !== foundPerson.alias)) {
    variables.alias = person.alias
  }

  if (variables.id_country === '-1') {
    delete variables.id_country
  }

  const keys = Object.keys(variables)

  keys.forEach(key => {
    if (variables[key] === '') {
      delete variables[key]
    }
  })

  return variables
}

async function findPersonDetails ({ apolloClient, variables }) {
  return apolloClient.query({
    query: findPerson,
    variables,
    fetchPolicy: 'network-only',
  })
}

async function handleErrors ({ apolloClient, variables, state, commit }) {
  if (isEmpty(state.foundPerson?.rut) && state.foundPerson?.details) {
    state.foundPerson.details.rut = removeValues(variables.rut)
    commit('setFoundPerson', state.foundPerson)
  }

  if (!isEmpty(variables.rut)) {
    delete variables.phone
    const {
      data: { consultRut: rutInfo },
    } = await apolloClient.query({
      query: consultRut,
      variables,
      fetchPolicy: 'network-only',
    })
    commit('setRutInfo', rutInfo)
  } else {
    commit('setRutInfo', null)
  }
}

export const actions: ActionTree<Persons, RootState> = {
  cleanFieldsRequired: ({ commit }) => {
    commit('setAccountRequired', false)
    commit('setAddressRequired', false)
    commit('setReferenceRequired', false)
    commit('setLaborDataRequired', false)
  },
  lookFieldsRequired: async (
    { commit, rootState: { apolloClient } },
    id
  ): Promise<void> => {
    if (!apolloClient || !id) return

    const observer = apolloClient.subscribe({
      query: isAccountRequired,
      variables: { id },
    })
    observer.subscribe({
      next ({ data: { isAccount } }) {
        commit(
          'setAccountRequired',
          isAccount.length > 0 &&
          !isAccount.some(
            (account: { active: boolean, id: number }) => account.active
          )
        )
      },
    })

    const observerAddress = apolloClient.subscribe({
      query: isAddressRequired,
      variables: { id },
    })
    observerAddress.subscribe({
      next ({ data: { isAddress } }) {
        commit(
          'setAddressRequired',
          isAddress.length > 0 &&
          !isAddress.some(
            (account: { active: boolean, id: number }) => account.active
          )
        )
      },
    })

    const observerReferences = apolloClient.subscribe({
      query: isReferenceRequired,
      variables: { id },
    })

    observerReferences.subscribe({
      next ({ data: { isReference } }) {
        commit(
          'setReferenceRequired',
          isReference.length > 0 &&
          !isReference.some(
            (reference: { active: boolean }) => reference.active
          )
        )
      },
    })

    const observerLaborData = apolloClient.subscribe({
      query: isLaborDataRequired,
      variables: { id },
    })

    observerLaborData.subscribe({
      next ({ data: { isLaborData } }) {
        commit(
          'setLaborDataRequired',
          isLaborData.length > 0 &&
          !isLaborData.some(
            (laborData: { active: boolean }) => laborData.active
          )
        )
      },
    })
  },
  findReferencePerson: async (
    { commit, state: { foundPerson, id }, rootState: { apolloClient } },
    phone
  ): Promise<void> => {
    if (isEmpty(phone) || !apolloClient) {
      return
    }

    const variables: PersonsDetails = {}
    variables.phone = phone

    try {
      const {
        data: { person },
      } = await apolloClient.query({
        query: findPerson,
        variables,
        fetchPolicy: 'network-only',
      })

      if (foundPerson && person.id === foundPerson.id) {
        window.dispatchEvent(
          new CustomEvent('notification-message', {
            detail: {
              message:
                'No puede agregar al mismo usuario como persona de contacto',
              type: 'error',
            },
          })
        )
        commit('setDisabledReferenceField', true)
        return
      }
      if (person?.id) {
        const personFormatted = new PersonFormatted(person)
        commit('setReferencePerson', personFormatted)
        commit('setDisabledReferenceField', true)
      } else {
        commit('setDisabledReferenceField', false)
      }
      const {
        data: { references },
      } = await apolloClient.query({
        query: currentReferences,
        variables: {
          // @ts-ignore
          id: id || foundPerson.id,
        },
      })

      // @ts-ignore
      if (references.find(ref => ref.person.id === person.id && ref.active)) {
        window.dispatchEvent(
          new CustomEvent('notification-message', {
            detail: {
              message: 'Ya tienes a esta persona agregada',
              type: 'error',
            },
          })
        )
        commit('setDisabledReferenceField', true)
        return
      }
    } catch (e) {
      commit('setDisabledReferenceField', false)
    }
  },
  // eslint-disable-next-line max-statements
  findPerson: async (
    { commit, state, rootState: { apolloClient } },
    { phone, rut, id, type }
  ): Promise<void> => {
    commit('setLoading', true)

    if ((isEmpty(phone) && isEmpty(rut) && isEmpty(id)) || !apolloClient) {
      return
    }

    commit('setFoundPerson', null)

    const variables: PersonsDetails = {}
    if (!isEmpty(id)) {
      variables.id = id
    } else if (!isEmpty(rut)) {
      variables.rut = removeValues(rut)
    } else {
      variables.phone = phone
    }

    try {
      const {
        data: { person },
      } = await findPersonDetails({ apolloClient, variables })

      const personFormatted = new PersonFormatted(deepCopy(person))

      if (!state?.foundPerson || state?.foundPerson?.id === personFormatted.id) {
        commit('setFoundPerson', personFormatted)
      } else if (state?.foundPerson?.phone !== personFormatted.phone) {
        window.dispatchEvent(
          new CustomEvent('notification-message', {
            detail: {
              message: 'El numero telefonico lo tiene asignada otra persona',
              type: 'error',
            },
          })
        )
        commit('setResetPhone', state.foundPerson.phone)
      }
    } catch (e) {
      // @ts-ignore
      if (e.message.includes('Invalid RUT')) {
        commit('setRutError', true)
        return
      }
      variables.personType = type || 'natural_person'
      await handleErrors({ apolloClient, variables, state, commit })
    }
    commit('setLoading', false)
  },
  createPerson: async (
    { commit, dispatch, state, rootState: { apolloClient } },
    { person, close = false, labor = false }
  ) => {
    if (!apolloClient) return
    const newPerson = setPersonData(person, state.foundPerson)

    const {
      data: {
        insertNaturalPerson: { id },
      },
    } = await apolloClient.mutate({
      mutation: insertNaturalPerson,
      variables: { insertNaturalPerson: newPerson },
    })

    if (!close && !labor) {
      commit('setId', id)
      await dispatch('lookFieldsRequired', id)
      return
    }

    if (labor) {
      commit('setLaborId', id)
    }
    return id
  },

  updatePerson: async (
    { state, rootState: { apolloClient } },
    person
  ): Promise<void> => {
    if (!apolloClient) return
    let personFounded = null
    try {
      const {
        data: { person: personFound },
      } = await apolloClient.query({
        query: findPerson,
        variables: { id: person.id },
        fetchPolicy: 'network-only',
      })

      personFounded = new PersonFormatted(personFound)
    } catch (e) {
      console.log(e)
    }

    const updatePerson = setPersonData(person, state?.foundPerson || personFounded)

    await apolloClient.mutate({
      mutation: updateNaturalPerson,
      variables: { person: updatePerson, id: person.id },
    })
  },

  getPersonType: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { personType },
    } = await apolloClient.query({
      query: getPersonType,
    })

    commit('setPersonType', personType.map(type => plainToInstance(PersonType, type)))
  },
  getGender: async ({ commit, rootState: { apolloClient } }): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { gender },
    } = await apolloClient.query({
      query: getPersonGender,
    })

    commit('setGender', gender)
  },
  getCivilStatus: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { maritalStatus },
    } = await apolloClient.query({ query: getMaritalStatus })

    commit('setCivilStatus', maritalStatus)
  },
  getCountries: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) return
    const {
      data: { country },
    } = await apolloClient.query({
      query: getCountry,
    })

    commit('setCountries', country)
  },
  getCountryById: async (
    { commit, dispatch, rootState: { apolloClient } },
    id: number
  ): Promise<void> => {
    if (!apolloClient) return
    if (id === -1 || !id) {
      await dispatch('getCountries')
      return
    }

    commit('setCountries', [])
    const {
      data: { country },
    } = await apolloClient.query({
      query: getCountryFilteredById,
      variables: { id },
    })

    commit('setCountries', country)
  },
  getFilterCountries: async (
    { commit, rootState: { apolloClient } },
    name
  ): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { country },
    } = await apolloClient.query({
      query: getCountryFiltered,
      variables: { name: `%${name || ''}%` },
    })

    commit('setCountries', country)
  },
  updatePhoneNumber: async (
    { commit, rootState: { apolloClient } },
    { id, phone }
  ): Promise<void> => {
    if (!apolloClient || !id || !phone) return

    await apolloClient.mutate({
      mutation: updateMainPhoneNumber,
      variables: { id, phone },
    })
  },
  getPersonById: async (
    { commit, dispatch, rootState: { apolloClient } },
    id: number
  ): Promise<void> => {
    if (!apolloClient || !id) return

    const {
      data: { person },
    } = await apolloClient.query({
      query: getPersonById,
      variables: { id },
      fetchPolicy: 'network-only',
    })

    if (person?.length) {
      await dispatch('findPerson', { phone: person[0].phone, rut: person[0].rut, id })
    }
  },
  embedPerson: ({ commit }, payload): void => {
    commit('setEmbedPerson', payload)
  },
}
