import gql from 'graphql-tag'
import { EntityFields } from '..'
import { AutoOwnerFragment } from '.'

export const BaseOwnershipFragment = gql`
    fragment OwnershipBase on ownership {
        ${EntityFields}
    }
`

export const OwnershipFragment = gql`
    ${BaseOwnershipFragment}
    ${AutoOwnerFragment}
    fragment Ownership on ownership {
        ...OwnershipBase
        owners {
            ...AutoOwner
        }
    }
`
