import { Module } from 'vuex'

import { State as RootState } from '@/store/state'
import { App, initState, getters } from './state'
import { mutations } from './mutations'
import { actions } from './actions'

const module: Module<App, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  actions,
  getters,
}

export default module
