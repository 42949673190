import gql from 'graphql-tag'
import { EntityFields } from '..'
import { InspectionAssessmentOptionFragment } from './inspectionAssessmentOption'

export const BaseInspectionAssessmentFragment = gql`
  fragment BaseInspectionAssessment on purchase_inspection_assessment{
    ${EntityFields}
    score
  }
`

export const InspectionAssessmentFragment = gql`
  ${BaseInspectionAssessmentFragment}
  ${InspectionAssessmentOptionFragment}
  fragment InspectionAssessment on purchase_inspection_assessment{
    ...BaseInspectionAssessment
    option:option{
      ...InspectionAssessmentOption
    }
  }
`
