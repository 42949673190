import { Module } from 'vuex'

import { State as RootState } from '@/store/state'
import { Catalogue, initState, mutations, getters } from './state'
import actions from './actions'

const module: Module<Catalogue, RootState> = {
  namespaced: true,
  state: initState,
  mutations,
  getters,
  actions,
}

export default module
