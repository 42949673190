import gql from 'graphql-tag'

import { PaymentTypeFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${PaymentTypeFragment}
  query fetchPaymentType($id: Int!) {
    records: finance_payment_type_by_pk(id: $id) {
      ...PaymentType
    }
  }
`

export default query
