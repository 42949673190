import { AggregatePath } from '@/entities/interfaces'

export class Aggregate {
  readonly name: string
  title?: string
  icon?: string
  color?: string
  hidden?: boolean

  private readonly values: Record<string, number>
  private default: string

  constructor ({ name, title, icon, color, hidden, aggregations }: AggregatePath, values: Record<string, number>) {
    if (!aggregations?.length) throw new Error(`The aggregations MUST have at least one value | ${name}`)
    if (aggregations.some(name => !(name in values))) throw new Error(`Inconsistency values on ${name} aggregate.`)

    this.name = name
    this.values = values
    this.setDefault(aggregations[0])

    this.title = title
    this.icon = icon
    this.color = color
    this.hidden = hidden
  }

  setDefault (name: string) {
    if (!(name in this.values)) throw new Error(`Unknown value ${name} in aggregation ${this.name}`)
    this.default = name
  }

  aggregate (name: string = this.default) {
    const { values } = this
    return values[name]
  }

  get value () {
    return this.aggregate()
  }
}

export class Aggregates implements Iterable<Aggregate> {
  private readonly aggregates: Array<Aggregate>
  private _values: Record<string, Aggregate>
  private default: string

  constructor (structure: Array<AggregatePath>, record: Record<string, any>) {
    if (!structure?.length) throw new Error('The structure MUST have at least one aggregation')

    this.aggregates = structure.map(aggregate => new Aggregate(aggregate, record[aggregate.name].aggregate))
    this.setDefault(structure[0].name)
  }

  public [Symbol.iterator] () {
    const { visible } = this
    let count = 0
    return {
      next () {
        return {
          value: visible[count],
          done: visible.length === count++,
        }
      },
    }
  }

  get visible (): Array<Aggregate> {
    const { aggregates = [] } = this
    return aggregates.filter(({ hidden }) => !hidden)
  }

  get values (): Record<string, Aggregate> {
    const { _values } = this
    if (_values) return _values

    return this._values = this.aggregates.reduce((values, aggregate) =>
      Object.assign(values, { [aggregate.name]: aggregate }),
    {})
  }

  setDefault (name: string) {
    if (!(name in this.values)) throw new Error(`Unknown aggregation ${name}`)
    this.default = name
  }

  aggregate (name: string = this.default) {
    const { values } = this
    return values[name]
  }

  get value () {
    return this.aggregate().value
  }
}
