<template>
  <svg width="56" height="50" viewBox="0 0 56 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.1143 36C28.8429 35.2 28.5714 34.2667 28.5714 33.3333C28.5714 31.3333 29.3857 29.4667 30.6071 28H28.5714V25.3333H31.2857V27.3333C32.1 26.6667 33.05 26.1333 34 25.7333V12H15V36H23.1429V31.3333H25.8571V36H29.1143ZM28.5714 14.6667H31.2857V17.3333H28.5714V14.6667ZM28.5714 20H31.2857V22.6667H28.5714V20ZM20.4286 33.3333H17.7143V30.6667H20.4286V33.3333ZM20.4286 28H17.7143V25.3333H20.4286V28ZM20.4286 22.6667H17.7143V20H20.4286V22.6667ZM20.4286 17.3333H17.7143V14.6667H20.4286V17.3333ZM23.1429 14.6667H25.8571V17.3333H23.1429V14.6667ZM23.1429 20H25.8571V22.6667H23.1429V20ZM23.1429 28V25.3333H25.8571V28H23.1429Z"
    />
    <path
      d="M38.8265 36.8L36.5 35.3975L34.1735 36.8L34.7895 34.1545L32.738 32.378L35.444 32.147L36.5 29.65L37.556 32.1415L40.262 32.3725L38.2105 34.149L38.8265 36.8ZM36.5 28C33.4585 28 31 30.475 31 33.5C31 34.9587 31.5795 36.3576 32.6109 37.3891C33.1216 37.8998 33.728 38.3049 34.3952 38.5813C35.0625 38.8577 35.7777 39 36.5 39C37.9587 39 39.3576 38.4205 40.3891 37.3891C41.4205 36.3576 42 34.9587 42 33.5C42 32.7777 41.8577 32.0625 41.5813 31.3952C41.3049 30.728 40.8998 30.1216 40.3891 29.6109C39.8784 29.1002 39.272 28.6951 38.6048 28.4187C37.9375 28.1423 37.2223 28 36.5 28Z"
    />
  </svg>
</template>
<script>
  export default {}
</script>
