import { Type } from 'class-transformer'
import { Entity } from '..'

import { Appraisal } from '.'
import { Person } from '../persons'
import { fixPrice } from '@/utils/general'
import { Icon } from '@/models/interfaces'

const OverviewIcons: Record<string, Icon> = {
  from: {
    name: 'Desde',
    icon: 'mdi-triangle',
    color: 'red',
    rotate: true,
  },
  avg: {
    name: 'Prom',
    icon: 'mdi-rhombus',
    color: 'orange',
  },
  to: {
    name: 'Hasta',
    icon: 'mdi-triangle',
    color: 'red',
  },
}

export class AppraisalMarketPrice extends Entity {
  @Type(() => Appraisal)
  appraisal: Appraisal;

  @Type(() => Person)
  company: Person;

  mileageFrom?: number;
  mileageTo?: number;
  vehiclesNumber?: number;
  minimumLowPrice?: number;
  medianLowPrice?: number;
  maximumLowPrice?: number;
  minimumMediumPrice?: number;
  medianMediumPrice?: number;
  maximumMediumPrice?: number;
  minimumHighPrice?: number;
  medianHighPrice?: number;
  maximumHighPrice?: number;
  link: string;

  get overview () {
    const prices = [
      { name: 'from', value: 'minimumLowPrice' },
      { name: 'avg', value: 'medianLowPrice' },
      { name: 'to', value: 'maximumLowPrice' },
    ]

    return prices.map(price => ({
      ...OverviewIcons[price.name],
      value: this[price.value],
    }))
  }

  toString () {
    const { maximumHighPrice, maximumLowPrice, maximumMediumPrice } = this
    const prices = [fixPrice(maximumHighPrice), fixPrice(maximumMediumPrice), fixPrice(maximumLowPrice)]
    return prices.join('\n')
  }
}
