import gql from 'graphql-tag'

export const inspectionsSettings = gql`
  query inspection_settings {
    settingsProcess: settings_process(where: {table_name: {_eq: "inspection"}}) {
      id
      config
    }
  }
`
