import { Company, Employee, Entity, Person } from '@/models'
import { Financing as IFinancing, FinancingStatus, financingStatusIcons } from './interfaces'

export class Financing extends Entity implements IFinancing {
  company: Company;
  debtor: Person;
  status: FinancingStatus;
  responsible: Employee;

  constructor (financing: IFinancing) {
    super(financing)
    const { company, debtor, status, responsible } = financing

    this.company = new Company(company)
    this.debtor = new Person(debtor)
    this.status = status
    this.responsible = new Employee(responsible)
  }

  get isApproved () {
    return this.status >= FinancingStatus.approved
  }

  toString () {
    return financingStatusIcons[this.status].name
  }
}
