import { Type } from 'class-transformer'
import { Entity } from '..'

import { Channel, DealNote, DealType, Intention, Lead } from '.'
import { ClosingReason, ProcessStatus } from '../settings'
import { Appraisal } from '../purchase'
import { Auto, DealAutoAttribute, Ownership } from '../public'
import { SaleOrder, Stock } from '../sales'
import { Person } from '@/entities/persons'
import { Payment } from '@/entities/finance'

export class AppraisalsAggregate {
  aggregate
}

export class Deal extends Entity {
  @Type(() => DealType)
  type: DealType;

  @Type(() => Channel)
  channel: Channel;

  @Type(() => Auto)
  auto: Auto;

  @Type(() => Lead)
  lead: Lead;

  @Type(() => Intention)
  intention: Intention;

  @Type(() => DealNote)
  notes: DealNote[];

  @Type(() => Appraisal)
  appraisals: Appraisal[];

  @Type(() => ClosingReason)
  closingReason: ClosingReason;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => DealAutoAttribute)
  autoAttributes: DealAutoAttribute[];

  @Type(() => AppraisalsAggregate)
  appraisalsAggregate: AppraisalsAggregate

  @Type(() => Ownership)
  ownership: Ownership

  @Type(() => Person)
  referrer: Person;

  @Type(() => SaleOrder)
  saleOrders: SaleOrder[];

  @Type(() => Stock)
  stock: Stock;

  @Type(() => Payment)
  payments: Payment[];

  totalAmount: number;
  negotiationAmount: number;
  warrantyAmount: string;
  singlePayment: Payment
  private _appraisal?: Appraisal;
  private _saleOrder?: SaleOrder;

  get isDealOpen () {
    const { closingReason } = this
    return !closingReason
  }

  get isDealClosed () {
    const { closingReason } = this
    return closingReason
  }

  get isSale () {
    const { type } = this
    return type?.name === DealType.sale
  }

  get isPurchase () {
    const { type: { name } } = this
    return name === DealType.purchase
  }

  get appraisal (): Appraisal | undefined {
    const { _appraisal } = this
    if (_appraisal) return _appraisal

    const { appraisals } = this
    if (!appraisals) return

    const value = appraisals[appraisals.length - 1]
    return this._appraisal = value
  }

  get order (): SaleOrder | undefined {
    const { _saleOrder } = this
    if (!_saleOrder) return this.saleOrders && this.saleOrders[0]

    return _saleOrder
  }

  get price (): number {
    const { type, auto, stock, appraisal } = this

    switch (type.name) {
      case DealType.sale:
        return stock?.currentPrice?.amount || auto?.price
      case DealType.purchase:
        return appraisal?.price
    }
  }

  get financial () {
    const { order } = this
    if (!order) return
    const { financing } = order
    if (!financing) return

    return financing
  }

  get initialNote () {
    const { notes } = this
    if (!notes?.length) return

    return notes.filter(note => !note.activity)[0].note
  }

  set initialNote (value) {
    const { notes } = this

    if (!notes?.length) {
      const note = new DealNote()
      note.note = value
      this.notes = [note]
    } else notes[0].note = value
  }

  get isChannelFacebook () {
    const { channel: { name } } = this

    return name === Channel.facebook
  }

  get isChannelInstagram () {
    const { channel: { name } } = this

    return name === Channel.instagram
  }

  get isChannelFloor () {
    const { channel: { name } } = this

    return name === Channel.floor
  }

  get isChannelClientPortfolio () {
    const { channel: { name } } = this

    return name === Channel.clientPortfolio
  }

  get isChannelWebSite () {
    const { channel: { name } } = this

    return name === Channel.webSite
  }

  get isChannelChileAutos () {
    const { channel: { name } } = this

    return name === Channel.chileautos
  }

  get isChannelYapo () {
    const { channel: { name } } = this

    return name === Channel.yapo
  }

  get isChannelFullMotors () {
    const { channel: { name } } = this

    return name === Channel.fullmotor
  }

  get isChannelMercadoLibre () {
    const { channel: { name } } = this

    return name === Channel.mercadoLibre
  }

  get isChannelReferred () {
    const { channel: { name } } = this

    return name === Channel.referred
  }
}
