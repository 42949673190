import { Type } from 'class-transformer'
import { Entity } from '..'

import { Appraisal, AppraisalResponseType } from '.'
import { Employee } from '../hr'

export class AppraisalResponse extends Entity {
  @Type(() => Appraisal)
  appraisal: Appraisal;

  @Type(() => Employee)
  responsible: Employee;

  @Type(() => AppraisalResponseType)
  type: AppraisalResponseType

  amount?: number;
  comment?: string;

  get isAppraised () {
    const { type: { name } } = this
    return name === AppraisalResponseType.appraised
  }

  get isExpectedOffer () {
    const { type: { name } } = this
    return name === AppraisalResponseType.offer
  }

  get isAppealed () {
    const { type: { name } } = this
    return name === AppraisalResponseType.appealed
  }

  get isExpectedOfferConsignment () {
    const { type: { name } } = this
    return name === AppraisalResponseType.offerConsignment
  }

  get isAppraisedConsignment () {
    const { type: { name } } = this
    return name === AppraisalResponseType.appraisedConsignment
  }

  get isAppealedConsignment () {
    const { type: { name } } = this
    return name === AppraisalResponseType.appealedConsignment
  }
}
