import gql from 'graphql-tag'
import { Fragment } from './fragment'

export const mutation = gql`
  ${Fragment}
  mutation updatePerson($id: Int!, $fields: persons_person_set_input!) {
    record: update_persons_person_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...PersonForm
    }
  }
`

export default mutation
