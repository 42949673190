import { Type } from 'class-transformer'
import { Entity } from '..'

import { PaymentOrderItem, PaymentRecipient } from '.'
import { Deal } from '../crm'
import { Employee } from '../hr'
import { Person } from '../persons'
import { Process, ProcessStatus } from '../settings'

export class PaymentOrder extends Entity {
  @Type(() => PaymentOrderItem)
  items: PaymentOrderItem[]

  @Type(() => PaymentRecipient)
  recipients: PaymentRecipient[]

  @Type(() => Deal)
  deal: Deal[]

  @Type(() => Employee)
  employee: Employee

  @Type(() => Person)
  person: Person

  @Type(() => ProcessStatus)
  status: ProcessStatus

  @Type(() => Process)
  process: Process;

  controlNumber: number
  idProcessRecord: number
  idProcess: number
  amount: number

  get dealActive () {
    const { deal } = this

    return deal?.[0]
  }

  get payments () {
    const { items } = this
    if (!items?.length) return []
    return items?.flatMap(item => item?.paymentRecipientsTitle)
  }
}
