import { Type } from 'class-transformer'
import { Entity } from '..'

import { BonusType } from './BonusType'
import { Price } from './Price'

export class Bonus extends Entity {
  @Type(() => BonusType)
  type: BonusType;

  @Type(() => Price)
  price: Price;

  amount: number;

  get title () {
    const { type } = this
    return type?.description
  }
}
