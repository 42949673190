import gql from 'graphql-tag'

import { EvaluationRequirementFragment } from '@/graphql/fragments/loans'

export const query = gql`
  ${EvaluationRequirementFragment}
  query findEvaluationRequirement($filter: loans_evaluation_requirement_bool_exp, $limit: Int) {
    records: loans_evaluation_requirement(where: $filter, limit: $limit) {
      ...EvaluationRequirement
    }
  }
`

export default query
