import gql from 'graphql-tag'
import { EntityFields, EnumFields } from '..'

export const PaymentTypeFragment = gql`
  fragment PaymentType on finance_payment_type{
    ${EntityFields}
    ${EnumFields}
    icon
    color
  }
`
