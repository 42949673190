import { Actions } from '../../interfaces'
import { Agreement } from '@/entities/persons'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { Agreement },
  actions: { [Agreement.name]: actions },
}
