<template>
  <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M451.765 72.2824C451.765 40.6588 426.165 15.0588 394.541 15.0588H117.459C85.8354 15.0588 60.2354 40.6588 60.2354 72.2824V439.718C60.2354 471.341 85.8354 496.941 117.459 496.941H394.541C426.165 496.941 451.765 471.341 451.765 439.718V72.2824ZM421.647 439.718C421.647 454.776 409.6 466.824 394.541 466.824H117.459C102.4 466.824 90.353 454.776 90.353 439.718V72.2824C90.353 57.2235 102.4 45.1765 117.459 45.1765H394.541C409.6 45.1765 421.647 57.2235 421.647 72.2824V439.718Z"
    />
    <path
      d="M164.141 286.118H341.835C346.353 286.118 350.87 284.612 353.882 280.094C356.894 277.082 358.4 272.565 356.894 268.047C350.87 231.906 328.282 203.294 298.164 189.741C307.2 179.2 313.223 165.647 313.223 149.082C313.223 115.953 286.117 88.847 252.988 88.847C219.858 88.847 192.753 115.953 192.753 149.082C192.753 164.141 198.776 177.694 207.811 189.741C177.694 204.8 153.6 233.412 149.082 268.047C149.082 272.565 149.082 277.082 152.094 280.094C155.106 284.612 159.623 286.118 164.141 286.118ZM222.87 150.588C222.87 134.024 236.423 120.471 252.988 120.471C269.553 120.471 283.106 134.024 283.106 150.588C283.106 167.153 269.553 180.706 252.988 180.706C236.423 180.706 222.87 167.153 222.87 150.588ZM252.988 210.824C283.106 210.824 310.211 228.894 322.259 256H183.717C195.764 228.894 222.87 210.824 252.988 210.824Z"
    />
    <path
      d="M376.471 331.294H135.53C126.494 331.294 120.471 337.318 120.471 346.353C120.471 355.388 126.494 361.412 135.53 361.412H376.471C385.506 361.412 391.53 355.388 391.53 346.353C391.53 337.318 385.506 331.294 376.471 331.294Z"
    />
    <path
      d="M376.471 391.529H135.53C126.494 391.529 120.471 397.553 120.471 406.588C120.471 415.624 126.494 421.647 135.53 421.647H376.471C385.506 421.647 391.53 415.624 391.53 406.588C391.53 397.553 385.506 391.529 376.471 391.529Z"
    />
  </svg>

</template>

<script>
  export default {}
</script>
