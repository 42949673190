import { Metadata } from '.'
import { Aggregate, Filter, FilterField, Forms, Metadata as IMetadata, Query } from '../interfaces'

export class View extends Metadata implements Omit<IMetadata, '__type'> {
  aggregates: Aggregate[];
  filters: Filter[];
  query: Query;
  forms: Forms;
  search: string[];
  filterFields: FilterField[];
  numericFields: string[];
  translateWords: {
    fields: { [field: string]: { [key: string]: string }[] }
  }[];

  get model (): string {
    return this.query.model
  }

  // Getter para obtener un conjunto de campos numéricos
  private get numericFieldSet (): Set<string> {
    return new Set(this.numericFields || [])
  }

  // Método público para obtener la consulta actualizada basada en filterFields
  public getUpdatedQuery (): Record<string, any>[] {
    return this.getUpdatedQueryFromFields()
  }

  // Método para generar la consulta basada en los campos con valores
  public getUpdatedQueryFromFields (): Record<string, any>[] {
    const numericFields = this.numericFieldSet

    return this.filterFields
      .filter(field => field.value !== undefined && field.value !== null && field.value !== '')
      .flatMap(field => {
        const fieldName = field.fieldName
        let processedValue = field.value

        const fieldTranslations = this.getFieldTranslations(fieldName)
        if (fieldTranslations && fieldTranslations.length > 0) {
          processedValue = this.translateSearchTerm(field.value.toString(), fieldName)
        }

        if (numericFields.has(fieldName)) {
          const numValue = Number(processedValue)
          if (!isNaN(numValue)) {
            processedValue = numValue
          } else {
            return [] // Omitir si no es un número válido
          }
        }

        // Reemplazar 'search' por 'processedValue' en cada filtro
        return field.filter.map(f => this.replaceSearchInFilter(f, processedValue))
      })
      .filter(filter => filter !== null)
  }

  // Método para reemplazar 'search' por el valor ingresado en el filtro
  public replaceSearchInFilter (
    filter: Record<string, any>,
    value: any
  ): Record<string, any> | null {
    // Clonar el filtro para no modificar el original
    const newFilter = JSON.parse(JSON.stringify(filter))

    // Reemplazar recursivamente 'search' por 'value' en 'newFilter'
    return this.recursiveReplace(newFilter, 'search', value)
  }

  private recursiveReplace (
    obj: any,
    searchValue: any,
    replaceValue: any
  ): any | null {
    if (Array.isArray(obj)) {
      const newArray = obj
        .map(item => this.recursiveReplace(item, searchValue, replaceValue))
        .filter(item => item !== null)
      return newArray.length > 0 ? newArray : null
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj: any = {}
      for (const key in obj) {
        const value = obj[key]
        if (typeof value === 'object' && value !== null) {
          const result = this.recursiveReplace(value, searchValue, replaceValue)
          if (result !== null) {
            newObj[key] = result
          }
        } else {
          if (value === searchValue) {
            if (key === '_ilike') {
              // Si la clave es '_ilike', agregar % al valor reemplazado
              newObj[key] = `%${replaceValue}%`
            } else {
              // Para otras claves, reemplazar directamente con el valor
              newObj[key] = replaceValue
            }
          } else {
            newObj[key] = value
          }
        }
      }
      return Object.keys(newObj).length > 0 ? newObj : null
    } else {
      return obj
    }
  }

  // Función auxiliar para eliminar acentos y normalizar cadenas
  private removeAccents (str: string): string {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  // Método para traducir el término de búsqueda basado en translateWords y el campo actual
  private translateSearchTerm (
    searchTerm: string,
    fieldName: string
  ): string {
    if (!this.translateWords || this.translateWords.length === 0) {
      return searchTerm
    }

    const fieldTranslations = this.getFieldTranslations(fieldName)
    if (!fieldTranslations || fieldTranslations.length === 0) {
      return searchTerm
    }

    // Normalizar el término de búsqueda
    const normalizedSearchTerm = this.normalizeString(searchTerm)

    let bestMatchValue = searchTerm
    let highestMatchScore = 0

    for (const translation of fieldTranslations) {
      // Cada traducción es un objeto con una clave-valor
      const [key, value] = Object.entries(translation)[0]

      // Normalizar la clave
      const normalizedKey = this.normalizeString(key)

      // Calcular la puntuación de coincidencia
      const matchScore = this.getMatchScore(
        normalizedSearchTerm,
        normalizedKey
      )

      // Si la puntuación es mayor que la puntuación más alta hasta ahora, actualizamos
      if (matchScore > highestMatchScore) {
        bestMatchValue = value
        highestMatchScore = matchScore
      }
    }

    return bestMatchValue
  }

  // Método para normalizar cadenas (eliminar acentos, espacios y convertir a minúsculas)
  private normalizeString (str: string): string {
    return this.removeAccents(str).replace(/\s+/g, '').toLowerCase()
  }

  // Método para obtener las traducciones del campo específico
  private getFieldTranslations (
    fieldName: string
  ): { [key: string]: string }[] | null {
    if (!this.translateWords || this.translateWords.length === 0) {
      return null
    }

    for (const translationEntry of this.translateWords) {
      if (translationEntry.fields && translationEntry.fields[fieldName]) {
        return translationEntry.fields[fieldName]
      }
    }

    return null
  }

  // Método para calcular la puntuación de coincidencia entre dos cadenas
  private getMatchScore (str1: string, str2: string): number {
    // Comprobamos si una cadena está incluida en la otra
    if (str1.includes(str2) || str2.includes(str1)) {
      // La puntuación es la longitud de la coincidencia más corta
      return Math.min(str1.length, str2.length)
    }

    // Si no hay inclusión directa, buscamos coincidencias parciales
    return this.longestCommonSubstring(str1, str2).length
  }

  // Método para encontrar la subsecuencia común más larga entre dos cadenas
  private longestCommonSubstring (str1: string, str2: string): string {
    const matrix: number[][] = []
    let longestLength = 0
    let endIndex = 0

    for (let i = 0; i < str1.length; i++) {
      matrix[i] = []
      for (let j = 0; j < str2.length; j++) {
        if (str1[i] === str2[j]) {
          if (i === 0 || j === 0) {
            matrix[i][j] = 1
          } else {
            matrix[i][j] = matrix[i - 1][j - 1] + 1
          }
          if (matrix[i][j] > longestLength) {
            longestLength = matrix[i][j]
            endIndex = i
          }
        } else {
          matrix[i][j] = 0
        }
      }
    }
    return str1.slice(endIndex - longestLength + 1, endIndex + 1)
  }
}
