import { Actions } from '../../interfaces'
import { ProcessTrafficTicket } from '@/entities/purchase'

import fetch from './fetch'
import update from './update'
import find from './find'

export const actions: Actions = {
  fetch,
  find,
  update,
}

export default {
  Model: { ProcessTrafficTicket },
  actions: { [ProcessTrafficTicket.name]: actions },
}
