import { Entity } from '..'

export class FinancialCategory extends Entity {
  public static readonly income = 'income'
  public static readonly spent = 'spent'

  name: string;
  description: string;

  toString () {
    return this.description
  }

  get isIncome () {
    const { name } = this
    return name === FinancialCategory.income
  }

  get isSpent () {
    const { name } = this
    return name === FinancialCategory.spent
  }
}
