import { State as RootState } from '@/store/state'
import { ActionTree } from 'vuex'
import { Notification } from '@/store/notification/state'

export const actions: ActionTree<Notification, RootState> = {
  loadNotification: ({ commit }, payload): void => {
    commit('setNotification', payload)
  },
  cleanNotification: ({ commit }): void => {
    commit('clearNotification')
  },
}
