import gql from 'graphql-tag'

import { EvaluationConditionFragment } from '@/graphql/fragments/loans'

export const mutation = gql`
  ${EvaluationConditionFragment}
  mutation insertEvaluationCondition($fields: loans_evaluation_condition_insert_input!) {
    record: insert_loans_evaluation_condition_one(object: $fields) {
      ...EvaluationCondition
    }
  }
`

export default mutation
