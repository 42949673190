import { GetterTree, MutationTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { User as FirebaseUser, UserInfo } from '@firebase/auth-types'
import { User as EntityUser } from '@/entities/public/User'
import { AUTH_TOKEN } from '@/plugins/apollo'
import { Role } from '@/store/user/models'
import { plainToInstance } from 'class-transformer'

export const ROLES_INSPECTION_ALLOWED = {
  executive: 1,
  inspector: 2,
  supervisor: 3,
}

export const ROLES_DASHBOARD_ALLOWED = {
  supervisor: 1,
}

export const ROLES_DASHBOARD_GROUP_ALLOWED = {
  staff: 1,
  appraiser: 2,
  supervisor: 3,
  inspector: 4,
  validator: 5,
  forwarder: 6,
  advertiser: 7,
  treasurer: 8,
  sysop: 9,
}

export const ROLES_SCHEDULE_ALLOWED = {
  staff: 1,
}

export const RoleSysop = {
  sysop: 1,
}

export interface User {
  uid?: string
  token?: string
  details: FirebaseUser
  roles: Array<Role>
  id: number | null
  defaultRole: string | null
  onlineUsersSubscription: Record<string, any> | null
  onlineUsers: Array<EntityUser>
}

export interface UserData extends UserInfo {
  id: number
}

export function initState (): User {
  return {
    uid: undefined,
    token: undefined,
    details: {} as FirebaseUser,
    roles: [],
    id: null,
    defaultRole: null,
    onlineUsersSubscription: null,
    onlineUsers: [],
  }
}

export const mutations: MutationTree<User> = {
  login: async (state, { user, token, roles, id, roleName }) => {
    state.details = user
    state.token = token
    state.uid = user.uid
    state.roles = roles
    state.id = id
    state.defaultRole = roleName
  },
  logout: state => Object.assign(state, initState()),
  updateJwt (state, jwt) {
    localStorage.setItem(AUTH_TOKEN, jwt)
  },
  setOnlineUsersSubscription (state, subscription) {
    state.onlineUsersSubscription = subscription
  },
  setOnlineUsers (state, users) {
    state.onlineUsers = users
      .map(user => plainToInstance(EntityUser, user))
      .sort((a, b) => {
        if (a.userIsOnline && !b.userIsOnline) return -1
        if (!a.userIsOnline && b.userIsOnline) return 1

        if (a.lastSeen && b.lastSeen) {
          return b.lastSeen.isBefore(a.lastSeen) ? -1 : 1 // Cambiado para ordenar de más reciente a más antiguo
        }
        if (a.lastSeen && !b.lastSeen) return -1
        if (!a.lastSeen && b.lastSeen) return 1

        return 0
      })
  },
}

export const getters: GetterTree<User, RootState> = {
  isLoggedIn: ({ uid, details: { isAnonymous } }, { firebaseLoading }) => {
    if (firebaseLoading) return undefined
    return uid !== undefined && !isAnonymous
  },
  user: ({ details, id }): UserData => {
    return {
      uid: details.uid,
      displayName: details.displayName,
      email: details.email,
      photoURL: details.photoURL,
      providerId: details.providerId,
      phoneNumber: details.phoneNumber,
      id,
    }
  },
  token: ({ token }): string => {
    return token || ''
  },
  rolesName: ({ roles }): Array<string> => {
    return roles.map(({ name }) => name)
  },
  email: ({ details }): string | null => {
    return details.email
  },
  name: ({ details }): string | null => {
    return details.displayName
  },
  roles: ({ roles }): Array<Role> => {
    return roles
  },
  defaultRole: ({ defaultRole }): string | null => {
    return defaultRole
  },
}
