import gql from 'graphql-tag'
import { BasePurchaseOrderFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${BasePurchaseOrderFragment}
  query fetchPurchase($id: Int!) {
    records: purchase_purchase_order_by_pk(id: $id) {
      ...BasePurchaseOrder
    }
  }
`

export default query
