import gql from 'graphql-tag'
import { BankDataFragment } from '@/graphql/fragments/persons/bankData'

export const query = gql`
  ${BankDataFragment}
  query findBankData($filter: persons_bank_data_bool_exp, $limit: Int) {
    records: persons_bank_data(where: $filter,  limit: $limit) {
      ...BankData
    }
  }
`
export default query
