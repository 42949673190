import { Entity } from '@/models'
import { Type } from 'class-transformer'
import { Person } from '@/entities/persons'
import { Process } from '@/entities/settings'
import { Platform } from '.'

export class Alias extends Entity {
  @Type(() => Person)
  person: Person;

  @Type(() => Process)
  process: Process;

  @Type(() => Platform)
  platform: Platform;

  name: string;
  idProcessRecord: number;
}
