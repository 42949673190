import gql from 'graphql-tag'

import { PaymentOrderItemFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${PaymentOrderItemFragment}
  query fetchPaymentOrderItem($id: Int!) {
    records: finance_payment_order_item_by_pk(id: $id) {
      ...PaymentOrderItem
    }
  }
`

export default query
