import { MutationTree } from 'vuex'
import { Teams } from '@/store/settings/team/state'
import { deepCopy } from '@/utils/general'
import { TeamClass } from '@/store/settings/team/team'
import { TeamUserManagement } from '@/store/user/user'

export const mutations: MutationTree<Teams> = {
  setTeams: (state, teams) => {
    state.teams = teams
    const copy = deepCopy(teams)

    state.copyTeams = copy.map(
      (cp: TeamUserManagement) => new TeamClass(cp.team)
    )
  },
}
