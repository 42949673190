import gql from 'graphql-tag'
import { DealFragment } from '@/graphql/fragments/crm'
import { PersonFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${DealFragment}
  ${PersonFragment}
  query fetchDeal($id: Int!) {
    records: crm_deal_by_pk(id: $id) {
      ...Deal
      lead{
        id
        executive{
          id
          id
          person{
            ...Person
          }
        }
      }
    }
  }
`

export default query
