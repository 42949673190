import { Type } from 'class-transformer'
import { Entity } from '..'
import { Auto } from '@/entities/public/Auto'
import { Details, Posts } from '.'

export class Car extends Entity {
  @Type(() => Auto)
  auto: Auto;

  @Type(() => Details)
  details: Details[];

  @Type(() => Posts)
  posts: Posts[];
}
