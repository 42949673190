import gql from 'graphql-tag'
import { EntityFields } from '..'
import { InspectionQualificationFragment } from './inspectionQualification'

export const BaseInspectedComponentFragment = gql`
  fragment BaseInspectedComponent on purchase_inspection_inspected_component{
    ${EntityFields}
    cost
    comment
    supervisorCost:supervisor_cost
  }
`

export const InspectedComponentFragment = gql`
  ${BaseInspectedComponentFragment}
  ${InspectionQualificationFragment}
  fragment InspectedComponent on purchase_inspection_inspected_component{
    ...BaseInspectedComponent
    inspectionQualifications:inspection_qualifications{
      ...InspectionQualification
    }
  }
`
