import gql from 'graphql-tag'

import { SaleProductFragment } from '@/graphql/fragments/sales'

export const mutation = gql`
  ${SaleProductFragment}
  mutation updateSaleProduct($id: Int!,$fields: sales_sale_product_set_input!) {
    record: update_sales_sale_product_by_pk(pk_columns: {id: $id}, _set: $fields){
      ...SaleProduct
    }
  }
`

export default mutation
