import { GetterTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { UserManagement } from '@/store/user/user'

export interface Teams {
  teams: Array<UserManagement>
  copyTeams: Array<UserManagement>
}

export function initState (): Teams {
  return {
    teams: [],
    copyTeams: [],
  }
}

export const getters: GetterTree<Teams, RootState> = {
  teams: ({ teams }): Array<UserManagement> => {
    return teams
  },
  copyTeams: ({ copyTeams }): Array<UserManagement> => {
    return copyTeams
  },
}
