import gql from 'graphql-tag'
import { Fragment } from './fragment'

export const query = gql`
  ${Fragment}
  query findPurchase($filter: purchase_purchase_order_bool_exp!) {
    records: purchase_purchase_order(where: $filter) {
      ...PurchaseForm
    }
  }
`

export default query
