
  import { Component, Vue } from 'vue-property-decorator'

@Component
  export default class updatePwa extends Vue {
  timeout = -1;
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  registration;
  refreshing = false;
  enable = false;

  mounted (): void {
    if ('serviceWorker' in navigator) {
      document.addEventListener('swUpdated', this.showRefreshUI, {
        once: true,
      })
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
    }
  }

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  showRefreshUI (e): void {
    this.registration = e.detail
    this.enable = true
  }

  refreshApp (): void {
    this.enable = false
    if (!this.registration) {
      return
    }

    this.registration.waiting.postMessage('skipWaiting')
  }
  }
