import { GetterTree, MutationTree } from 'vuex'
import { VueApolloClient } from '@/plugins/apollo'

export interface State {
  firebaseLoading: boolean
  apolloClient?: VueApolloClient
}

export function initState (): State {
  return {
    firebaseLoading: true,
  }
}

export const mutations: MutationTree<State> = {}

export const getters: GetterTree<State, State> = {
  isReady: ({ firebaseLoading }) => !firebaseLoading,
}
