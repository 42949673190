import gql from 'graphql-tag'
import { EntityFields } from '..'

export const CompanyTypeFragment = gql`
  fragment CompanyType on persons_company_type {
    ${EntityFields}
    name
    description
  }
`
