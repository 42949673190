import gql from 'graphql-tag'
import { TrafficTicketFragment } from '@/graphql/fragments/public'

export const mutation = gql`
  ${TrafficTicketFragment}
  mutation updateTrafficTicket($id: Int!, $fields: traffic_ticket_set_input!) {
    record: update_traffic_ticket_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...TrafficTicket
    }
  }
`

export default mutation
