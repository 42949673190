import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ProcessExpenseFragment } from '@/graphql/fragments/settings'
import { PaymentRecipientFragment } from '@/graphql/fragments/finance/paymentRecipent'

export const BasePaymentOrderItemFragment = gql`
  fragment BasePaymentOrderItem on finance_payment_order_item{
    ${EntityFields}
    amount
  }
`

export const PaymentOrderItemFragment = gql`
  ${BasePaymentOrderItemFragment}
  ${ProcessExpenseFragment}
  ${PaymentRecipientFragment}
  fragment PaymentOrderItem on finance_payment_order_item{
    ...BasePaymentOrderItem
    processExpense:process_expense {
      ...ProcessExpense
    }
    recipients {
      ...PaymentRecipient
      paymentOrder:payment_order{
        id
      }
      paymentOrderItem:payment_order_item{
        id
      }
    }
  }
`
