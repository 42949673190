import gql from 'graphql-tag'
import { BonusFragment } from '@/graphql/fragments/sales'

export const query = gql`
  ${BonusFragment}
  query fetchBonus($id: Int!) {
    records: sales_bonus_by_pk(id: $id) {
      ...Bonus
    }
  }
`

export default query
