import { Actions } from '../../interfaces'

import find from './find'
import { ProcessExpense } from '@/entities/settings'

export const actions: Actions = {
  find,
}

export default {
  Model: { ProcessExpense },
  actions: { [ProcessExpense.name]: actions },
}
