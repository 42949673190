import gql from 'graphql-tag'
import { EmployeeFragment } from '@/graphql/fragments/hr'
import { AggregatePath } from '@/entities/interfaces'
import { PersonFragment } from '@/graphql/fragments/persons'

const structure: Array<AggregatePath> = [
  { name: 'active', title: 'Activos', aggregations: ['count'], color: 'blue' },
  { name: 'pending', title: 'Sin Gestión', aggregations: ['count'], color: 'yellow' },
  { name: 'delayed', title: 'Atrasados', aggregations: ['count'], color: 'red' },
  { name: 'assigned', title: 'Asignadas', aggregations: ['count'], color: 'primary', hidden: true },
]
const query = gql`
  ${EmployeeFragment}
  ${PersonFragment}
  query aggregates($model: hr_employee_bool_exp, $aggregate: crm_lead_bool_exp! = {}, $order: [hr_employee_order_by!]) {
    aggregates: hr_employee(where: $model, order_by: $order) {
      ...Employee
      person{
        ...Person
      }
      active: executive_leads_aggregate(where: {_and: [$aggregate, {id_closing_reason: {_is_null: true}, activities_aggregate: {count: {predicate: {_gt: 0}}}}]}) {
        aggregate {
          count(distinct: true)
        }
      }
      pending: executive_leads_aggregate(where: {_and: [$aggregate, {id_closing_reason: {_is_null: true}, activities_aggregate: {count: {predicate: {_eq: 0}}}}]}) {
        aggregate {
          count(distinct: true)
        }
      }
      delayed: executive_leads_aggregate(where: {_and: [$aggregate, {_or: [{activities: {_or: [{process_status: {status: {name: {_eq: "pending"}}}, scheduling_date: {_lt: "now()"}}, {process_status: {status: {name: {_eq: "deprecated"}}}}]}}, {deals: {appraisals: {status: {status: {name: {_nin: ["pending", "no_offer", "appealed"]}}}}}, _not: {activities: {}}}], id_closing_reason: {_is_null: true}}]}) {
        aggregate {
          count(distinct: true)
        }
      }
      assigned: executive_leads_aggregate(where: {_and: [$aggregate, {id_forwarder: {_is_null: false}}]}) {
        aggregate {
          count(columns: id)
        }
      }
    }
  }
`
export const aggregate = { structure, query }
export default aggregate
