import { Type } from 'class-transformer'
import { Entity } from '@/entities'

import { Auto, MaintenanceType } from '.'
import { Concessionaire } from '../vehicle'
import { Person } from '../persons'
import { ProcessStatus } from '../settings'
import { MaintenanceBackupType } from './MaintenanceBackupType'

export class Maintenance extends Entity {
  @Type(() => Auto)
  auto: Auto

  @Type(() => Concessionaire)
  concessionaire: Concessionaire

  @Type(() => Person)
  person: Person

  @Type(() => MaintenanceType)
  type: MaintenanceType

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => MaintenanceBackupType)
  backupType: MaintenanceBackupType;

  mileage: number

  get description () {
    const { type: { description } } = this
    return description
  }

  get isMileage () {
    const { type } = this

    return type?.mileage
  }
}
