import { Actions } from '../../interfaces'
import { LeadActivity } from '@/entities/crm'

import find from './find'
import create from './create'
import fetch from './fetch'
import update from './update'

export const actions: Actions = {
  find,
  create,
  fetch,
  update,
}

export default {
  Model: { LeadActivity },
  actions: { [LeadActivity.name]: actions },
}
