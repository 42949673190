import gql from 'graphql-tag'
import { EntityFields } from '..'
import { ModelFragment } from './model'

export const BaseVersionFragment = gql`
  fragment VersionBase on vehicle_version {
    ${EntityFields}
    name
    discontinued
  }
`

export const VersionFragment = gql`
  ${BaseVersionFragment}
  ${ModelFragment}
  fragment Version on vehicle_version {
    ...VersionBase
    model {
      ...Model
    }
  }
`
