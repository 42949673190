import gql from 'graphql-tag'
import { QueryBuilder } from '../utils'

import { ExpenseOrder } from '@/entities/finance'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import { VersionYearFragment } from '@/graphql/fragments/vehicle'
import { PersonAccountFragment, PersonFragment } from '@/graphql/fragments/persons'
import { ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { PaymentTypeFragment } from '@/graphql/fragments/finance'

const query = gql`
  ${BaseAutoFragment}
  ${VersionYearFragment}
  ${PersonFragment}
  ${ProcessStatusFragment}
  ${PaymentTypeFragment}
  ${PersonAccountFragment}
  ${ClosingReasonFragment}
  query expenses($filter: finance_payment_bool_exp, $order: [finance_payment_order_by!], $limit: Int, $offset: Int) {
    records: finance_payment(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      id
      amount
      type:payment_type {
        ...PaymentType
      }
      recipientAccount:recipient_account {
        ...PersonAccount
        holder{
          ...Person
        }
      }
      paymentRecipient:payment_recipient {
        id
        person{
          ...Person
        }
        paymentOrderItem:payment_order_item {
          id
          processExpense: process_expense {
            id
            expense{
              id
              description
            }
          }
        }
      }
      status{
        ...ProcessStatus
      }
      closingReason: closing_reason {
        ...ClosingReason
      }
      deal{
        id
        auto{
          ...AutoBase
          version: version_year {
            ...VersionYear
          }
        }
        lead{
          id
          executive{
            id
            person{
              ...Person
            }
          }
        }
      }
      validator{
        id
        person{
          ...Person
        }
      }
    }
    total: finance_payment_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const expenses = QueryBuilder(query, ExpenseOrder)
export default expenses
