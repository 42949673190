import { MutationTree } from 'vuex'
import { Resources } from '@/store/settings/resources/state'

export const mutations: MutationTree<Resources> = {
  setResources: (state, resources) => {
    state.resources = resources
  },
  setResourcesGroup: (state, resourcesGroup) => {
    state.resourcesGroup = resourcesGroup
  },
  setGroupIdCreated: (state, groupIdCreated) => {
    state.groupIdCreated = groupIdCreated
  },
  setResourcesPositions: (state, positions) => {
    state.positions = positions
  },
  setResource: (state, resource) => {
    state.resource = resource
  },
  setTotalResources: (state, totalResources) => {
    state.totalResources = totalResources
  },
  setResourcesForRoles: (state, resourcesForRoles) => {
    state.resourcesForRoles = resourcesForRoles
  },
}
