import gql from 'graphql-tag'

export const withdrawalByPurchaseId = gql`
  mutation withdrawalByPurchaseId($purchaseId: Float!, $idValidator: Float!) {
    response: withdrawal_by_purchase_id(purchaseId: $purchaseId, idValidator: $idValidator)
  }
`

export const closeApprovedPurchase = gql`
  mutation closeApprovedPurchase($purchaseId: Float!, $idValidator: Float!) {
    close_approved_purchase(idValidator: $idValidator,purchaseId: $purchaseId)
  }`
