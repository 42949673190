import gql from 'graphql-tag'
import { AddressTypeFragment } from '@/graphql/fragments/persons/addressType'
import { PersonAddressFragment } from '@/graphql/fragments/persons/personAddress'

export const query = gql`
  ${AddressTypeFragment}
  ${PersonAddressFragment}
  query findAddressType($filter: persons_address_type_bool_exp) {
    records: persons_address_type(where: $filter) {
      ...AddressType
      personAddresses:person_addresses{
        ...PersonAddress
      }
    }
  }
`
export default query
