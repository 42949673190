import gql from 'graphql-tag'

import {
  AppraisalFragment,
  BaseInspectionFragment,
  EnablementFragment, InspectedComponentFragment,
} from '@/graphql/fragments/purchase'
import { ClosingReasonFragment, ProcessFragment } from '@/graphql/fragments/settings'
import { EmployeeFragment } from '@/graphql/fragments/hr'
import { PersonAddressFragment } from '@/graphql/fragments/persons/personAddress'
import { DealFragment } from '@/graphql/fragments/crm'
import { DealAutoAttributeFragment } from '@/graphql/fragments/public/dealAutoAttribute'
import { AutoMotiveRegistrationFragment, VersionYearFragment } from '@/graphql/fragments/vehicle'
import { MaintenanceFragment } from '@/graphql/fragments/public'

export const mutation = gql`
  ${BaseInspectionFragment}
  ${ProcessFragment}
  ${EmployeeFragment}
  ${EnablementFragment}
  ${ClosingReasonFragment}
  ${PersonAddressFragment}
  ${AppraisalFragment}
  ${DealFragment}
  ${DealAutoAttributeFragment}
  ${InspectedComponentFragment}
  ${AutoMotiveRegistrationFragment}
  ${VersionYearFragment}
  ${MaintenanceFragment}
  mutation updateInspection($id: Int!, $fields: purchase_inspection_set_input!) {
    record: update_purchase_inspection_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...InspectionBase
      negotiation {
        id
      }
      status {
        ...ProcessStatus
      }
      inspector {
        ...Employee
      }
      supervisor {
        ...Employee
      }
      closingReason: closing_reason {
        ...ClosingReason
      }
      address {
        ...PersonAddress
      }
      appraisal {
        id
        deal {
          id
          auto {
            id
            registrationPlate: registration_plate
            generation  {
              id
              registrations:automotive_registrations{
                ...AutoMotiveRegistration
              }
            }
            version:version_year {
              ...VersionYear
              attributes {
                ...Attribute
              }
            }
            maintenances (order_by: {type: {mileage: desc}}){
              ...Maintenance
            }
          }
          autoAttributes:auto_attributes{
            ...DealAutoAttribute
          }
          lead {
            id
          }
        }
      }
      inspectedComponents:inspected_components(where: {process: {table_name: {_eq: "inspection"}}}){
        ...InspectedComponent
        inspectionComponent:inspection_component {
          id
          inspectionParameters:inspection_parameters {
            id
            inspectionComponent:inspection_component {
              id
              component {
                slug
                id
              }
            }
          }
          component {
            id
            slug
            category {
              id
            }
          }
        }
      }
    }
  }
`

export default mutation
