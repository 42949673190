import { VueApolloClient } from '@/plugins/apollo'
import { plainToInstance } from 'class-transformer'

import { Actions, Aggregates as IAggregates, formMutation } from './interfaces'
import { Query } from '@/entities/public/Resource/interfaces'
import { formFilter, insertInput, updateInput } from '@/graphql/generated-types'
import { UnimplementedAction, UnimplementedModel } from '@/errors'

import crm from './crm'
import persons from './persons'
import Public from './public'
import vehicle from './vehicle'
import purchase from './purchase'
import settings from './settings'
import sales from './sales'
import loans from './loans'
import audit from './audit'
import hr from './hr'
import finance from './finance'
import files from './files'
import documents from './documents'
import { Entity, EnumEntity } from '@/entities'
import { Aggregates } from '@/entities/aggregate'

type Result = Entity | EnumEntity | Array<Entity | EnumEntity>

export const Models = {
  ...crm.Models,
  ...persons.Models,
  ...Public.Models,
  ...vehicle.Models,
  ...purchase.Models,
  ...settings.Models,
  ...sales.Models,
  ...loans.Models,
  ...audit.Models,
  ...hr.Models,
  ...finance.Models,
  ...files.Models,
  ...documents.Models,
}

const actions: Record<string, Actions> = {
  ...crm.actions,
  ...persons.actions,
  ...Public.actions,
  ...vehicle.actions,
  ...purchase.actions,
  ...settings.actions,
  ...sales.actions,
  ...loans.actions,
  ...audit.actions,
  ...hr.actions,
  ...finance.actions,
  ...files.actions,
  ...documents.actions,
}

const aggregates: Record<string, IAggregates> = {
  ...sales.aggregates,
}

export function extract (query: Query) {
  const { model, name } = query
  const Model = Models[model]
  if (!Model) throw new UnimplementedModel(query)

  const action = actions[model][name]
  if (!action) throw new UnimplementedAction(query)

  const aggregate = aggregates[model]?.[name]

  return { Model, action, aggregate }
}

export async function fetchData (
  client: VueApolloClient,
  query: Query,
  filter?: formFilter,
  limit?: number,
  force?: boolean,
  offset?: number,
  params?: any,
  distinct?: Array<string>
): Promise<Result> {
  if (!query) throw new Error('Query is REQUIRED')

  const { Model, action, aggregate } = extract(query)
  const { order, params: queryParams } = query

  const variables = {
    filter,
    order,
    limit,
    ...{ ...params, ...queryParams },
    offset,
    distinct,
  }

  Object.entries(variables).forEach(([key, value]) => value !== 0 && !value && delete variables[key])

  const {
    data: { records },
  } = await client.query({ query: action, variables, fetchPolicy: force ? 'network-only' : 'cache-first' })

  const result = plainToInstance(Model, variables.limit === 1 && Array.isArray(records) ? records[0] : records as Object[]) as Result

  if (aggregate) {
    const target = Array.isArray(result) ? result : [result]
    target.forEach(record => record?.setAggregates(new Aggregates(aggregate, record)))
  }

  return result
}

export async function pushData (client: VueApolloClient, model: string, fields: insertInput | updateInput): Promise<any> {
  const { id } = fields as Record<string, any>
  const name: formMutation = id ? 'update' : 'create'

  if ('id' in fields && model === 'VersionYear') {
    delete fields.id
  }
  const { Model, action } = extract({ name, model })
  const variables = {
    id,
    fields,
  }

  const {
    data: { record },
  } = await client.mutate({ mutation: action, variables })

  return plainToInstance(Model, record)
}

export async function removeData (client: VueApolloClient, model: string, fields): Promise<any> {
  const { id } = fields
  const name: formMutation = 'remove'

  const { Model, action } = extract({ name, model })
  const variables = {
    id,
  }

  const {
    data: { record },
  } = await client.mutate({ mutation: action, variables })

  return plainToInstance(Model, record)
}
