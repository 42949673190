import { Transform, Type } from 'class-transformer'
import { Entity } from '..'
import dayjs, { Dayjs } from 'dayjs'
import { Person } from '@/entities/persons'
import { convertObjectPropertiesToCamelCase } from '@/utils/general'

export type PropertyType = 'text' | 'number' | 'phone' | 'link' | 'image' | 'video' | 'email';

enum auditIcon {
  editó = 'mdi-text-box-plus-outline',
  creó = 'mdi-plus',
  elimino = 'mdi-text-box-remove-outline',
  desvinculo = 'mdi-text-box-remove-outline',
  vinculo = 'mdi-text-box-plus-outline',
}

enum auditActions {
  U = 'editó',
  I = 'creó',
  D = 'elimino'
}

export class LoggedActions extends Entity {
  @Type(() => Person)
  person: Person

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs(value), { toClassOnly: true })
  actionTstampTx: Dayjs

  schemaName
  tableName
  hasuraUser
  transactionId
  applicationName
  action
  rowData
  changedFields
  statementOnly
  eventId

  get actionText () {
    const { action } = this

    return auditActions[action as keyof typeof auditActions]
  }

  get icon () {
    const { actionText } = this

    return auditIcon[actionText as keyof typeof auditIcon]
  }

  get date () {
    const { actionTstampTx } = this

    return actionTstampTx.format('DD/MM/YYYY h:mm A')
  }

  get email () {
    const { hasuraUser } = this

    return hasuraUser['x-hasura-user-email']
  }

  get newRow () {
    const { rowData } = this
    if (!rowData) return null

    return convertObjectPropertiesToCamelCase(rowData)
  }

  get newChanges () {
    const { changedFields } = this
    if (!changedFields) return null
    return convertObjectPropertiesToCamelCase(changedFields)
  }
}
