import { Actions } from '../../interfaces'

import { ConditionType } from '@/entities/loans'
import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { ConditionType },
  actions: { [ConditionType.name]: actions },
}
