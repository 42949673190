import { Actions } from '../../interfaces'
import { Component } from '@/entities/vehicle'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { Component },
  actions: { [Component.name]: actions },
}
