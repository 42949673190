import gql from 'graphql-tag'
import { EntityFields } from '..'
import { StreetTypeFragment } from './streetType'
import { CityFragment } from '../public/city'
import { PropertyTypeFragment } from './propertyType'

export const BaseAddressFragment = gql`
  fragment AddressBase on persons_address {
    ${EntityFields}
    streetName:street_name
    streetNumber:street_number
    propertyNumber:property_number
  }
`

export const AddressFragment = gql`
  ${BaseAddressFragment}
  ${StreetTypeFragment}
  ${CityFragment}
  ${PropertyTypeFragment}
  fragment Address on persons_address{
    ...AddressBase
    streetType: street_type {
      ...StreetType
    }
    city {
      ...City
    }
    propertyType:property_type {
      ...PropertyType
    }
  }
`
