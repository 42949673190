import { Type } from 'class-transformer'
import { Entity } from '..'

import { InspectionInspectedComponent, InspectionParameter } from '.'
import { Component, ComponentValue } from '../vehicle'

export class InspectionComponent extends Entity {
  @Type(() => Component)
  component: Component;

  @Type(() => ComponentValue)
  componentValue: ComponentValue;

  @Type(() => InspectionInspectedComponent)
  inspectedComponents: InspectionInspectedComponent[];

  @Type(() => InspectionParameter)
  inspectionParameters: InspectionParameter[];

  order: number;
  config: Record<string, any>

  get componentOptions () {
    return this.inspectionParameters?.flatMap(param =>
      param.inspectionAssessments.map(assessment => {
        return { id: assessment.id, name: assessment.option.name }
      })
    )
  }

  get totalScore () {
    return this.inspectedComponents?.reduce((total, inspectedComponent) => {
      const componentScore = inspectedComponent.inspectionQualifications.reduce((subTotal, qualification) => {
        return subTotal + qualification.assessment.score
      }, 0)
      return total + componentScore
    }, 0)
  }

  getComponentOptionByOrder (order: number) {
    const inspectionParameter = this.inspectionParameters.find(param => param.order === order)

    return inspectionParameter.inspectionAssessments.map(assessment => {
      return { id: assessment.id, name: assessment.option.name, score: assessment.score }
    })
  }

  get idInspectedComponent () {
    const { inspectionParameters } = this

    return inspectionParameters?.[0]?.inspectionQualifications?.[0]?.component?.id
  }

  findInspectionParameterByOrder (order) {
    const { inspectionParameters } = this

    if (!inspectionParameters?.length) return null

    return inspectionParameters.find(inspectionParameter => inspectionParameter?.order === order)
  }
}
