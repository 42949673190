import gql from 'graphql-tag'

import { StockFragment } from '@/graphql/fragments/sales'
import { BaseAppraisalFragment, BaseEnablementFragment, BaseInspectionFragment } from '@/graphql/fragments/purchase'
import { BaseDealFragment } from '@/graphql/fragments/crm'
import { BaseAutoFragment } from '@/graphql/fragments/public'
import { BaseAutoMotiveRegistrationFragment, VersionYearFragment } from '@/graphql/fragments/vehicle'

export const Fragment = gql`
  ${StockFragment}
  ${BaseEnablementFragment}
  ${BaseInspectionFragment}
  ${BaseAppraisalFragment}
  ${BaseDealFragment}
  ${BaseAutoFragment}
  ${VersionYearFragment}
  ${BaseAutoMotiveRegistrationFragment}
  fragment StockForm on sales_stock {
    ...Stock
    enablement {
      ...EnablementBase
      inspection {
        ...InspectionBase
        appraisal {
          ...AppraisalBase
          deal {
            ...DealBase
            auto {
              ...AutoBase
              version: version_year {
                ...VersionYear
              }
              status: process_status {
                ...ProcessStatus
              }
            }
          }
        }
      }
    }
  }
`
export default Fragment
