import { Actions } from '../../interfaces'

import find from './find'
import { ClosingReasonType } from '@/entities/settings'

export const actions: Actions = {
  find,
}

export default {
  Model: { ClosingReasonType },
  actions: { [ClosingReasonType.name]: actions },
}
