import gql from 'graphql-tag'
import { QueryBuilder } from '../utils'

import { Negotiation } from '@/entities/purchase'
import {
  BaseAppraisalFragment,
  BaseInspectionFragment,
  BaseNegotiationFragment,
  NegotiationFragment,
  NegotiationResponseFragment,
  ResponseFragment,
} from '@/graphql/fragments/purchase'
import { DealFragment, LeadFragment } from '@/graphql/fragments/crm'
import { ClosingReasonFragment, ProcessStatusFragment } from '@/graphql/fragments/settings'
import { EmployeeFragment } from '@/graphql/fragments/hr'
import { PersonFragment } from '@/graphql/fragments/persons'

const query = gql`
  ${NegotiationFragment}
  ${BaseInspectionFragment}
  ${BaseAppraisalFragment}
  ${ResponseFragment}
  ${DealFragment}
  ${LeadFragment}
  ${EmployeeFragment}
  ${PersonFragment}
  ${BaseNegotiationFragment}
  ${ProcessStatusFragment}
  ${NegotiationResponseFragment}
  ${ClosingReasonFragment}
  query negotiations($filter: purchase_negotiation_bool_exp, $order: [purchase_negotiation_order_by!], $limit: Int, $offset: Int) {
    records: purchase_negotiation(where: $filter, order_by: $order, limit: $limit, offset: $offset) {
      ...NegotiationBase
      status {
        ...ProcessStatus
      }
      responses: negotiation_responses (order_by: {created_at: desc_nulls_last}){
        ...NegotiationResponse
      }
      closingReason: closing_reason{
        ...ClosingReason
      }
      inspection {
        ...InspectionBase
        supervisor{
          ...Employee
          person {
            ...Person
          }
        }
        appraisal {
          ...AppraisalBase
          responses(order_by: {created_at: desc_nulls_last}) {
            ...Response
          }
          deal {
            ...Deal
            lead {
              ...Lead
              executive {
                ...Employee
                person {
                  ...Person
                }
              }
            }
          }
        }
      }
    }
    total: purchase_negotiation_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const negotiations = QueryBuilder(query, Negotiation)
export default negotiations
