import { Type } from 'class-transformer'
import { Entity } from '../index'

import { Component } from '../vehicle'

export class ComponentType extends Entity {
    @Type(() => Component)
    components: Component[];

    name: string;
}
