import { Actions } from '../interfaces'

import financing from './financing'
import financedItem from './financedItem'
import evaluation from './evaluation'
import evaluationCondition from './evaluationCondition'
import evaluationRequirement from './evaluationRequirement'
import conditionType from './conditionType'
import requirementType from './requirementType'
import offer from './offer'
import quota from './quota'

export const Models = {
  ...financing.Model,
  ...financedItem.Model,
  ...evaluation.Model,
  ...evaluationCondition.Model,
  ...evaluationRequirement.Model,
  ...conditionType.Model,
  ...requirementType.Model,
  ...offer.Model,
  ...quota.Model,
}

export const actions: Record<string, Actions> = {
  ...financing.actions,
  ...financedItem.actions,
  ...evaluation.actions,
  ...evaluationCondition.actions,
  ...evaluationRequirement.actions,
  ...conditionType.actions,
  ...requirementType.actions,
  ...offer.actions,
  ...quota.actions,
}

export const schema = { Models, actions }
export default schema
