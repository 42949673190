const errors = {
  user_not_found: 'Usuario no se consigue debe registrarse',
  lower_case: 'Caracter en Minuscula',
  upper_case: 'Caracter en Mayuscula',
  number_case: 'Caracter Numerico',
  special_case: 'Caracter Especial',
  minimum_case: 'Minimo 8 Caracteres',
  white_space_case: 'Sin Espacios en Blanco',
  is_required: 'Campo Requerido',
}

export default errors
