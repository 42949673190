import gql from 'graphql-tag'
import { AutoFragment } from '@/graphql/fragments/public'

export const query = gql`
  ${AutoFragment}
  query fetchAuto($id: Int!) {
    records: auto_by_pk(id: $id) {
      ...Auto
    }
  }
`
export default query
