import gql from 'graphql-tag'

import { EntityFields } from '..'
import { ContractTypeFragment, PersonActivityTypeFragment, RentTypeFragment } from '@/graphql/fragments/persons'

export const BasePersonLaborDataFragment = gql`
  fragment PersonLaborDataBase on persons_labor_data {
    ${EntityFields}
    rent
    jobTitle:job_title
    admissionDate:admission_date
    jobKey:job_key
    nameActivityType:name_activity_type
    nameRentType:name_rent_type
    nameContractType:name_contract_type
  }
`

export const PersonLaborDataFragment = gql`
  ${BasePersonLaborDataFragment}
  ${PersonActivityTypeFragment}
  ${RentTypeFragment}
  ${ContractTypeFragment}
  fragment PersonLaborData on persons_labor_data{
    ...PersonLaborDataBase
    activityType:activity_type {
      ...PersonActivityType
    }
    rentType:rent_type {
      ...RentType
    }
    contractType:contract_type {
      ...ContractType
    }
    employer {
      id
      type {
        name
        description
      }
      firstName:first_name
      secondName:second_name
      surname
      secondSurname:second_surname
      uid
      alias
      country {
        id
        name
      }
      mainPhone:main_phone
      birthDate:birth_date
      companyName:company_name
    }
  }
`
