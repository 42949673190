import gql from 'graphql-tag'

import { AppraisalFragment } from '@/graphql/fragments/purchase'

export const mutation = gql`
  ${AppraisalFragment}
  mutation updateAppraisal($id: Int!, $fields: purchase_appraisal_set_input!) {
    record: update_purchase_appraisal_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...Appraisal
    }
  }
`

export default mutation
