import gql from 'graphql-tag'

import { EvaluationFragment } from '@/graphql/fragments/loans'

export const mutation = gql`
  ${EvaluationFragment}
  mutation updateEvaluation($id: Int!,$fields: loans_evaluation_set_input!) {
    record: update_loans_evaluation_by_pk(pk_columns: {id: $id},_set: $fields) {
      ...Evaluation
    }
  }
`

export default mutation
