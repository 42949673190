import gql from 'graphql-tag'
import { QueryBuilder } from '../utils'
import { DailyAccountBalance } from '@/entities/finance'
import { DailyAccountBalanceFragment } from '@/graphql/fragments/finance'

const query = gql`
  ${DailyAccountBalanceFragment}
  query dailyAccountBalance($filter: finance_daily_account_balance_bool_exp, $order: [finance_daily_account_balance_order_by!], $limit: Int, $offset: Int) {
    records: finance_daily_account_balance(order_by: $order, limit: $limit, offset: $offset, where: $filter) {
      ...DailyAccountBalance
    }
    total: finance_daily_account_balance_aggregate(where: $filter) {
      aggregate {
        count(distinct: true)
      }
    }
  }
`

export const dailyAccountBalance = QueryBuilder(query, DailyAccountBalance)
export default dailyAccountBalance
