import gql from 'graphql-tag'
import { ResponseFragment } from '@/graphql/fragments/purchase'

export const Fragment = gql`
  ${ResponseFragment}
  fragment AppraisalResponseForm on purchase_appraisal_response {
    ...Response
  }
`
export default Fragment
