import gql from 'graphql-tag'
import { AppraisalFragment } from '@/graphql/fragments/purchase'

export const query = gql`
  ${AppraisalFragment}
  query findAppraisal($filter: purchase_appraisal_bool_exp) {
    records: purchase_appraisal(where: $filter) {
      ...Appraisal
    }
  }
`

export default query
