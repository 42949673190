import gql from 'graphql-tag'
import { ProcessStatusFragment } from '@/graphql/fragments/settings'

export const query = gql`
  ${ProcessStatusFragment}
  query fetchConsignment($id: Int!) {
    records: purchase_purchase_order_by_pk(id: $id) {
      id
      acquisitionType: acquisition_type {
        id
        name
        description
      }
      status {
        ...ProcessStatus
      }
      negotiation {
        id
        inspection{
          id
          appraisal{
            id
            deal{
              id
            }
          }
        }
      }
    }
  }
`

export default query
