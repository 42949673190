import gql from 'graphql-tag'
import { Fragment } from './fragment'

export const mutation = gql`
  ${Fragment}
  mutation updateQuota($id: Int!, $fields: loans_quota_set_input!) {
    record: update_loans_quota_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...QuotaFragment
    }
  }
`

export default mutation
