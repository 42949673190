import gql from 'graphql-tag'
import { AutoFragment } from '@/graphql/fragments/public'

export const mutation = gql`
  ${AutoFragment}
  mutation updateAuto($id: Int!, $fields: auto_set_input!) {
    record: update_auto_by_pk(pk_columns: {id: $id}, _set: $fields) {
      ...Auto
    }
  }
`

export default mutation
